import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Form } from 'antd';
import { SearchOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadWorkOrderDetailsData, getWorkOrderDetailsData, getSearchData } from '../../services/actions/api/workOrderDetails'
import moment from 'moment';
import * as XLSX from 'xlsx';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
// import axios from 'axios';
import BulkUploadModal from './BulkUploadModal'
import { useParams, useHistory, useNavigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const { Option } = Select;


const transformSheetData = (entry) => {
  return {
    workOrderNumber: entry['WORKORDER_NUM'],
    unitName: entry['UNIT_CODE'],
    contractorName: entry['VENDOR_NAME'],
    vendorCode: entry['VENDOR_CODE'],
    validFrom: entry['WORKORDER_VALID_FROM'],
    validTo: entry['WORKORDER_VALID_TO'],
    workOrderType: entry['SAP_WORKORDER_TYPE'],
    departmentName: entry['DEPT_NAME'],
    sectionCode: entry['SECTION_CODE'],
    costCentre: entry['COST_CENTRE_CODE'],
    gLCode: entry['GL_CODE'],
    jobDescription: entry['JOB_NAME'],
    workOrderOwner: '', // Not available in provided data
    contractorSupervisor: '', // Not available in provided data
    serviceDetails: [{
      itemNum: entry['ITEM_NUM'],
      sVCLnItemNum: entry['SVC_LN_ITEM_NUM'],
      // Add mappings for other service detail attributes here 
      sVCLnItemDel:entry['SVC_LN_ITEM_DEL'],
      sVCNum: entry['SVC_NUM'],	
      sVCLnItemName: entry['SVC_LN_ITEM_NAME'],
      itemDel: entry["ITEM_DEL"],	
      delvCompletionSw: entry["DELV_COMPLETION_SW"],
      itemChangedOnDate: entry["ITEM_CHANGED_ON_DATE"],
      vendorCode: entry["VENDOR_CODE"],
      contractorName: entry["VENDOR_NAME"],
      vendorAddress: entry["VENDOR_ADDRESS"],
      vendorBlocked: entry["VENDOR_BLOCKED"],
      validFrom: entry["WORKORDER_VALID_FROM"],
      validTo: entry["WORKORDER_VALID_TO"],
      workOrderType: entry["SAP_WORKORDER_TYPE"],	
      unitCode: entry["UNIT_CODE"],
      sectionCode: entry["SECTION_CODE"],
      sectionName: entry["SECTION_NAME"],
      sectionDescription: entry["SECTION_DESCRIPTION"],
      gLCode:entry["GL_CODE"],
      costCentre:entry["COST_CENTRE_CODE"],
      jobName:entry["JOB_NAME"],	
      rate:entry["RATE"],	
      qty: entry["QTY"],
      uom: entry["UOM"],
      workorderReleasedSw: entry["WORKORDER_RELEASED_SW"],
      pmWorkorderNum: entry["PM_WORKORDER_NUM"],
      wbsElement: entry["WBS_ELEMENT"],
      completedQty: entry["COMPLETED_QTY"],
      workorderReleasedDate: entry["WORKORDER_RELEASED_DATE"],
      sVCEntryCreatedDate: entry["SVC_ENTRY_CREATED_DATE"],
      sVCEntryUpdatedDate: entry["SVC_ENTRY_UPDATED_DATE"],	
      recordCreatedOn:entry["RECORD_CREATED_ON"],
      recordUpdatedOn: entry["RECORD_UPDATED_ON"],
      recordStatus: entry["RECORD_STATUS"],
      recordStatusText: entry["RECORD_STATUS_TEXT"],
      recordCreatedBy: entry["RECORD_CREATED_BY"],
      recordUpdatedBy:entry["RECORD_UPDATED_BY"],
      deptCode: entry["DEPT_CODE"],
      departmentName: entry["DEPT_NAME"],
      deptDescription: entry["DEPT_DESCRIPTION"],
      trade: entry["TRADE"],
      skill: entry["SKILL"],
      storageLocation: entry["STORAGE_LOCATION"],
      netAmount: entry["NET_AMOUNT"],	
      marginZcm1: entry["MARGIN_ZCM1"],	
      discountRa01: entry["DISCOUNT_RA01"],
      marginCmsZcm2: entry["MARGIN_CMS_ZCM2"],
      marginCmsQtyZcmq: entry["MARGIN_CMS_QTY_ZCMQ"],
      surchargeZa00: entry["SURCHARGE_ZA00"],	
      toolsTacklesZttc: entry["TOOLS_TACKLES_ZTTC"],
      taxCode: entry["TAX_CODE"],
      cgstTaxRate: entry["CGST_TAX_RATE"],
      sgstTaxRate: entry["SGST_TAX_RATE"],
      igstTaxRate: entry["IGST_TAX_RATE"],
      utgstTaxRate: entry["UTGST_TAX_RATE"],
      companyCode: entry["COMPANY_CODE"],
      paymentTerms: entry["PAYMENT_TERMS"],
      vendorStateCodeGst: entry["VENDOR_STATE_CODE_GST"],
      plantStateCodeGst: entry["PLANT_STATE_CODE_GST"],
      vendorGstin: entry["VENDOR_GSTIN"],
      plantGstin: entry["PLANT_GSTIN"],
      hraQty: entry["HRA_QTY"],	
      eInvoiceEligibility: entry["E_INVOICE_ELIGIBILITY"]
    }]
    
  };
};


const WorkOrderList = () => {
  const { userConfig } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    unit: null,
    status: null,
    sectionCode: null,
    validFrom: null,
    validTo: null,
  });
 

  console.log("userConfig", userConfig);
  
  const [jsonData, setJsonData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();
  const isLRPage = location.pathname.includes('scheduleworkmen');
  const [pageSize, setPageSize] = useState();

  useEffect(() => {
    getWorkOrderDetailsData()
      .then(data => {
        console.log("ggg", data.data[0]);
        
        const dataWithKeys = data?.data?.map((item, index) => ({
          ...item,
          key: item.id || index, // Use an existing unique identifier or fallback to index
        }));
        setTableData(dataWithKeys);
        setFilteredData(dataWithKeys);
      })
      .catch(error => {
        console.error('Error fetching work order details data:', error);
      });
  }, []);

  console.log("data", filteredData);

const handleFileUpload = async (file) => {
  console.log("file", file);
  try {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      const sheetData = XLSX.utils.sheet_to_json(firstSheet);

      // Function to convert Excel date serial number to Date object
      function excelDateToDate(excelDate) {
        if (typeof excelDate === 'number') {
          // Define the base date for Excel (December 30, 1899)
          const excelEpoch = new Date(1899, 11, 30);
          // Calculate the number of milliseconds per day
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          // Calculate the offset in milliseconds from the base date
          const millisecondsOffset = (excelDate - 1) * millisecondsPerDay;
          // Add the offset to the base date to get the corresponding JavaScript Date object
          return new Date(excelEpoch.getTime() + millisecondsOffset);
        } else if (typeof excelDate === 'string') {
          const [day, month, year] = excelDate.split('-').map(Number);
          // JavaScript months are 0-based, so we need to subtract 1 from the month
          return new Date(year, month - 1, day);
        } else {
          return null; // If the date format is unexpected
        }
      }

      function formatDate(date) {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
          return ''; // Return empty string if the date is invalid
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      // Transform the sheet data into the desired payload format
      const payload = sheetData.reduce((acc, entry) => {
        const workOrderNumber = JSON.stringify(entry['WORKORDER_NUM']);
        const existingEntry = acc.find(item => item.workOrderNumber === workOrderNumber);

        const serviceDetail = {
          itemNum: entry['ITEM_NUM'],
          sVCLnItemNum: entry['SVC_LN_ITEM_NUM'],
          sVCLnItemDel: entry['SVC_LN_ITEM_DEL'],
          sVCNum: entry['SVC_NUM'],
          sVCLnItemName: entry['SVC_LN_ITEM_NAME'],
          itemDel: entry['ITEM_DEL'],
          delvCompletionSw: entry['DELV_COMPLETION_SW'],
          itemChangedOnDate: entry['ITEM_CHANGED_ON_DATE'] ? formatDate(excelDateToDate(entry['ITEM_CHANGED_ON_DATE'])) : '',
          vendorCode: JSON.stringify(entry['VENDOR_CODE']),
          contractorName: entry['VENDOR_NAME'],
          vendorAddress: entry['VENDOR_ADDRESS'],
          vendorBlocked: entry['VENDOR_BLOCKED'],
          validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
          validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
          workOrderType: entry['SAP_WORKORDER_TYPE'],
          unitCode: entry['UNIT_CODE'],
          sectionCode: entry['SECTION_CODE'],
          sectionName: entry['SECTION_NAME'],
          sectionDescription: entry['SECTION_DESCRIPTION'],
          gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
          costCentre: entry['COST_CENTRE_CODE'],
          jobName: entry['JOB_NAME'],
          rate: entry['RATE'],
          qty: entry['QTY'],
          uom: entry['UOM'],
          workorderReleasedSw: entry['WORKORDER_RELEASED_SW'],
          pmWorkorderNum: entry['PM_WORKORDER_NUM'],
          wbsElement: entry['WBS_ELEMENT'],
          completedQty: entry['COMPLETED_QTY'],
          workorderReleasedDate: entry['WORKORDER_RELEASED_DATE'] ? formatDate(excelDateToDate(entry['WORKORDER_RELEASED_DATE'])) : '',
          sVCEntryCreatedDate: entry['SVC_ENTRY_CREATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_CREATED_DATE'])) : '',
          sVCEntryUpdatedDate: entry['SVC_ENTRY_UPDATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_UPDATED_DATE'])) : '',
          recordCreatedOn: entry['RECORD_CREATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_CREATED_ON'])) : '',
          recordUpdatedOn: entry['RECORD_UPDATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_UPDATED_ON'])) : '',
          recordStatus: entry['RECORD_STATUS'],
          recordStatusText: entry['RECORD_STATUS_TEXT'],
          recordCreatedBy: entry['RECORD_CREATED_BY'],
          recordUpdatedBy: entry['RECORD_UPDATED_BY'],
          deptCode: entry['DEPT_CODE'],
          departmentName: entry['DEPT_NAME'],
          deptDescription: entry['DEPT_DESCRIPTION'],
          trade: entry['TRADE'],
          skill: entry['SKILL'],
          storageLocation: entry['STORAGE_LOCATION'],
          netAmount: entry['NET_AMOUNT'],
          marginZcm1: entry['MARGIN_ZCM1'],
          discountRa01: entry['DISCOUNT_RA01'],
          marginCmsZcm2: entry['MARGIN_CMS_ZCM2'],
          marginCmsQtyZcmq: entry['MARGIN_CMS_QTY_ZCMQ'],
          surchargeZa00: entry['SURCHARGE_ZA00'],
          toolsTacklesZttc: entry['TOOLS_TACKLES_ZTTC'],
          taxCode: entry['TAX_CODE'],
          cgstTaxRate: entry['CGST_TAX_RATE'],
          sgstTaxRate: entry['SGST_TAX_RATE'],
          igstTaxRate: entry['IGST_TAX_RATE'],
          utgstTaxRate: entry['UTGST_TAX_RATE'],
          companyCode: entry['COMPANY_CODE'],
          paymentTerms: entry['PAYMENT_TERMS'],
          vendorStateCodeGst: entry['VENDOR_STATE_CODE_GST'],
          plantStateCodeGst: entry['PLANT_STATE_CODE_GST'],
          vendorGstin: entry['VENDOR_GSTIN'],
          plantGstin: entry['PLANT_GSTIN'],
          hraQty: entry['HRA_QTY'],
          eInvoiceEligibility: entry['E_INVOICE_ELIGIBILITY']
        };

        if (existingEntry) {
          existingEntry.serviceDetails.push(serviceDetail);
        } else {
          acc.push({
            workOrderNumber: entry['WORKORDER_NUM'],
            unitName: entry['UNIT_CODE'],
            contractorName: entry['VENDOR_NAME'],
            vendorCode: entry['VENDOR_CODE'],
            validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
            validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
            workOrderType: entry['SAP_WORKORDER_TYPE'],
            departmentName: entry['DEPT_NAME'],
            sectionCode: entry['SECTION_CODE'],
            gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
            jobDescription: entry['JOB_NAME'],
            workOrderOwner: entry['WORKORDER_OWNER'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            costCentre: entry['COST_CENTER_CODE'],
            sectionHead: entry['SECTION_HEAD'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            status: entry['STATUS'],
            contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
            costCentre: entry['COST_CENTRE_CODE'],
            serviceDetails: [serviceDetail]
          });
        }

        return acc;
      }, []);

      console.log('Formatted Sheet Data', sheetData, payload);

      // Upload the parsed data to the server
      const response = await uploadWorkOrderDetailsData(payload);
      console.log('Backend response:', response);
      // setTableData(response); // Update table data with the response from the server
      // setFilteredData(response); // Update filtered data as well
      message.success('File uploaded successfully!');
      window.location.reload();
      // Handle the response as needed
    };

    reader.readAsArrayBuffer(file);
  } catch (error) {
    console.error('Error uploading file:', error);
    message.error('Failed to upload file.');
    // Handle the error
  }
};

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const handleUploadSuccess = (newData) => {
    // setTableData(newData); // Update the table data with the new data from the server
    setTableData(newData);
    setFilteredData(newData);
    handleReset();
  };



  const beforeUpload = (file) => {
    // handleFileUpload(file);
    return false; // Prevent default Upload behavior
  };
  
  const customRequest = ({ file, onSuccess }) => {
    onSuccess(file);
  };

  // const handleSearch = () => {
  //   let data = [...tableData];
  //   console.log("Filters:", filters);
  //   console.log("Table Data:", tableData);
  //   // let data = [...tableData];
  //   // data = tableData;

  //   // Debugging: Log data to ensure it contains expected values
  //   console.log("Original Data:", data);
  //   // if (filters.search) {
  //     console.log("Applying search filter for:", filters.search);
  //     if (filters.search) {
  //       data = data.filter((item) =>
  //         [
  //           item.workOrderNumber,
  //           item.workOrderType,
  //           item.jobDescription,
  //           item.sectionCode,
  //           item.sectionHead,
  //           item.contractorName,
  //           item.unitName,
  //           item.status,
  //           item.validFrom,
  //           item.validTo,
  //         ].some((field) =>
  //           field?.toString().toLowerCase().includes(filters.search.toLowerCase())
  //         )
  //       );
  //     }
  // // }

  //   if (filters.unit) {
  //     data = data.filter(item => item.unitName.includes(filters.unit));
  //   }
  //   if (filters.status) {
  //     console.log("filters.status", filters.status);
  //     data = data.filter(item => item.status.includes(filters.status));
  //   }
  //   if (filters.sectionCode) {
  //     data = data.filter(item => item.sectionCode.includes(filters.sectionCode));
  //   }
  //   if (filters.validFrom) {
  //     console.log("Applying validFrom filter for:", filters.validFrom);
  //     console.log("data", data);
  //     data = data.filter(item => 
  //       moment(item.validFrom, 'YYYY-MM-DD').isSameOrAfter(moment(filters.validFrom, 'DD-MM-YYYY'))
  //     );
  //   }
  //   if (filters.validTo) {
  //     console.log("Applying validTo filter for:", filters.validTo);
  //     data = data.filter(item => 
  //       moment(item.validTo, 'YYYY-MM-DD').isSameOrBefore(moment(filters.validTo, 'DD-MM-YYYY'))
  //     );
  //   }

  //   console.log("Filtered Data:", data);

  //   setFilteredData(data);
  // };

  const handleSearch = async () => {
    try {
      // Fetch filtered data from backend
      const res = await getSearchData(filters);
      // setres(res)
      const actualData = res?.data?.data
      // setres(actualData)
      console.log("Backend Filtered Data:", actualData);
   
      // Set the initial filtered data based on the backend response
      let data = [...actualData];
   
      if (filters.search) {
        data = data.filter((item) =>
          [
            item?.unit,
            item?.status,
            item?.sectionCode,
            item?.validFrom,
            item?.validTo,
           
          ].some((field) =>
            field?.toString().toLowerCase().includes(filters.search.toLowerCase())
          )
        );
      }
   
      // Apply additional frontend filters if necessary
      if (filters.unit) {
        data = data.filter(item => item.unitName.includes(filters.unit));
      }
      if (filters.status) {
        console.log("Applying status filter:", filters.status);
        data = data.filter(item => item.status.includes(filters.status));
      }
      if (filters.sectionCode) {
        data = data.filter(item => item.sectionCode.includes(filters.sectionCode));
      }
      if (filters.validFrom) {
        console.log("Applying validFrom filter for:", filters.validFrom);
        data = data.filter(item =>
          moment(item.validFrom, 'YYYY-MM-DD').isSameOrAfter(moment(filters.validFrom, 'DD-MM-YYYY'))
        );
      }
      if (filters.validTo) {
        console.log("Applying validTo filter for:", filters.validTo);
        data = data.filter(item =>
          moment(item.validTo, 'YYYY-MM-DD').isSameOrBefore(moment(filters.validTo, 'DD-MM-YYYY'))
        );
      }
   
      // Log the final filtered data and update state
      console.log("Final Filtered Data:", data);
      setFilteredData(data);
   
    } catch (error) {
      console.error("Error fetching backend data:", error);
    }
  };
   
 
  useEffect(() => {
    handleSearch();
  }, [filters?.search]);

  const handleReset = () => {
    
    setFilters({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
    setFilteredData(tableData);
    
    // form.resetFields(['search', 'unit', 'status', 'sectionCode', 'validFrom', 'validTo']);
    // Manually reset form fields immediately after state update
    form.setFieldsValue({
      search: '',
      unit: null,
      status: null,
      sectionCode: null,
      validFrom: null,
      validTo: null,
    });
  };

  // const uniqueUnits = [...new Set(filteredData.map(record => record.unitName))];
  const uniqueUnits = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.unitName))] : [];
  // const uniqueStatuses = [...new Set(filteredData.map(record => record.status))];
  const uniqueStatuses = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.status))] : [];
  // const uniqueSectionCodes = [...new Set(filteredData.map(record => record.sectionCode))];
  const uniqueSectionCodes = filteredData && filteredData.length > 0 ? [...new Set(filteredData.map(record => record.sectionCode))] : [];

  const columns = [
    {
      title: 'Work Order Number',
      dataIndex: 'workOrderNumber',
      key: 'workOrderNumber',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.workOrderNumber}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderNumber}
          </Link>
        )
      ),
    },
    {
      title: 'Work Order Type',
      dataIndex: 'workOrderType',
      key: 'workOrderType',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.workOrderType}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.workOrderType}
          </Link>
        )
      ),
    },
    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      key: 'jobDescription',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.jobDescription}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.jobDescription}
          </Link>
        )
      ),
    },
    {
      title: 'Section Code',
      dataIndex: 'sectionCode',
      key: 'sectionCode',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.sectionCode}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.sectionCode}
          </Link>
        )
      ),
    },
    // {
    //   title: 'Section Head',
    //   dataIndex: 'sectionHead',
    //   key: 'sectionHead',
    // },
    {
      title: 'WO Valid From ',
      width:'11%',
      dataIndex: 'validFrom',
      key:'validFrom',
      // render: (text, record) => `${record.validFrom} - ${record.validTo}`,
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {/* {record.validFrom} */}
        // {dayjs(record.validFrom).format('DD-MM-YYYY')}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validFrom} */}
            {dayjs(record.validFrom).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'WO Valid To ',
      width:'10%',
      dataIndex: 'validTo',
      key:'validTo',
      // render: (text, record) => `${record.validFrom} - ${record.validTo}`,
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {/* {record.validTo} */}
        // {dayjs(record.validTo).format('DD-MM-YYYY')}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {/* {record.validTo} */}
            {dayjs(record.validTo).format('DD-MM-YYYY')}
          </Link>
        )
      ),
    },
    {
      title: 'Contractor Name',
      dataIndex: 'contractorName',
      key: 'contractorName',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.contractorName}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.contractorName}
          </Link>
        )
      ),
    },
    {
      title: 'Unit Name',
      dataIndex: 'unitName',
      key: 'unitName',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.unitName}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.unitName}
          </Link>
        )
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        // <Link 
        // to={`/workorderDetails/${record.workOrderNumber}`}
        // style={{ textDecoration: 'none', color: 'inherit' }}
        // >
        // {record.status}
        // </Link>
        isLRPage ? (
          <Link 
            to={`/scheduleworkmen/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        ) : (
          <Link 
            to={`/home/department/sectionhead/workorderlist/workorderDetails/${record.workOrderNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {record.status}
          </Link>
        )
      ),
    },
  ];

console.log("fiter",filteredData);
// const columns = Object.keys(tableData[0] || {}).map(key => ({
//     title: key,
//     dataIndex: key,
//     key: key
//   }));

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={6}>
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Work Order List</span>
        </Col>
        <Col span={12}>
        <Form form={form}>
            <Form.Item name="search" initialValue={filters.search}>
          <Input
            // placeholder={filters.search || "Search"}
            placeholder="Search"
            // placeholder={filters.search ? filters.search : "Search"}
            // value={filters.search}
            // value={filters.search || ""}
            onChange={e => setFilters({ ...filters, search: e.target.value })}
            style={{ width: '100%'}}
            suffix={<SearchOutlined />}
          />
          </Form.Item>
          </Form>
        </Col>
        { !isLRPage && (
        <Col span={6} style={{ textAlign: 'right' }}>
        {/* <Upload customRequest={customRequest} beforeUpload={beforeUpload} showUploadList={false}>
          <Button type="primary" icon={<UploadOutlined />} >
            Import Work Order
          </Button>
          
        </Upload> */}
        {userConfig.level === "sectionHead" &&
        <Button type="primary" icon={<UploadOutlined />} onClick={showModal}>
            Import Work Order
        </Button>
        }
        <BulkUploadModal visible={isModalVisible} onClose={handleClose} onUploadSuccess={handleUploadSuccess} isWorkOrderSheet={true} isMinWageSheet={false}/>
         
        </Col>
      )}
      </Row>
      <div style={{ backgroundColor: '#F4F6FF', border: '1px solid #d9d9d9', padding: '16px', borderRadius: '4px' }}>
      <Form form={form}>  
        <Row gutter={16}>
            <Col span={4}>
            <Form.Item name="unit" initialValue={filters.unit}>
            <Select
                placeholder="Unit"
                // value={filters.unit}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, unit: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
              {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                <Option key={element.unitName} value={element.unitName}>{element.unitName}</Option>
              ))} */}
              {/* {uniqueUnits && uniqueUnits.map(unit => (
                <Option key={unit} value={unit}>
                  {unit}
                </Option>
              ))} */}
              return (
                  <>
                    {uniqueUnits.length > 0 ? (
                      uniqueUnits.map(unit => (
                        <Option key={unit} value={unit}>
                          {unit}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="status" initialValue={filters.status}>
            <Select
                placeholder="Status"
                // value={filters.status}
                style={{ width: '100%' }}
                onChange={value => setFilters({ ...filters, status: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
               {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                  <Option key={element.status} value={element.status}>{element.status}</Option>
               ))} */}
                {/* {uniqueStatuses.map(status => (
                  <Option key={status} value={status}>
                    {status}
                  </Option>
                ))} */}
                return (
                  <>
                    {uniqueStatuses.length > 0 ? (
                      uniqueStatuses.map(status => (
                        <Option key={status} value={status}>
                          {status}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
                {/* <Option value="Status1">Status1</Option>
                <Option value="Status2">Status2</Option> */}
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="sectionCode" initialValue={filters.sectionCode}>
            <Select
                placeholder="Section Code"
                // value={filters.sectionCode}
                style={{ width: '100%'}}
                onChange={value => setFilters({ ...filters, sectionCode: value })}
                disabled={!filteredData || filteredData.length === 0}
            >
               {/* {filteredData && filteredData.length > 0 && filteredData.map(element => (
                  <Option key={element.sectionCode} value={element.sectionCode}>{element.sectionCode}</Option>
                ))} */}
                {/* {uniqueSectionCodes.map(sectionCode => (
                  <Option key={sectionCode} value={sectionCode}>
                    {sectionCode}
                  </Option>
                ))} */}
                return (
                  <>
                    {uniqueSectionCodes.length > 0 ? (
                      uniqueSectionCodes.map(sectionCode => (
                        <Option key={sectionCode} value={sectionCode}>
                          {sectionCode}
                        </Option>
                      ))
                    ) : (
                      <Option disabled></Option>
                    )}
                  </>
                );
                {/* <Option value="ADM">ADM</Option>
                <Option value="HR">HR</Option> */}
            </Select>
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validFrom" initialValue={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid From"
                // value={filters.validFrom ? moment(filters.validFrom, 'DD-MM-YYYY') : null}
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                // onChange={date => setFilters({ ...filters, validFrom: date })}
                onChange={(date, dateString) => setFilters({ ...filters, validFrom: dateString })}
            />
            </Form.Item>
            </Col>
            <Col span={4}>
            <Form.Item name="validTo" initialValue={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}>
            <DatePicker
                placeholder="Valid To"
                style={{ width: '100%' }}
                // value={filters.validTo ? moment(filters.validTo, 'DD-MM-YYYY') : null}
                format="DD-MM-YYYY"
                // onChange={date => setFilters({ ...filters, validTo: date })}
                onChange={(date, dateString) => setFilters({ ...filters, validTo: dateString })}
            />
            </Form.Item>
            </Col>
            {/* <Col span={3}>
            <Button
                type=""
                border= "primary"
                icon={<SearchOutlined />}
                onClick={handleSearch}
                style={{ width: '100%' }}
            >
                Search
            </Button>
            </Col> */}
            <Col span={4}>
            <Button
                icon={<ReloadOutlined />}
                onClick={handleReset}
                style={{ width: '100%' }}
            >
                Reset
            </Button>
            </Col>
        </Row>
        </Form>
      </div>
      {filteredData && (
      <Table columns={columns} dataSource={filteredData} 
      rowKey="id"
      // pagination={{
      //   pageSize: pageSize,
      //   showSizeChanger: true, // Enable the page size changer dropdown
      //   pageSizeOptions: ["10", "20", "50", "100"], // Options for page sizes
      //   // onShowSizeChange: (current, size) => setPageSize(size), // Update page size on change
      // }}
        // scroll={{ x: 3000 }}
        // sticky
        pagination = {true}
      />
      )}
    </div>
  );
};

export default WorkOrderList;
