import {uploadMinimumData, getMinimumWageData} from '../../../services/api/minimumWage';


// export const uploadMinimumDatas = async (mappedData, orgId) => {
//     console.log("data", mappedData, orgId);
//     // const response = await uploadMinimumData(data, orgID);
//     // console.log('value of post data', response);
//     // return response; // Return response if needed
//     try {
//       const data = await uploadMinimumData(mappedData, orgId);
//       // Handle success, e.g., update state or notify user
//   } catch (err) {
//       if (err.response) {
//           const { status, data } = err.response;
//           // Handle based on status code
//           switch (status) {
//               case 400:
//                   // Handle Bad Request
//                   console.error("Bad Request:", data);
//                   // message.error("Bad Request: " + (data.message || "Invalid data."));
//                   break;
//               case 500:
//                   // Handle Internal Server Error
//                   console.error("Internal Server Error:", data);
//                   // message.error("Server Error: Please try again later.");
//                   break;
//               // Add more cases as needed
//               default:
//                   console.error(`Error ${status}:`, data);
//                   // message.error(`Error ${status}: ${data.message || "An error occurred."}`);
//           }
//       } else if (err.request) {
//           // The request was made but no response was received
//           console.error("No response received from server:", err.request);
//           // message.error("Network Error: No response received from server.");
//       } else {
//           // Something happened in setting up the request that triggered an Error
//           console.error("Error setting up the request:", err.message);
//           // message.error("Error: " + err.message);
//       }
//   }
// };

// export const getMinimumWageDatas = async (data) => {
//     //Get Data
//     try{
//         return await getMinimumWageData(data);
//       } catch (error) {
//         console.error('Error fetching work order details data:', error);
//         return []; // Return an empty array in case of an error
//       }
//   };

export const uploadMinimumDatas = async (mappedData, orgId) => {
  try {
      const data = await uploadMinimumData(mappedData);
      // Handle success
  } catch (err) {
      if (err.response) {
          const { status, data } = err.response;
          switch (status) {
              case 400:
                  console.error("Bad Request:", data);
                  break;
              case 500:
                  console.error("Internal Server Error:", data);
                  break;
              default:
                  console.error(`Error ${status}:`, data);
          }
      } else if (err.request) {
          console.error("No response received from server:", err.request);
      } else {
          console.error("Error setting up the request:", err.message);
      }
  }
};


export const getMinimumWageDatas = async () => {
  try {
    return await getMinimumWageData();
  } catch (error) {
    // Check if error.response exists before accessing its properties
    if (error.response) {
      console.error('Error fetching work order details data:', error.response.status, error.response.data);
    } else {
      // Handle cases where error.response is undefined (e.g., network issues)
      console.error('Error fetching work order details data:', error.message);
    }
    
    // Return an empty array in case of an error
    return [];
  }
};
