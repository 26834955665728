// import React, { useState } from 'react';
// import { Modal, Upload, Button, Icon, Typography, Row, Col, Divider, message } from 'antd';
// import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
// import { ArrowRightOutlined } from '@ant-design/icons';
// import {uploadWorkOrderDetailsData, getExcelTemplateDetailsData, getDownloadExcelFile} from '../actions/api/workOrderDetails'
// import * as XLSX from 'xlsx';

// const { Title, Text } = Typography;

// const BulkUploadModal = ({ visible, onClose, onUploadSuccess }) => {
//   const [fileList, setFileList] = useState([]);
//   const [sheetData, setSheetData] = useState([]);
//   const [downloading, setDownloading] = useState(false);

//   const handleDownload = async() => {
    
//       try {
//           setDownloading(true);
//           // Fetch the template file URL
//           const templateResponse = await getExcelTemplateDetailsData();
//           console.log("Response:", templateResponse);

//           const downloadResponse = await getDownloadExcelFile();

//           // Convert the blob into a downloadable file
//           const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
//           const a = document.createElement('a');
//           a.href = url;
//           a.download = 'workorder_template.xlsx';
//           document.body.appendChild(a);
//           a.click();
//           a.remove();
//         } catch (error) {
//           console.error('Error downloading file:', error);
//           // Handle error (e.g., show an error message to the user)
//         }

//   };


// //   const handleUpload = ({ fileList }) => setFileList(fileList);
// const handleUpload = async (file) => {
//     console.log("file", file);
//     try {
//       const reader = new FileReader();
  
//       reader.onload = async (e) => {
//         const data = new Uint8Array(e.target.result);
//         const workbook = XLSX.read(data, { type: 'array' });
  
//         const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
//         const sheetData = XLSX.utils.sheet_to_json(firstSheet);
//         setSheetData(sheetData);
//         message.success('File loaded successfully!');
        
//       }
//       reader.readAsArrayBuffer(file);
//       setFileList([file]);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       message.error('Failed to upload file.');
//     }
//   };

//   const handleSubmit = async() =>{

//     // Function to convert Excel date serial number to Date object
//     function excelDateToDate(excelDate) {
//         if (typeof excelDate === 'number') {
//             // Define the base date for Excel (December 30, 1899)
//             const excelEpoch = new Date(1899, 11, 30);
//             // Calculate the number of milliseconds per day
//             const millisecondsPerDay = 24 * 60 * 60 * 1000;
//             // Calculate the offset in milliseconds from the base date
//             const millisecondsOffset = (excelDate - 1) * millisecondsPerDay;
//             // Add the offset to the base date to get the corresponding JavaScript Date object
//             return new Date(excelEpoch.getTime() + millisecondsOffset);
//         } else if (typeof excelDate === 'string') {
//             const [day, month, year] = excelDate.split('-').map(Number);
//             // JavaScript months are 0-based, so we need to subtract 1 from the month
//             return new Date(year, month - 1, day);
//         } else {
//             return null; // If the date format is unexpected
//         }
//     }

//     function formatDate(date) {
//         if (!(date instanceof Date) || isNaN(date.getTime())) {
//             return ''; // Return empty string if the date is invalid
//         }
//         const year = date.getFullYear();
//         const month = String(date.getMonth() + 1).padStart(2, '0');
//         const day = String(date.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     }
    
//     try {
//         const payload = sheetData.reduce((acc, entry) => {
//         const workOrderNumber = entry['WORKORDER_NUM'];
//         const existingEntry = acc.find(item => item.workOrderNumber === workOrderNumber);

//         const serviceDetail = {
//           itemNum: entry['ITEM_NUM'],
//           sVCLnItemNum: entry['SVC_LN_ITEM_NUM'],
//           sVCLnItemDel: entry['SVC_LN_ITEM_DEL'],
//           sVCNum: entry['SVC_NUM'],
//           sVCLnItemName: entry['SVC_LN_ITEM_NAME'],
//           itemDel: entry['ITEM_DEL'],
//           delvCompletionSw: entry['DELV_COMPLETION_SW'],
//           itemChangedOnDate: entry['ITEM_CHANGED_ON_DATE'] ? formatDate(excelDateToDate(entry['ITEM_CHANGED_ON_DATE'])) : '',
//           vendorCode: entry['VENDOR_CODE'],
//           contractorName: entry['VENDOR_NAME'],
//           vendorAddress: entry['VENDOR_ADDRESS'],
//           vendorBlocked: entry['VENDOR_BLOCKED'],
//           validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
//           validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
//           workOrderType: entry['SAP_WORKORDER_TYPE'],
//           unitCode: entry['UNIT_CODE'],
//           sectionCode: entry['SECTION_CODE'],
//           sectionName: entry['SECTION_NAME'],
//           sectionDescription: entry['SECTION_DESCRIPTION'],
//           gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
//           costCentre: entry['COST_CENTRE_CODE'],
//           jobName: entry['JOB_NAME'],
//           rate: entry['RATE'],
//           qty: entry['QTY'],
//           uom: entry['UOM'],
//           workorderReleasedSw: entry['WORKORDER_RELEASED_SW'],
//           pmWorkorderNum: entry['PM_WORKORDER_NUM'],
//           wbsElement: entry['WBS_ELEMENT'],
//           completedQty: entry['COMPLETED_QTY'],
//           workorderReleasedDate: entry['WORKORDER_RELEASED_DATE'] ? formatDate(excelDateToDate(entry['WORKORDER_RELEASED_DATE'])) : '',
//           sVCEntryCreatedDate: entry['SVC_ENTRY_CREATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_CREATED_DATE'])) : '',
//           sVCEntryUpdatedDate: entry['SVC_ENTRY_UPDATED_DATE'] ? formatDate(excelDateToDate(entry['SVC_ENTRY_UPDATED_DATE'])) : '',
//           recordCreatedOn: entry['RECORD_CREATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_CREATED_ON'])) : '',
//           recordUpdatedOn: entry['RECORD_UPDATED_ON'] ? formatDate(excelDateToDate(entry['RECORD_UPDATED_ON'])) : '',
//           recordStatus: entry['RECORD_STATUS'],
//           recordStatusText: entry['RECORD_STATUS_TEXT'],
//           recordCreatedBy: entry['RECORD_CREATED_BY'],
//           recordUpdatedBy: entry['RECORD_UPDATED_BY'],
//           deptCode: entry['DEPT_CODE'],
//           departmentName: entry['DEPT_NAME'],
//           deptDescription: entry['DEPT_DESCRIPTION'],
//           trade: entry['TRADE'],
//           skill: entry['SKILL'],
//           storageLocation: entry['STORAGE_LOCATION'],
//           netAmount: entry['NET_AMOUNT'],
//           marginZcm1: entry['MARGIN_ZCM1'],
//           discountRa01: entry['DISCOUNT_RA01'],
//           marginCmsZcm2: entry['MARGIN_CMS_ZCM2'],
//           marginCmsQtyZcmq: entry['MARGIN_CMS_QTY_ZCMQ'],
//           surchargeZa00: entry['SURCHARGE_ZA00'],
//           toolsTacklesZttc: entry['TOOLS_TACKLES_ZTTC'],
//           taxCode: entry['TAX_CODE'],
//           cgstTaxRate: entry['CGST_TAX_RATE'],
//           sgstTaxRate: entry['SGST_TAX_RATE'],
//           igstTaxRate: entry['IGST_TAX_RATE'],
//           utgstTaxRate: entry['UTGST_TAX_RATE'],
//           companyCode: entry['COMPANY_CODE'],
//           paymentTerms: entry['PAYMENT_TERMS'],
//           vendorStateCodeGst: entry['VENDOR_STATE_CODE_GST'],
//           plantStateCodeGst: entry['PLANT_STATE_CODE_GST'],
//           vendorGstin: entry['VENDOR_GSTIN'],
//           plantGstin: entry['PLANT_GSTIN'],
//           hraQty: entry['HRA_QTY'],
//           contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
//           eInvoiceEligibility: entry['E_INVOICE_ELIGIBILITY']
//         };

//         if (existingEntry) {
//           existingEntry.serviceDetails.push(serviceDetail);
//         } else {
//           acc.push({
//             workOrderNumber: entry['WORKORDER_NUM'],
//             companyCode: entry['COMPANY_CODE'],
//             unitName: entry['UNIT_CODE'],
//             unitCode: entry['UNIT_CODE'],
//             deptCode: entry['DEPT_CODE'],           
//             sectionCode: entry['SECTION_CODE'],
//             vendorCode: entry['VENDOR_CODE'],
//             contractorSupervisor: entry['CONTRACTOR_SUPERVISOR'],
//             contractorName: entry['VENDOR_NAME'],
//             validFrom: entry['WORKORDER_VALID_FROM'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_FROM'])) : '',
//             validTo: entry['WORKORDER_VALID_TO'] ? formatDate(excelDateToDate(entry['WORKORDER_VALID_TO'])) : '',
//             workOrderType: entry['SAP_WORKORDER_TYPE'],
//             departmentName: entry['DEPT_NAME'],
//             gLCode: entry['GL_CODE'] ? String(entry['GL_CODE']) : "",
//             jobDescription: entry['JOB_NAME'],
//             workOrderOwner: entry['WORKORDER_OWNER'],
//             costCentre: entry['COST_CENTER_CODE'],
//             sectionHead: entry['SECTION_HEAD'],
//             status: entry['STATUS'],
//             costCentre: entry['COST_CENTRE_CODE'],
//             serviceDetails: [serviceDetail]
//           });
//         }

//         return acc;
//       }, []);

//       console.log('Formatted Sheet Data', sheetData, payload);

//       // Upload the parsed data to the server
//       const response = await uploadWorkOrderDetailsData(payload);
//       console.log('Backend response:', response);
//       // setTableData(response); // Update table data with the response from the server
//       // setFilteredData(response); // Update filtered data as well
//       message.success('File uploaded successfully!');
//       window.location.reload();
//       onUploadSuccess(response); // Update table data with the response from the server
//       onClose(); // Close the modal
//       // Handle the response as needed
//   } catch (error) {
//     console.error('Error uploading file:', error);
//     message.error('Failed to upload file.');
//     // Handle the error
//   }
//   }

//   return (
//     <Modal
//       title={<Title level={3} style={{ textAlign: 'center' }}>Bulk Upload</Title>}
//       visible={visible}
//       onCancel={onClose}
//       width={800}
//       centered
//       footer={[
//         // <Button key="submit" type="primary" onClick={onClose}>
//         //   Submit
//         // </Button>,
//         // <Button key="cancel" onClick={onClose}>Cancel</Button>,
//         <Button key="submit" type="primary" onClick={handleSubmit} disabled={!fileList.length}>Submit</Button>
//       ]}
//     >
        
//       <div style={{ textAlign: 'center' }}>
//         <Row justify="center" align="middle" span ={24}>
//             <Col span = {7}>
//                 <Text strong>Download the Excel sheet</Text>
//             </Col>
//             {/* <Col>
//                 <Divider type="vertical" style={{ height: 'auto' }} />
//             </Col>
//             <Col>
//                 <ArrowRightOutlined />
//             </Col> */}
//             <Col>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                 {/* <div style={{ width: '100px', height: '1px', backgroundColor: '#000' }} /> */}
//                 {/* <div className='arrow'></div> */}
//                 <div style={{ width: '100px', height: '1px', backgroundColor: '#000' }}></div>{'>'}
// {/* <div style={{ width: 0, height: 0, borderLeft: '5px solid transparent', borderRight: '10px solid transparent', borderBottom: '11px solid #000' }}></div> */}
//                 {/* <span style={{ margin: '0 8px', fontSize: '20px' }}>→</span> */}
//                 {/* <div style={{ width: '100px', height: '1px', backgroundColor: '#000' }} /> */}
//                 </div>
//             </Col>
//             <Col>
//                 <Text strong>Fill it</Text>
//             </Col>
//             {/* <Col>
//                 <Divider type="vertical" style={{ height: 'auto' }} />
//             </Col>
//             <Col>
//                 <ArrowRightOutlined />
//             </Col> */}
//             <Col>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                 <div style={{ width: '100px', height: '1px', backgroundColor: '#000' }}></div>{'>'}
//                 {/* <span style={{ margin: '0 8px', fontSize: '20px' }}>→</span> */}
//                 {/* <div style={{ width: '100px', height: '1px', backgroundColor: '#000' }} /> */}
//                 </div>
//             </Col>
//             <Col span = {7}>    
//                 <Text strong>Upload the filled Excel sheet</Text>
//             </Col>
//         </Row><br/>
//         <Row gutter={24} align="middle">
//           <Col span={11}>
//             <DownloadOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
//             <Title level={4}>Excel sheet template</Title>
//             <Button icon={<DownloadOutlined />} onClick={handleDownload} style={{color: '#1677FF', borderColor: '#1677FF'}} loading={downloading}>
//             {/* type="primary" */}
//               Download
//             </Button>
//           </Col>
//           {/* <Col span={2}>
//             <Divider type="vertical" style={{ height: '100%' }} />
//           </Col> */}
//           <Col span={2}>
//             <Divider type="vertical" style={{ height: '100px', marginLeft: '20px', marginRight: '20px', borderColor: '#000'}} />
//           </Col>
//           <Col span={11}>
//             <UploadOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
//             <Title level={4}>Upload the filled Excel sheet</Title>
//             <Upload
//               fileList={fileList}
//             //   onChange={handleUpload}
//               onChange={(info) => {
//                     const { file } = info;
//                     handleUpload(file);
//                 }}
//               beforeUpload={() => false}
//               multiple={false}
//             >
//               <Button icon={<UploadOutlined />} style={{color: '#1677FF', borderColor: '#1677FF'}}>Upload</Button>
//             </Upload>
//             {fileList.length > 0 && (
//               <div>
//                 <p>{fileList[0].name} - Uploaded</p>
//               </div>
//             )}
//           </Col>
//         </Row>
//       </div>
//     </Modal>
//   );
// };


// export default BulkUploadModal;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Upload,
  Button,
  Icon,
  Typography,
  Row,
  Col,
  Divider,
  message,
  Image
} from "antd";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  uploadWorkOrderDetailsData,
  getExcelTemplateDetailsData,
  getDownloadExcelFile,
  getExcelTemplateMinimumwage,
  getDownloadExce,
  getDownloadExcelFileMinimumWage,
} from "../../services/actions/api/workOrderDetails";
import download from '../../assets/images/Download.png'
import upload from '../../assets/images/Upload.png'
import arrow from '../../assets/images/Arrow.svg'
import * as XLSX from "xlsx";
import {
  uploadMinimumDatas,
  fetchMinimumWageData,
} from "../../services/actions/api/minimumWage";
 
const { Title, Text } = Typography;
 
const BulkUploadModal = ({
  visible,
  onClose,
  onUploadSuccess,
  isMinWageSheet,
  isWorkOrderSheet,
  uploadExcel,
}) => {
  console.log(isMinWageSheet, "isMinWageSheet");
  console.log(isWorkOrderSheet, "isWorkOrderSheet");
  const [fileList, setFileList] = useState([]);
  const [sheetData, setSheetData] = useState([]);
  const [downloading, setDownloading] = useState(false);
 
  const handleDownload = async () => {
    if (isWorkOrderSheet) {
      try {
        setDownloading(true);
        // Fetch the template file URL
        const templateResponse = await getExcelTemplateDetailsData();
        console.log("Response:", templateResponse);
 
        const downloadResponse = await getDownloadExcelFile();
 
        // Convert the blob into a downloadable file
        const url = window.URL.createObjectURL(
          new Blob([downloadResponse.data])
        );
        const a = document.createElement("a");
        a.href = url;
        a.download = "workorder_template.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      } catch (error) {
        console.error("Error downloading file:", error);
        // Handle error (e.g., show an error message to the user)
      }
    }
    if (isMinWageSheet) {
     
      try {
        setDownloading(true);
 
        const minimumWageHeaders = [
          "Location Name",
          "UNIT ID",
          "VENDOR NAME(IN SAP)",
          "VENDOR ID (SAP ID)",
          "JOB CODE",
          "SKILL CODE",
          "Sub Skill",
          "WAGE TYPE ",
          "Basic",
          "DA",
          "Skill Allowance",
          "Special Allowance",
          "Daily Batta",
          "Shift Allowance",
          "Variable Allowance",
          "Statutory Bonus",
          "NH &FH @ 12 Days",
          "Gartuity",
          "ESI",
          "Fair Wages",
          "Leave Wages",
          "Uniform Allowance",
          "Work Allowancwe",
          "Relieving Charges",
          "Training",
          "Medical Allowance",
          "Washing Allowance",
          "Education",
          "House Rent Allowance",
          "Levy",
        ];
 
        const workbook = XLSX.utils.book_new();
 
        // Create a 2D array for the worksheet (one row with all headers)
        const worksheetData = [minimumWageHeaders]; // Wrap in another array for a single row
 
        // Convert the 2D array to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
 
        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
 
        // Create a Blob from the workbook
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
 
        // Convert the blob into a downloadable file
        const url = window.URL.createObjectURL(
          new Blob([wbout], { type: "application/octet-stream" })
        );
 
        const a = document.createElement("a");
        a.href = url;
        a.download = "minimumwage_template.xlsx";
 
        document.body.appendChild(a);
        a.click();
 
        // Clean up by removing the link and revoking the object URL
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
 
        setDownloading(false);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  };
 
 
  //   const handleUpload = ({ fileList }) => setFileList(fileList);
  const handleUpload = async (file) => {
    console.log("file", file);
    try {
      const reader = new FileReader();
 
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
 
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const sheetData = XLSX.utils.sheet_to_json(firstSheet);
        setSheetData(sheetData);
        message.success("File loaded successfully!");
      };
      reader.readAsArrayBuffer(file);
      setFileList([file]);
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Failed to upload file.");
    }
  };
 
  const handleSubmit = async (e) => {
    // e.preventDefault();
    if (isMinWageSheet) {
      console.log("Success min wage upload");
      const file = fileList[0];
 
      uploadExcel(file);
    }
    if (isWorkOrderSheet) {
      console.log("Success work order sheet");
      function excelDateToDate(excelDate) {
        if (typeof excelDate === "number") {
          // Define the base date for Excel (December 30, 1899)
          const excelEpoch = new Date(1899, 11, 30);
          // Calculate the number of milliseconds per day
          const millisecondsPerDay = 24 * 60 * 60 * 1000;
          // Calculate the offset in milliseconds from the base date
          const millisecondsOffset = (excelDate - 1) * millisecondsPerDay;
          // Add the offset to the base date to get the corresponding JavaScript Date object
          return new Date(excelEpoch.getTime() + millisecondsOffset);
        } else if (typeof excelDate === "string") {
          const [day, month, year] = excelDate.split("-").map(Number);
          // JavaScript months are 0-based, so we need to subtract 1 from the month
          return new Date(year, month - 1, day);
        } else {
          return null; // If the date format is unexpected
        }
      }
 
      function formatDate(date) {
        if (!(date instanceof Date) || isNaN(date.getTime())) {
          return ""; // Return empty string if the date is invalid
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
 
      try {
        const payload = sheetData.reduce((acc, entry) => {
          const workOrderNumber = JSON.stringify(entry["WORKORDER_NUM"]);
          const existingEntry = acc.find(
            (item) => item.workOrderNumber === workOrderNumber
          );
 
          const serviceDetail = {
            itemNum: entry["ITEM_NUM"],
            sVCLnItemNum: entry["SVC_LN_ITEM_NUM"],
            sVCLnItemDel: entry["SVC_LN_ITEM_DEL"],
            sVCNum: entry["SVC_NUM"],
            sVCLnItemName: entry["SVC_LN_ITEM_NAME"],
            itemDel: entry["ITEM_DEL"],
            delvCompletionSw: entry["DELV_COMPLETION_SW"],
            itemChangedOnDate: entry["ITEM_CHANGED_ON_DATE"]
              ? formatDate(excelDateToDate(entry["ITEM_CHANGED_ON_DATE"]))
              : "",
            vendorCode: JSON.stringify(entry["VENDOR_CODE"]),
            contractorName: entry["VENDOR_NAME"],
            vendorAddress: entry["VENDOR_ADDRESS"],
            vendorBlocked: entry["VENDOR_BLOCKED"],
            validFrom: entry["WORKORDER_VALID_FROM"]
              ? formatDate(excelDateToDate(entry["WORKORDER_VALID_FROM"]))
              : "",
            validTo: entry["WORKORDER_VALID_TO"]
              ? formatDate(excelDateToDate(entry["WORKORDER_VALID_TO"]))
              : "",
            workOrderType: entry["SAP_WORKORDER_TYPE"],
            unitCode: entry["UNIT_CODE"],
            sectionCode: entry["SECTION_CODE"],
            sectionName: entry["SECTION_NAME"],
            sectionDescription: entry["SECTION_DESCRIPTION"],
            gLCode: entry["GL_CODE"] ? String(entry["GL_CODE"]) : "",
            costCentre: entry["COST_CENTRE_CODE"],
            jobName: entry["JOB_NAME"],
            rate: entry["RATE"],
            qty: entry["QTY"],
            uom: entry["UOM"],
            workorderReleasedSw: entry["WORKORDER_RELEASED_SW"],
            pmWorkorderNum: entry["PM_WORKORDER_NUM"],
            wbsElement: entry["WBS_ELEMENT"],
            completedQty: entry["COMPLETED_QTY"],
            workorderReleasedDate: entry["WORKORDER_RELEASED_DATE"]
              ? formatDate(excelDateToDate(entry["WORKORDER_RELEASED_DATE"]))
              : "",
            sVCEntryCreatedDate: entry["SVC_ENTRY_CREATED_DATE"]
              ? formatDate(excelDateToDate(entry["SVC_ENTRY_CREATED_DATE"]))
              : "",
            sVCEntryUpdatedDate: entry["SVC_ENTRY_UPDATED_DATE"]
              ? formatDate(excelDateToDate(entry["SVC_ENTRY_UPDATED_DATE"]))
              : "",
            recordCreatedOn: entry["RECORD_CREATED_ON"]
              ? formatDate(excelDateToDate(entry["RECORD_CREATED_ON"]))
              : "",
            recordUpdatedOn: entry["RECORD_UPDATED_ON"]
              ? formatDate(excelDateToDate(entry["RECORD_UPDATED_ON"]))
              : "",
            recordStatus: entry["RECORD_STATUS"],
            recordStatusText: entry["RECORD_STATUS_TEXT"],
            recordCreatedBy: entry["RECORD_CREATED_BY"],
            recordUpdatedBy: entry["RECORD_UPDATED_BY"],
            deptCode: entry["DEPT_CODE"],
            departmentName: entry["DEPT_NAME"],
            deptDescription: entry["DEPT_DESCRIPTION"],
            trade: entry["TRADE"],
            skill: entry["SKILL"],
            storageLocation: entry["STORAGE_LOCATION"],
            netAmount: entry["NET_AMOUNT"],
            marginZcm1: entry["MARGIN_ZCM1"],
            discountRa01: entry["DISCOUNT_RA01"],
            marginCmsZcm2: entry["MARGIN_CMS_ZCM2"],
            marginCmsQtyZcmq: entry["MARGIN_CMS_QTY_ZCMQ"],
            surchargeZa00: entry["SURCHARGE_ZA00"],
            toolsTacklesZttc: entry["TOOLS_TACKLES_ZTTC"],
            taxCode: entry["TAX_CODE"],
            cgstTaxRate: entry["CGST_TAX_RATE"],
            sgstTaxRate: entry["SGST_TAX_RATE"],
            igstTaxRate: entry["IGST_TAX_RATE"],
            utgstTaxRate: entry["UTGST_TAX_RATE"],
            companyCode: entry["COMPANY_CODE"],
            paymentTerms: entry["PAYMENT_TERMS"],
            vendorStateCodeGst: entry["VENDOR_STATE_CODE_GST"],
            plantStateCodeGst: entry["PLANT_STATE_CODE_GST"],
            vendorGstin: entry["VENDOR_GSTIN"],
            plantGstin: entry["PLANT_GSTIN"],
            hraQty: entry["HRA_QTY"],
            contractorSupervisor: entry["CONTRACTOR_SUPERVISOR"],
            eInvoiceEligibility: entry["E_INVOICE_ELIGIBILITY"],
          };
 
          if (existingEntry) {
            existingEntry.serviceDetails.push(serviceDetail);
          } else {
            acc.push({
              workOrderNumber: JSON.stringify(entry["WORKORDER_NUM"]),
              companyCode: entry["COMPANY_CODE"],
              unitName: entry["UNIT_CODE"],
              unitCode: entry["UNIT_CODE"],
              deptCode: entry["DEPT_CODE"],
              sectionCode: entry["SECTION_CODE"],
              vendorCode: JSON.stringify(entry["VENDOR_CODE"]),
              contractorSupervisor: entry["CONTRACTOR_SUPERVISOR"],
              contractorName: entry["VENDOR_NAME"],
              validFrom: entry["WORKORDER_VALID_FROM"]
                ? formatDate(excelDateToDate(entry["WORKORDER_VALID_FROM"]))
                : "",
              validTo: entry["WORKORDER_VALID_TO"]
                ? formatDate(excelDateToDate(entry["WORKORDER_VALID_TO"]))
                : "",
              workOrderType: entry["SAP_WORKORDER_TYPE"],
              departmentName: entry["DEPT_NAME"],
              gLCode: entry["GL_CODE"] ? String(entry["GL_CODE"]) : "",
              jobDescription: entry["JOB_NAME"],
              workOrderOwner: entry["WORKORDER_OWNER"],
              costCentre: entry["COST_CENTER_CODE"],
              sectionHead: entry["SECTION_HEAD"],
              status: entry["STATUS"],
              costCentre: entry["COST_CENTRE_CODE"],
              serviceDetails: [serviceDetail],
            });
          }
 
          return acc;
        }, []);
 
        console.log("Formatted Sheet Data", sheetData, payload);
 
        // Upload the parsed data to the server
        const response = await uploadWorkOrderDetailsData(payload);
        console.log("Backend response:", response);
        // setTableData(response); // Update table data with the response from the server
        // setFilteredData(response); // Update filtered data as well
        message.success("File uploaded successfully!");
        window.location.reload();
        onUploadSuccess(response); // Update table data with the response from the server
        onClose(); // Close the modal
        // Handle the response as needed
      } catch (error) {
        console.error("Error uploading file:", error);
        message.error("Failed to upload file.");
        // Handle the error
      }
    }
  };
 
  const textStyle = {
    font: 'inter',
    fontSize:'16px',
    fontWeight: '400'
  }
 
  return (
    <Modal
      title={
        <Title level={3} style={{ textAlign: "center" }}>
          Minimum Wage details
        </Title>
      }
      visible={visible}
      onCancel={onClose}
      width={596}
      centered
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={(e) => handleSubmit(e)}
          disabled={!fileList.length}
        >
          Submit
        </Button>,
      ]}
    >
      <div style={{ textAlign: "center" }}>
       
        {/* Top Line with Arrows */}
        <Row justify="center" align="middle" style={{ marginBottom: "20px" }}>
          <Col span={7}>
            <Text style={{ fontSize: '16px', fontWeight: '400' }} strong>
              Download the Excel sheet
            </Text>
          </Col>
          <Col span={1} style={{ textAlign: 'center', padding: '0 15px' }}>
            <Image
              width={40}
              src={arrow} // Adjusted width to ensure proper sizing
              preview={false}
              style={{ transform: 'rotate(0deg)', marginRight: '10px', fontWeight: 'bold'}} // Adjusted margin
            />
          </Col>
          <Col span={5}>
            <Text style={{ fontSize: '16px', fontWeight: '400' }} strong>
              Fill it
            </Text>
          </Col>
          <Col span={1} style={{ textAlign: 'center', padding: '0 15px' }}>
            <Image
              width={40}
              src={arrow}
              preview={false}
              style={{ position: 'absolute', right: '10px', fontWeight: 'bold'}} // Optional styling in case of rotation
            />
          </Col>
          <Col span={7}>
            <Text style={{ fontSize: '16px', fontWeight: '400' }} strong>
              Upload the filled Excel sheet
            </Text>
          </Col>
        </Row>
 
        {/* Main Content */}
        <Row gutter={24} align="middle">
          <Col span={11} style={{ textAlign: "center" }}>
            <Image width={70} src={download} preview={false} />
            <Title style={{ fontSize: '16px', fontWeight: '400' }} level={4}>
              Excel sheet template
            </Title>
            <Button
              onClick={handleDownload}
              style={{ color: "#1677FF", borderColor: "#1677FF", marginTop: "10px" }}
              loading={downloading}
            >
              Download
            </Button>
          </Col>
 
          <Col span={2}>
            <Divider
              type="vertical"
              style={{
                height: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                borderColor: "#000",
              }}
            />
          </Col>
 
          <Col span={11} style={{ textAlign: "center" }}>
            <Image width={70} src={upload} preview={false} />
            <Title style={{ fontSize: '16px', fontWeight: '400' }} level={4}>
              Filled Excel sheet
            </Title>
            <Upload
              fileList={fileList}
              onChange={(info) => {
                const { file } = info;
                handleUpload(file);
              }}
              beforeUpload={() => false}
              multiple={false}
            >
              <Button
                style={{ color: "#1677FF", borderColor: "#1677FF", marginTop: "10px" }}
              >
                Upload
              </Button>
            </Upload>
            {fileList.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                <p>{fileList[0].name} - Uploaded</p>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </Modal>
  );
 
};
 
export default BulkUploadModal;