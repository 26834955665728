import React, { useState, useEffect, useCallback, useContext } from 'react';
import {
  Modal,
  Button,
  Input,
  Select,
  Table,
  Checkbox,
  notification,
} from 'antd';
import { AddPermissions } from '../../services/auth';
import { getDetails } from '../../services/auth';
import { getDetailsDashboard } from '../../services/auth';
// import useAuth from "../hooks/useAuth";
import useAuth from '../../hooks/useAuth';
import { AuthContext } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Switch } from 'antd';

const { Option } = Select;

const MyDropdown = ({
  fromDashBoard = false,
  closeModal = () => {},
  callApi = () => {},
  Trole,
  setDataFetchedParent = () => {},
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState('');
  const [newRole, setNewRole] = useState('');
  const [roles, setRoles] = useState([
    'Admin',
    'Supervisor',
    'Approver',
    'Shop',
    'IR',
    'Department'
  ]); // Initial dropdown options
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [apiData, setApiData] = useState({});
  const [selectedRoleType, setSelectedRoleType] = useState('');
  const [menu, setMenu] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [level, setLevel] = useState(null);
  const fetchRole = Trole;
  //
  const navigate = useNavigate();

  const { userConfig } = useAuth();
  //
  const orgId = userConfig.orgId;

  const { setCustomDropdownValues } = useContext(AuthContext);

  const token = sessionStorage.getItem('token');
  useEffect(() => {
    if (token) {
      // Decode the token
      //
      const decodedToken = jwtDecode(token);

      // Access the level from the decoded token
      const level = decodedToken.level;

      // Set the user level state
      setLevel(level);
    } else {
      //
    }
  }, []);

  const Fdata = [
    {
      role: 'Admin',
      data: [
        {
          key: '1',
          api: 'Contractors',
          role: 'Contractors',
        },
        {
          key: '2',
          api: 'Org_Setup',
          role: 'Org Setup',
        },
        // {
        //   key: '3',
        //   api: 'Compliance',
        //   role: 'Compliance',
        // },
        {
          key: '4',
          api: 'Master_Setup',
          role: 'Master Setup',
        },
        {
          key: '5',
          api: 'Audit',
          role: 'Audit',
        },
        {
          key: '6',
          api: 'User_Management',
          role: 'User Management',
        },
      ],
    },
    {
      role: 'Supervisor',
      data: [
        {
          key: '1',
          api: 'Onboard_Workmen',
          role: 'Onboard Workmen',
        },
        {
          key: '2',
          api: 'Workmen_Dashboard',
          role: 'Workmen Dashboard',
        },
        // {
        //   key: '3',
        //   api: 'Quick_Employee_Onboarding',
        //   role: 'Quick Employee Onboarding',
        // },
        {
          key: '4',
          api: 'Bulk_Upload',
          role: 'Bulk Upload',
        },
        // {
        //   key: '5',
        //   api: 'Incident_Report',
        //   role: 'Incident Report',
        // },
      ],
    },
    {
      role: 'Approver',
      data: [
        {
          key: '1',
          api: 'Workmen_Approval',
          role: 'Workmen Approval',
        },
        {
          key: '2',
          api: 'Report_Incident',
          role: 'Report Incident',
        },
        {
          key: '3',
          api: 'Contractor_Approval',
          role: 'Contractor Approval',
        },
        {
          key: '4',
          api: 'Historical_Records',
          role: 'Historical Records',
        },
        {
          key: '5',
          api: 'Reports_For_Contractor_Workmen',
          role: 'Reports For Contractor Workmen',
        },
      ],
    },
    {
      role: 'Shop',
      data: [
        {
          key: '1',
          api: 'Historical_Records',
          role: 'Historical Records',
        },
      ],
    },
    {
      role: 'Department',
      data: [
        {
          key: '1',
          api: 'Work_Order_List',
          role: 'Work Order List',
        },
        {
          key: '2',
          api: 'LR_List',
          role: 'LR List',
        },
      ],
    },
    {
      role: 'IR',
      data: [
        {
          key: '1',
          api: 'Onboard_Regular_Workmen',
          role: 'Onboard Regular Workmen',
        },
        {
          key: '2',
          api: 'Regular_Workmen_Dashboard',
          role: 'Regular Workmen Dashboard',
        },
        {
          key: '3',
          api: 'Bulk_Upload',
          role: 'Bulk Upload',
        },
        // {
        //   key: '4',
        //   api: 'Contractor_Approval',
        //   role: 'Contractor Approval',
        // },
        {
          key: '4',
          api: 'Historical_Data',
          role: 'Historical Data',
        },
        {
          key: '5',
          api: 'Reports_For_Regular_Workmen',
          role: 'Reports For Regular Workmen',
        },
      ],
    },
  ];

  const adminFeatues = [
    'Contractors',
    'Org_Setup',
    //'Compliance',
    'Master_Setup',
    'Audit',
    'User_Management',
  ];
  const supervisorFeatures = [
    'Onboard_Workmen',
    'Workmen_Dashboard',
    //'Quick_Employee_Onboarding',
    'Bulk_Upload',
    //'Incident_Report',
  ];
  const approverFeatures = [
    'Workmen_Approval',
    'Report_Incident',
    'Contractor_Approval',
    'Historical_Records',
    'Reports_For_Contractor_Workmen',
  ];
  // const approverFeatures1 = [
  //   'Workmen_Approval',
  //   'Report_Incident',
  //   'Historical_Records',
  // ];
  const shopFeatures = ['Historical_Records'];
  const sectionHeadFeatures = ['Work_Order-List', 'LR_List'];
  const departmentheadFeatures = [
    'Work_Order-List',
    'LR_List'
  ]
  const irFeatures = [
    'Onboard_Regular_Workmen',
    'Regular Workmen Dashboard',
    'Bulk_Upload',
    //'Contractor_Approval',
    'Historical_Data',
    'Reports_For_Regular_Workmen',
  ];

  useEffect(() => {
    if (fromDashBoard && !dataFetched) {
      fetchData();
    }
  }, [fromDashBoard, dataFetched, fetchRole]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = async () => {
    getDetailsDashboard(fetchRole)
      .then((response) => {
        // Filter data based on specific roles
        //
        // const filteredData = response.data.filter(item => {
        //   return  item.fetchRole === fetchRole;
        // });

        // setApiData(filteredData);
        const { selectedRole, selectedRoleType, permissions } =
          response.data.data;
        //
        //
        // Set the selected role, role type, and permissions
        setSelectedRole(selectedRole);
        setSelectedRoleType(selectedRoleType);

        // Initialize selectedFeatures based on the fetched permissions
        const initialSelectedFeatures = {};
        if (permissions && typeof permissions === 'object') {
          for (const [feature, values] of Object.entries(permissions)) {
            initialSelectedFeatures[feature] = { ...values };
          }
        }
        //
        setSelectedFeatures(initialSelectedFeatures);

        setMenu(Fdata.find((item) => item.role === selectedRole)?.data || []);

        setDataFetched(true);
        // setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  function getKeysArray() {
    if (selectedRole === 'Admin') {
      return adminFeatues;
    } else if (selectedRole === 'Supervisor') {
      return supervisorFeatures;
    } else if (selectedRole === 'Approver') {
      return approverFeatures;

      // else{
      //   return approverFeatures1;
      // }
    } else if (selectedRole === 'Section Head') {
      return sectionHeadFeatures;
    } else if (selectedRole === 'Department Head') {
      return departmentheadFeatures;
    } else if (selectedRole === 'Shop') {
      return shopFeatures;
    } else {
      return irFeatures;
    }
  }

  function addMissingSubKeys(obj) {
    const defaultValues = {
      access: false,
      create: false,
      edit: false,
      import: false,
      export: true,
      view: true,
    };

    const requiredKeys = getKeysArray();

    // Add missing keys
    for (const requiredKey of requiredKeys) {
      if (!obj.hasOwnProperty(requiredKey)) {
        obj[requiredKey] = { ...defaultValues };
      }
    }

    // Add missing sub-keys
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        for (const subKey of Object.keys(defaultValues)) {
          if (!obj[key][subKey]) {
            obj[key][subKey] = defaultValues[subKey];
          }
        }
      }
    }

    return obj;
  }

  const onFinish = async () => {
    // values contains the form field values
    //

    const edittedFeatures = addMissingSubKeys(selectedFeatures);
    const values = { orgId, selectedRole, edittedFeatures, selectedRoleType };
    //

    setCustomDropdownValues(values);

    AddPermissions(values)
      .then((res) => {
        //
        notification.success({ message: res.data.message });
        setSelectedFeatures({});
        setSelectedRole('');
        setSelectedRoleType('');
        setDataFetchedParent(true);
      })
      .catch((err) => {
        notification.error({ message: err.response.data.message });
      });
    navigate('/home/admin/permission');
  };

  const columns = [
    {
      title: 'Features',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Access',
      dataIndex: 'access',
      key: 'access',
      render: (text, record) => (
        // <Checkbox
        //   checked={selectedFeatures[record.api]?.access}
        //   onChange={(e) =>
        //     handleCheckboxChange(record.api, 'access', e.target.checked)
        //   }
        // />
        <Switch
          checked={selectedFeatures[record.api]?.access}
          onChange={(checked) =>
            handleCheckboxChange(record.api, 'access', checked)
          }
        />
      ),
    },
    {
      title: 'Create',
      dataIndex: 'create',
      key: 'create',
      render: (text, record) => (
        <Checkbox
          checked={selectedFeatures[record.api]?.create}
          disabled={
            record.role === 'Audit' ||
            (selectedFeatures[record.api]?.access
              ? !selectedFeatures[record.api].access
              : true)
          }
          onChange={(e) =>
            handleCheckboxChange(record.api, 'create', e.target.checked)
          }
        />
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Checkbox
          checked={selectedFeatures[record.api]?.edit}
          disabled={
            record.role === 'Audit' ||
            (selectedFeatures[record.api]?.access
              ? !selectedFeatures[record.api].access
              : true)
          }
          onChange={(e) =>
            handleCheckboxChange(record.api, 'edit', e.target.checked)
          }
        />
      ),
    },
    {
      title: 'Import',
      dataIndex: 'import',
      key: 'import',
      render: (text, record) => {
        const allowedRoles = [
          'Master_Setup',
          'Bulk_Upload',
          'Historical_Reports',
          'Historical_Reports',
        ];
        const isAllowedRole = allowedRoles.includes(record.api);
        const isAccessChecked = selectedFeatures[record.api]?.access;
        //

        return (
          <Checkbox
            checked={selectedFeatures[record.api]?.import}
            disabled={!(isAllowedRole && isAccessChecked)}
            onChange={(e) =>
              handleCheckboxChange(record.api, 'import', e.target.checked)
            }
          />
        );
      },
    },
    {
      title: 'Export',
      dataIndex: 'export',
      key: 'export',
      render: (text, record) => (
        <Checkbox
          defaultChecked
          disabled={
            selectedFeatures[record.api]?.access
              ? !selectedFeatures[record.api].access
              : true
          }
          //checked={selectedFeatures[record.role]?.export}
          // onChange={(e) => handleCheckboxChange(record.role, 'export', e.target.checked)}
        />
      ),
    },
    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',

      render: (text, record) => (
        <Checkbox
          defaultChecked
          disabled={
            selectedFeatures[record.api]?.access
              ? !selectedFeatures[record.api].access
              : true
          }
          // checked={selectedFeatures[record.role]?.view}
          // onChange={(e) => handleCheckboxChange(record.role, 'view', e.target.checked)}
        />
      ),
    },
  ];

  //   const handleCheckboxChange = (role, column, checked) => {

  //     setSelectedFeatures((prevSelectedRoles) => ({
  //       ...prevSelectedRoles,
  //       [role]: {
  //         ...prevSelectedRoles[role],
  //         [column]: checked,
  //       }
  //     }
  //     )
  //     )
  //  };

  const handleCheckboxChange = useCallback(
    (role, column, checked) => {
      setSelectedFeatures((prevSelectedRoles) => ({
        ...prevSelectedRoles,
        [role]: {
          ...prevSelectedRoles[role],
          [column]: checked,
        },
      }));
    },
    [setSelectedFeatures]
  );

  // const handleLogSelectedFeatures = () => {
  //
  // };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    // Check if the required fields are empty
    if (!selectedRole || !selectedRoleType) {
      // If any required field is empty, display an error message
      notification.error({ message: 'Please fill out all required fields.' });
      return;
    }
    // console.log(
    //   'Selected Roles and Permissions:',
    //   selectedRole,
    //   selectedRoleType,
    //   selectedFeatures
    // );
    //
    onFinish();
    callApi();
    // onFinishRole();
    setVisible(false);
    window.location.reload();
  };

  const handleCancel = () => {
    if (fromDashBoard) {
      closeModal();
    } else {
      setVisible(false);
    }
  };

  const handleRoleChange = (value) => {
    setSelectedRole(value);
    const features = Fdata.filter((item) => {
      return item.role == value;
    });
    //
    setMenu(features[0].data);
    setSelectedFeatures({});
  };

  const handleNewRoleChange = (e) => {
    setNewRole(e.target.value);
  };

  const handleAddRole = () => {
    if (newRole && !roles.includes(newRole)) {
      setRoles([...roles, newRole]);
      setSelectedRole(newRole);
      setNewRole('');
    }
  };
  //

  const handleNewRoleTypeChange = (e) => {
    setSelectedRoleType(e.target.value);
    //
  };

  //
  //
  //
  //
  return (
    <div>
      {!fromDashBoard && (
        <Button type='primary' onClick={showModal}>
          Permissions
        </Button>
      )}
      <Modal
        title='Add Permissions'
        visible={visible || fromDashBoard}
        onOk={handleOk}
        onCancel={handleCancel}
        width='1000px'>
        <div>
          <label htmlFor='dropdown' required>
            {
              <span>
                Select Role<span style={{ color: 'red' }}>*</span>
              </span>
            }
          </label>
          <Select
            id='dropdown'
            style={{ width: '100%', marginBottom: '16px' }}
            onChange={handleRoleChange}
            value={selectedRole}
            required

            // aria-required="true"
          >
            {roles.map((role) => (
              <Option key={role} value={role}>
                {role}
              </Option>
            ))}
          </Select>
        </div>

        <div>
          <label htmlFor='role' required>
            {
              <span>
                Role Name<span style={{ color: 'red' }}>*</span>
              </span>
            }
          </label>
          <Input
            id='role'
            // rules={[{ required: true, message: 'Enter role' }]}
            value={selectedRoleType}
            onChange={handleNewRoleTypeChange}
            style={{ marginBottom: '16px' }}
            required
            // aria-required="true"
          />
        </div>
        {/* <div>
          <label htmlFor="newRole">Add New Role:</label>
          <Input
            id="newRole"
            value={newRole}
            onChange={handleNewRoleChange}
            style={{ marginBottom: '16px' }}
          />
        </div>
        <Button type="primary" onClick={handleAddRole}>
          Add New Role
        </Button> */}

        <Table columns={columns} dataSource={menu} pagination={false} />
        {/* <button onClick={handleLogSelectedFeatures}>Log Selected Roles</button> */}
      </Modal>
    </div>
  );
};

export default MyDropdown;
