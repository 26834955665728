// import Axios from './axios';
import axios from '../axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */

// export const uploadMinimumData = async (payload, orgId) => {
//     try {
//         const response = await axios.post(`/minimum-wage`, payload, {params: {orgId}});
//         console.log("response", response);
//         // Check if response is defined and has data property before accessing it
//         if (response && response.data) {
//             return response.data; // Assuming your data is in response.data
//         } else {
//             throw new Error("Invalid response received from server");
//         }
//     } catch (error) {
//         // Handle errors appropriately
//         throw new Error(`Error uploading work order details data: ${error.message}`);
//     }
// };

export const uploadMinimumData = async (payload, orgId) => {
    try {
        // const response = await axios.post(`/minimum-wage`, payload, { params: { orgId } });
        const response = await axios.post(`/minimum-wage`, payload);
        console.log("response", response);
        if (response && response.data) {
            return response.data;
        } else {
            throw new Error("Invalid response received from server");
        }
    } catch (error) {
        throw new Error(`Error uploading work order details data: ${error.message}`);
    }
};

export const getMinimumWageData = () => {
    
    return axios.get('/minimum-wage');
    // return Axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};