import { createWorkOrderDetailData, getWorkOrderDetailData, updateWorkOrderDetailDataById, deleteWorkOrderDetailData, fetchWorkOrderDetailData, updateWorkOrderDetailDataByWorkOrderNumber, uploadWorkOrderDetailData, getExcelTemplateData, getDownloadExcel, getSearch } from '../../api/workOrderDetails';
// import { storeDispatcher } from '../../layout/store';
// import { SET_PERSON_DATA } from '../actionType';

export const createWorkOrderDetailsData = async (data) => {
    console.log("data", data)
  const response = await createWorkOrderDetailData(data);
  console.log('value of post data', response);
  //   getWageHistory();
};

export const getWorkOrderDetailsData = async () => {
  //Get Data
  try{
    const workOrderData = await getWorkOrderDetailData();
    console.log("workOrderData", workOrderData);
    console.log('person is', workOrderData?.data);

    console.log('Get work Order Data is', workOrderData);
//     storeDispatcher.dispatch({
//     type: SET_PERSON_DATA,
//     payload: workOrderData?.data || []
//   });
  console.log("workOrderData?.data", workOrderData?.data);
  return workOrderData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const updateWorkOrderDetailsData = async (data) => {
    const response = await updateWorkOrderDetailDataById(data);
    console.log('value of post data', response);
    //   getWageHistory();
};

export const deleteWorkOrderDetailsData = async (data) => {
    const response = await deleteWorkOrderDetailData(data);
    const responseData = await response.json();
    console.log('value of post data', response);
    //   getWageHistory();
};

export const fetchWorkOrderDetailsData = async(data)=>{
  console.log("data", data);
    try {
        console.log("data", data);
        // Call the function to fetch work order details data
        const response = await fetchWorkOrderDetailData(data);
        console.log("response", response)
        // Directly return the response data
        return response.data;
      } catch (error) {
        throw new Error('Error fetching work order details data: ' + error.message);
      }
}

export const updateWorkOrderDetailsDataByWorkOrderNumber = async(workOrderNumber, data)=>{
    try {
        if (!data) {
            throw new Error('Data parameter is undefined');
        }
        console.log("data", data);
        // Call the function to fetch work order details data
        const response = await updateWorkOrderDetailDataByWorkOrderNumber(workOrderNumber, data);
        console.log("response", response)
        // Directly return the response data
        return response;
      } catch (error) {
        // throw new Error('Error fetching work order details data: ' + error.message);
        console.log("error", error)
      }
}

export const uploadWorkOrderDetailsData = async (data) => {
    console.log("data", data);
    const response = await uploadWorkOrderDetailData(data);
    console.log('value of post data', response);
    return response; // Return response if needed
};

export const getExcelTemplateDetailsData = async () => {
  //Get Data
  try{
      return await getExcelTemplateData();
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};
// getDownloadExcel
export const getDownloadExcelFile = async () => {
  //Get Data
  try{
      return await getDownloadExcel();
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};

export const getSearchData = async () => {
  //Get Data
  try{
      return await getSearch();
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};
