import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Typography,
  Space,
  Tooltip,
  notification,
  Table,
  Upload,
  Modal,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
// import qs from 'qs';

import useAuth from '../../hooks/useAuth';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  getWorkmenLimit,
  jobOrderCount,
  getSportsGroup,
  getPayRuleData
} from '../../services/auth';
import axios1 from '../../services/axiosInstance';
import axios from 'axios';
import {getLaborRequisitionDetailsData} from "../../services/actions/api/laborRequisitionsDetails"

const { Option } = Select;
const { Title } = Typography;

const typeCategoryMapping = {
  Welder: 'Direct',
  Fitter: 'Direct',
  Grinder: 'Direct',
  'Gas Cutter': 'Direct',
  Rigger: 'Indirect',
  Helper: 'Indirect',
  'Crane Operator': 'Direct',
  'MHE Crane Operator': 'Direct',
  'Tube Technician': 'Direct',
  Multiskill: 'Direct',
  Supervisor: 'Staff',
  'Safety Supervisor': 'Staff',
  'Site In-charge': 'Staff',
  'Store Keeper': 'Indirect',
  'Machine Operator': 'Indirect',
  'M/c Operator': 'Indirect',
  'Office Staff': 'Staff',
  Owner: 'Staff',
  Electrician: 'Direct',
};

const typeMedicalMapping = {
  Welder: 'No',
  Fitter: 'No',
  Grinder: 'No',
  'Gas Cutter': 'No',
  Rigger: 'No',
  Helper: 'No',
  'Crane Operator': 'Yes',
  'MHE Crane Operator': 'Yes',
  'Tube Technician': 'No',
  Multiskill: 'Yes',
  Supervisor: 'No',
  'Safety Supervisor': 'No',
  'Site In-charge': 'No',
  'Store Keeper': 'No',
  'Machine Operator': 'No',
  'Office Staff': 'No',
  Owner: 'No',
  Electrician: 'No',
};

const Jobdetails = ({
  initialValues,
  onNext,
  onPrev,
  isViewOnly,
  masterData,
  onUpdateFields,
}) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* eslint-disable */
  const [data, setData] = React.useState(initialValues);

  const [Isworking, setIsworking] = React.useState(true);
  const [Empstatus, setEmpstatus] = React.useState(true);
  const [EsicDisabled, setEsicDisabled] = React.useState(true);
  const [isDlDate, setIsDlDate] = React.useState(false);
  const [workingArea, setWorkingArea] = React.useState([]);
  const [masterDataShop, setMasterDataShop] = React.useState([]);
  const [isCommonService, setIsCommonService] = useState(false);
  const [payRules, setPayRules] = useState(null);
  const [selectedPayRule, setSelectedPayRule] = useState("");
  const [error, setError] = useState(null);

  // const isDisabled = data?.approvalStage > 0;
  // const [selectedContractorCode, setSelectedContractorCode] = React.useState(null);

  //

  const [contractorList, setContractorList] = React.useState([]);
  const [jobOrderList, setJobOrderList] = React.useState([]);
  const [selectedContractorCode, setSelectedContractorCode] =
    React.useState(null);
  const [contractorNameList, setContractorNameList] = React.useState([]);
  // const [selectedContractorName, setSelectedContractorCode] = React.useState(null);
  const [selectedJobOrder, setSelectedJobOrder] = React.useState(null);
  // const [selectedJobOrder, setSelectedJobOrder] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(false);
  const [separatedVariables, setSeperatedVariables] = React.useState([]);
  const [contractorName, setContractorName] = React.useState([]);
  const [workerType, setWorkerType] = React.useState([]);
  const [workType, setWorkType] = React.useState([]);
  const [skillType, setSkillType] = React.useState([]);
  const [bloodGroup, setBloodGroup] = React.useState([]);
  const [workerCategory, setWorkerCategory] = React.useState([]);
  const [technicalDetails, setTechnicalDetails] = React.useState([]);
  const [department, setDepartment] = React.useState([]);
  const [checkedList, setCheckedList] = React.useState([]);
  const [primaryJobSkilldata, setPrimaryJobSkill] = useState([]);
  const [lrList, setLrList] = useState([]);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [selectedWorkerType, setSelectedWorkerType] = useState('');

  // const fetchAccessToken = async () => {
  //   try {
  //     const response = await axios.post(
  //       "https://wikiworks-dev.cfn.mykronos.com/api/authentication/access_token",
  //       qs.stringify({
  //         username: "seanivan",
  //         password: "OPKINSiguroainso24q2.",
  //         client_id: "Uiqm4nuRwZPVWmg8TxWgYBBfhcGtpXfz",
  //         client_secret: "jIUNO2u7qdbfPmkq",
  //         grant_type: "password",
  //         auth_chain: "OAuthLdapService",
  //       }),
  //       {
  //         headers: {
  //           accept: "application/json",
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //       // {
  //       //   withCredentials: true,
  //       // }
  //     );
 
  //     // Log the full response to understand its structure
  //     console.log("Access Token Response:", response);
 
  //     if (response && response.data && response.data.access_token) {
  //       return response.data.access_token;
  //     } else {
  //       throw new Error("Access token not found in the response");
  //     }
  //   } catch (err) {
  //     console.error("Error fetching access token:", err.message);
  //     throw new Error("Failed to fetch access token");
  //   }
  // };

  const onChangePayRule = (value) => {
    console.log(`selected ${value}`);
    setSelectedPayRule(value);
  };
  // Dispatch the action to store the selectedPayRule in the store
  //   dispatch({
  //     type: 'SET_SELECTED_PAY_RULE',
  //     payload: value,  // Send selected pay rule to the store
  //   });
  // };
 
  // console.log('setSelectedPayRule ', selectedPayRule)
  const onSearch = (value) => {
    console.log("search:", value);
    setSelectedPayRule(value);
  };
 
  useEffect(() => {
    // const fetchAPIPayRules = async () => {
    //   try {
    // //     const accessToken = await fetchAccessToken();
    // //     console.log("Access Tokennnnnnnnnnnnnnnnnnnnnnnnnnnnnn ", accessToken)
    // //     const payRulesResponse = await axios.get(
    // //       "https://wikiworks-dev.cfn.mykronos.com/api/v2/timekeeping/setup/payrules",
    // //       {
    // //         headers: {
    // //           accept: "application/json",
    // //           Authorization: `Bearer ${accessToken}`,
    // //           // 'Accept-Encoding': 'gzip, deflate, br',
    // //           "Content-Type": "text/html, charset=utf-8",
    // //           // 'Connection': 'keep-alive'
    // //         },
    // //       }
    // //     );
    //     const payRulesResponse = await getPayRuleData()
    //     console.log("payRulesResponse", payRulesResponse);
        
 
    //     setPayRules(payRulesResponse.data);
 
    //     console.log("----", payRules.jobAssignment.jobAssignmentDetails.payRuleName)
    //     const options = payRulesResponse.data.map((rule) => ({
    //       value: rule.name, // Use the 'name' property as the value
    //       label: rule.name, // Use the 'name' property as the label displayed
    //     }));
 
    //     setPayRules(options);
    //   } catch (err) {
    //     setError("Failed to fetch pay rules data");
    //     console.error("Pay Rules Data Error:", err.message);
    //   }
    // };
 
    // fetchAPIPayRules(); // Invoke the async function to fetch data

    const fetchAPIPayRules = async () => {
      try {
        const payRulesResponse = await getPayRuleData();
        console.log("Pay Rules Response:", payRulesResponse);
    
        // Check if response has data before setting state
        if (payRulesResponse && payRulesResponse.data) {
          const options = payRulesResponse.data.map((rule) => ({
            value: rule.name,
            label: rule.name,
          }));
    
          setPayRules(options);
    
          const payRuleName = payRulesResponse.data[0]?.jobAssignment?.jobAssignmentDetails?.payRuleName;
          if (payRuleName) {
            console.log("Pay Rule Name:", payRuleName);
          } else {
            console.warn("payRuleName not found in response data");
          }
        } else {
          throw new Error("No data found in pay rules response");
        }
      } catch (err) {
        // Check if the error has a response
        if (err.response) {
          // Log the error status and data
          console.error("Error Status:", err.response.status);
          console.error("Error Data:", err.response.data);
          setError("Failed to fetch pay rules data");
        } else {
          // Handle case where there is no response
          console.error("Error:", err.message);
          setError("Network error or server not reachable");
        }
      }
    };
    fetchAPIPayRules();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts
 

  useEffect(() => {
    console.log(initialValues, 'initialValues');
    form.setFieldsValue({
      workerCategory: typeCategoryMapping[selectedWorkerType],
    });
  }, [selectedWorkerType]);

  useEffect(() => {
    setSelectedWorkerType(initialValues?.designation);
  }, [initialValues]);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChangeApproval = (value, checked) => {
    let updatedCheckedList;
    if (checked) {
      // If checkbox is checked, add its value to the checkedList array
      updatedCheckedList = [...checkedList, value];
    } else {
      // If checkbox is unchecked, remove its value from the checkedList array
      updatedCheckedList = checkedList.filter((item) => item !== value);
    }
    setCheckedList(updatedCheckedList);
  };

  const { userConfig } = useAuth();
  //
  const supplierId = userConfig?.supplierId;
  const orgId = userConfig?.orgId;
  const isDisabled = userConfig.role === 'approver';
  const isEditingDisabled =
    userConfig.role === 'supervisor' && initialValues?.approvalStage >= 0;

  const onFinish = (values) => {
    values.contractorCode = userConfig?.supplierId;
    values.contractorName = contractorName[0];

    onNext(values);
  };

  // const Esictoggle = () => {
  //   setEsicDisabled(!EsicDisabled);
  // };
  const Esictoggle = (e) => {
    // Check if the input value is empty or not
    const { name, value } = e.target;
    const isInputEmpty = e.target.value.trim() === '';

    // Enable or disable based on whether the input is empty
    setEsicDisabled(isInputEmpty);
    handleFieldChange(name, value);
  };
  const Isworkingtoggle = () => {
    setIsworking(!Isworking);
  };
  const Empstatustoggle = () => {
    setEmpstatus(!Empstatus);
  };

  const onChange = (e) => {
    //
    const { name, value } = e.target;
    if (value?.trim() !== '') {
      handleFieldChange(name, value);
    }
  };

  // const normfile = () => {
  //   //
  // };

  const handleFieldChange = (fieldName, value) => {
    if (fieldName == 'designation') setSelectedWorkerType(value);
    // Notify the parent component about the updated field
    // if (typeof onUpdateFields === 'function') {
    //   onUpdateFields(fieldName, value);
    // } else {
    //   console.error('onUpdateFields is not a function');
    //   return;
    // }
    onUpdateFields?.(fieldName, value);
  };

  React.useEffect(() => {
    // Fetch data when the component mounts
    workmenLimit();
  }, []);

  const workmenLimit = async () => {
    //
    getWorkmenLimit(orgId)
      .then((res) => {
        //

        const contractorIdsAndJobOrderNumbers = res.data.map((item) => {
          if (item && item.addJobDetails && Array.isArray(item.addJobDetails)) {
            return item.addJobDetails.map((details) => ({
              id: details?.id,
              jobOrderNumber: details?.jobordernumber,
              name: item?.name,
              status: details?.status,
            }));
          }
          return null;
        });

        const newArr = res.data.map((item) => {
          const jobOrderNumber = item?.addJobDetails?.map((jobDetail) => {
            return jobDetail?.jobordernumber;
          });
          if (jobOrderNumber) {
            return {
              id: item?.id,
              name: item?.name,
              jobOrderNumber: jobOrderNumber,
              status: item.status,
            };
          }
        });

        //

        setSeperatedVariables(newArr);

        // Flatten the array of objects into an array of { id, jobOrderNumber }
        const flattenedContractorIdsAndJobOrderNumbers =
          contractorIdsAndJobOrderNumbers.flat();
        // Old code
        const filteredContractors =
          flattenedContractorIdsAndJobOrderNumbers.filter(
            (contractor) => contractor.id === supplierId
          );
        //  Viresh Code
        // const filteredContractors =
        //   flattenedContractorIdsAndJobOrderNumbers.filter(
        //     (contractor) =>
        //       contractor.id == supplierId && contractor.status == 'Active'
        //   );

        const jobOrders = filteredContractors?.map(
          (contractor) => contractor.jobOrderNumber
        );
        //
        const name = filteredContractors?.map((contractor) => contractor.name);
        //
        const uniqueNames = [...new Set(name)];
        //
        setContractorName(uniqueNames);
        console.log(uniqueNames, 'cont name');
        setJobOrderList(jobOrders);
      })
      .catch((error) => {
        // setLoader(false);
      });
  };

  
  useEffect(() => {
    if (jobOrderList.length > 0) {
      getLrDataByWorkOrder();
    }
  }, [jobOrderList])

  // LR data By job order number with added logging
  const getLrDataByWorkOrder = async () => {
    try {
      const lrData = await getLaborRequisitionDetailsData();
      console.log("Fetched lrData:", lrData); // Debugging line

      // Filter to get matching LR numbers
      const matchingLrNumbers = lrData
        .filter((item) => jobOrderList.includes(item.workOrderNumber))
        .map((item) => item.lrNumber);
      console.log("Matching LR Numbers:", matchingLrNumbers); // Debugging line

      // Set the state with all matching LR numbers
      setLrList(matchingLrNumbers);
    } catch (error) {
      console.error("Error fetching LR data:", error);
    }
  };
  console.log('contractor Name', contractorName);

  // const handleJobOrderChange = async (value) => {
  //   setSelectedJobOrder(value);
  //   //
  //   const jobId = value;
  //   const contractorCode = userConfig?.supplierId;

  //   jobOrderCount({ jobId, contractorCode })
  //     .then((res) => {
  //

  //       // Make a request to the backend to get information from MongoDB

  //       const { maxContractors, jobCount } = res.data;

  //       // Check if jobCount exceeds the limit
  //       if (jobCount == maxContractors - 1) {
  //         notification.error({
  //           message: 'Limit Reached',
  //           description: 'Adding one more contractor would exceed the limit.',
  //         });

  //         // Set isNextButtonDisabled to true to disable the "Next" button
  //         //  setIsNextButtonDisabled(false);
  //       } else {
  //         // Display a notification if jobCount is greater than maxContractors
  //         if (jobCount > maxContractors - 1) {
  //           notification.info({
  //             message: 'Notice',
  //             description:
  //               'The number of contractors is more than the permitted limit.',
  //           });
  //           // If the limit is not reached, you might reset the state to enable the button
  //           setIsNextButtonDisabled(true);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       // setLoader(false);
  //
  //     });
  // };

  React.useEffect(() => {
    workerTypeInput(),
      workTypeInput(),
      skillTypeInput(),
      qualificationInput(),
      bloodGroupInput(),
      workerCategoryInput(),
      deptCodeTypeInput();
    primaryJobSkill();
  }, [masterData]);

  // function primaryJobSkill() {
  //   if (!Array?.isArray(masterData)) {
  //     console.error('not an array');
  //     return;
  //   }

  //   let sheetName = 'Primary_Job_Skill';
  //   const dataFromArray = masterData?.find(
  //     (item) => item?.sheetName === sheetName
  //   );
  //   //
  //   const primaryJobSkillAreas = dataFromArray?.dataParsed?.map((item) => {
  //     return item?.Contractor_Primary_Job_Skill;
  //   });

  //   setPrimaryJobSkill(primaryJobSkillAreas);
  //   //

  //   if (dataFromArray) {
  //     //
  //   } else {
  //     console.error(
  //       "Object with sheetName '" + sheetName + "' not found in masterData."
  //     );
  //   }
  // }

  function primaryJobSkill() {
    if (!Array.isArray(masterData)) {
      console.error('Not an array');
      return;
    }

    const sheetName = 'Primary_Job_Skill';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );

    if (!dataFromArray) {
      console.error(
        `Object with sheetName '${sheetName}' not found in masterData.`
      );
      return;
    }

    const primaryJobSkillAreas = dataFromArray?.dataParsed
      ?.map((item) => item?.Contractor_Primary_Job_Skill)
      ?.filter((skill) => typeof skill === 'string' && skill.trim().length > 0); // Filter out invalid data

    setPrimaryJobSkill(primaryJobSkillAreas || []);
  }

  function workerTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Worker_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const workerTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.WORKER_TYPE;
    });
    setWorkerType(workerTypeInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function workTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Work_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const workTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Work_Type + ' ' + item?.Discription;
    });
    setWorkType(workTypeInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function skillTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Skill_Type';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const skillTypeInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Skill_Type;
    });
    setSkillType(skillTypeInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function qualificationInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Qualification';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const qualificationInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Qualification;
    });
    setTechnicalDetails(qualificationInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function bloodGroupInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Blood_Group';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const bloodGroupInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Blood_Group;
    });
    setBloodGroup(bloodGroupInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  function workerCategoryInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    let sheetName = 'Worker_Category';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    //
    const workerCategoryInputAreas = dataFromArray?.dataParsed?.map((item) => {
      return item?.Worker_Category;
    });
    setWorkerCategory(workerCategoryInputAreas);
    //

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }
  console.log("masterData", masterData);
  
  function deptCodeTypeInput() {
    if (!Array.isArray(masterData)) {
      console.error('not an array');
      return;
    }

    // let sheetName = 'Contractor_Department_Master';
    // const dataFromArray = masterData?.find(
    //   (item) => item?.sheetName === sheetName
    // );
    // setMasterDataShop(dataFromArray);
    // //
    // const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
    //   return item?.Department;
    // });
    // setDepartment(deptCodeInputAreas);
    //
    let sheetName = 'Latest_Department_master';
    const dataFromArray = masterData?.find(
      (item) => item?.sheetName === sheetName
    );
    setMasterDataShop(dataFromArray);
    // 
    // const deptCodeInputAreas = dataFromArray?.dataParsed?.map((item) => {
    //   return item?.Working_Area;
    // });
    const deptCodeInputAreas = Array.from(
      new Set(dataFromArray?.dataParsed?.map((item) => item?.Working_Area))
    );
    setDepartment(deptCodeInputAreas);

    if (dataFromArray) {
    } else {
      console.error(
        "Object with sheetName '" + sheetName + "' not found in masterData."
      );
    }
  }

  const [form] = Form.useForm();

  // const fieldChanges = (changedFields, allFields) => {
  //
  //   if (changedFields[0].name == 'workingArea') {
  //     const changedValue = changedFields[0]?.value?.split(' ');
  //     //

  //     // let index = masterDataShop?.dataParsed?.find((item) => {
  //     //   return item.Production_Centre == changedValue[0];
  //     // });
  //     //
  //     const filteredDepartments = masterDataShop?.dataParsed?.filter((item) => {
  //       return item.Production_Centre === changedValue[0];
  //     });

  //

  //     if (filteredDepartments.length > 0) {
  //       // Extract department codes from the filtered data
  //       const departmentCodes = filteredDepartments.map((dept) => {
  //         return `${dept.Dept_Code} ${dept.Department_Name}`;
  //       });

  //       // Update the department code options
  //       setWorkingArea(departmentCodes);

  //       // Optionally, set the default value of department code
  //       form.setFieldsValue({
  //         departmentCode: departmentCodes[0], // Set the first department code as default
  //       });
  //     }
  //   }
  // };
  console.log("masterDataShop", masterDataShop);
  
  // const fieldChanges = (changedFields, allFields) => {
    
  //   if (changedFields[0].name == 'workingArea') {
  //     const changedValue = changedFields[0]?.value?.split(' ');

  //     let index = masterDataShop?.dataParsed?.find((item) => {
  //       return item.Department == changedValue[0];
  //     });

  //     if (index) {
  //       form.setFieldsValue({
  //         departmentCode: index?.Department_code,
  //       });
  //     } else {
  //       form.setFieldsValue({
  //         departmentCode: 'Null',
  //       });
  //     }
  //   }
  // };

  const fieldChanges = (changedFields, allFields) => {
    console.log("changedFields", changedFields[0]);
    console.log("changedFields[0].name[0] == 'workingArea'", changedFields[0].name[0] == 'workingArea');
    
    if (changedFields[0].name[0] == 'workingArea') {
      console.log("testedData");
      const changedValue = changedFields[0]?.value
      console.log("testedData", changedValue);

      // Find all matching records for the selected workingArea.
      const matchingRecords = masterDataShop?.dataParsed?.filter((item) => {
        return item.Working_Area === changedValue;
      }) || [];

      // let index = []
      // index = masterDataShop?.dataParsed?.find((item) => {
      //   console.log("item", item);
      //   return item.Working_Area == changedValue;
      // });

      // console.log("index", index);
      console.log("matchingRecords", matchingRecords);

      // Extract all Dept_Code values from the matching records.
      const allDeptCodes = matchingRecords.map((record) => record.Dept_Code);
      console.log("allDeptCodes", allDeptCodes);
      setWorkingArea(allDeptCodes)
      

      // Join the Dept_Code values into a single string or display them as needed.
      const deptCodesString = allDeptCodes.join(", ");

      console.log("deptCodesString", deptCodesString);

      if (allDeptCodes.length > 0) {
        form.setFieldsValue({
          departmentCode: allDeptCodes,
        });
      } else {
        form.setFieldsValue({
          departmentCode: 'Null',
        });
      }
    }
  };
  
  const uniqueDepartments = new Set(department);

  console.log("department", department);
  

  return (
    <>
      <Title level={5}>Job Details</Title>
      <Form
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}
        onFieldsChange={fieldChanges}
        form={form}>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='employerCode' label='Principal Employer Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig.orgId}
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='contractorCode' label='Contractor Code'>
              <Input
                size='large'
                disabled='true'
                defaultValue={userConfig?.supplierId}
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item label='Contractor Name'>
              <Input
                size='large'
                disabled='true'
                value={contractorName[0] || initialValues?.contractorName}
              />
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='contractorName'
              label='Contractor Name'
              rules={[{ required: true, message: 'Enter  Contact Name' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                value={contractorName}
                onChange={(value) =>
                  onChange({ target: { name: 'contractorName', value } })
                }>
                {contractorName?.map((code) => (
                  <Select.Option key={code} value={code}>
                    {code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='workingArea'
              label='Working Area'
              rules={[{ required: true, message: 'Enter Working Area' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'workingArea', value } })
                }>
                {department?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='departmentCode'
              label='Department Code'
              rules={[
                // {
                //   required: true,
                //   message: 'Enter Department Code',
                // },
              ]}>
              <Select size='large' 
              disabled={isDisabled}
              >
                {workingArea?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='workorderCode' label='Work Order Number'>
              <Select
                size='large'
                placeholder='Work Order Number'
                disabled={isDisabled}>
                <Option value='JM-LMG-IT-47839'>JM-LMG-IT-47839</Option>
                <Option value='FT-DRS-OU-97563'>FT-DRS-OU-97563</Option>
                <Option value='AJ-MXV-HG-42371A'>AJ-MXV-HG-42371A</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='itemServiceCode' label='Item Service Code'>
              <Select
                size='large'
                placeholder='Item Service Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'itemServiceCode', value } })
                }>
                <Option value=''></Option>
                <Option value='975632'>975632</Option>
                <Option value='991106'>991106</Option>
                <Option value='850219'>850219</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='labourType' label='Labour Code'>
              <Select
                size='large'
                placeholder='Labour Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'labourType', value } })
                }>
                <Option value=''></Option>
                <Option value='Monthly'>Monthly</Option>
                <Option value='Daily'>Daily</Option>
                <Option value='Weekly'>Weekly</Option>{' '}
              </Select>
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            {/* <Form.Item name='jobOrderNumber' label='Job Order Number'>
              <Select size='large' placeholder='Job Code' disabled={isDisabled}>
                <Option value=''></Option>
                <Option value='WSC-923'>WSC-923</Option>
                <Option value='ITS-321'>ITS-321</Option>
                <Option value='FIN-003'>FIN-003</Option>
                <Option value='ADM-411'>ADM-411</Option>{' '}
              </Select>
            </Form.Item> */}
            <Form.Item
              name='jobOrderNumber'
              label='Job Order Number'
              rules={[{ required: true, message: 'Enter Job Order Number' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                //onChange={(value) => handleJobOrderChange(value)}
              >
                {jobOrderList.map((number) => (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='lrListWorkorderNumber'
              label='LR List'
              rules={[{ required: true, message: 'Enter Job Order Number' }]}>
              <Select
                size='large'
                disabled={isDisabled}
                //onChange={(value) => handleJobOrderChange(value)}
              >
                {/* {jobOrderList.map((number) => (
                  <Select.Option key={number} value={number}>
                    {number}
                  </Select.Option>
                ))} */}
                  {/* <Select.Option key={lrList} value={lrList}>
                    {lrList}
                  </Select.Option>

              </Select>
            </Form.Item>
          </Col> */} 
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='lrNumberWorkorderNumber'
              label='LR Number'
              rules={[{ required: true, message: 'Enter Job Order Number' }]}
            >
              <Select
                size='large'
                disabled={isDisabled}
              >
                {lrList && lrList.length > 0 ? (
                  lrList.map((number) => (
                    <Select.Option key={number} value={number}>
                      {number}
                    </Select.Option>
                  ))
                ) : (
                  <Select.Option disabled key="no-data" value="">
                    No data available
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='skillCode' label='Skill Code'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'skillCode', value } })
                }>
                {skillType?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='subSkillCode' label='Sub Skill Code'>
              <Select
                size='large'
                placeholder='Sub Skill Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'subSkillCode', value } })
                }>
                <Option value=''></Option>

                <Option value='Skilled'>Skilled</Option>

                <Option value='Unskilled'>Unskilled</Option>
                <Option value='Semi-skilled'>Semi-Skilled</Option>
                <Option value='Highly-skilled'>Highly-Skilled</Option>
              </Select>
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='subSkillDate' label='SubSkill Date'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
              />
            </Form.Item>
          </Col> */}

          <Col className='gutter-row' span={6}>
            <Form.Item name='designation' label='Worker Type'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'designation', value } })
                }>
                {workerType?.map((area, index) => (
                  <Option
                    key={index}
                    value={area}
                    onChange={onChange}
                    name='designation'>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            hidden={selectedWorkerType != 'Electrician'}
            className='gutter-row'
            span={6}>
            <Form.Item
              label='Attachment'
              name='attachment'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              rules={[
                {
                  required: selectedWorkerType == 'Electrician',
                  message: 'Upload  Attachment',
                },
              ]}>
              <Upload
                // type="file"
                listType='picture'
                accept='image/*'
                onPreview={handlePreview}
                maxCount={1}
                beforeUpload={() => false}
                size='large'
                style={{ width: '100%' }}
                // disabled={PfDisabled || isDisabled}
                onChange={onchange}>
                <Button
                  icon={<UploadOutlined />}
                  size='large'
                  style={{ width: '100%' }}
                  onChange={onchange}>
                  Upload Image
                </Button>
              </Upload>
            </Form.Item>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={() => setPreviewOpen(false)}>
              <img
                alt='example'
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='workType' label='Work Type'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'workType', value } })
                }>
                {workType?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='duration' label='Duration'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='duration'
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='workerCategory' label='Worker Category'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'workerCategory', value } })
                }>
                {workerCategory?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='contractorReferenceNumber'
              label={
                <Space>
                  Contractor Reference Number
                  <span></span>
                  <Tooltip
                    title='Generated by Contractor Organisation'
                    placement='top'>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Space>
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='contractorReferenceNumber'></Input>
            </Form.Item>
          </Col> */}
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='pmeRequired'>
              <Checkbox
                size='large'
                name='pmeRequired'
                // onChange={PmeCheckbox}
                onClick={Isworkingtoggle}
                style={{ paddingTop: '40px' }}
                disabled={isDisabled}>
                PME Required?
              </Checkbox>
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='pmeDonedate' label='PME Done Date '>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                disabled={Isworking}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='pmeValidityDate' label='PME Validity Date'>
              <DatePicker
                size='large'
                style={{ width: '100%' }}
                disabled={Isworking}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='doj'
              label='Date Of Joining'
              rules={[{ required: true, message: 'Enter DOJ' }]}
              hasFeedback
              // valuePropName="date"
              // getValueFromEvent={normfile}
            >
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
                inputReadOnly={true} // Disable manual input
                onChange={(e) => handleFieldChange('doj', e)}
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='primaryJobSkill' label='Primary Job Skill'>
              <Select size='large' disabled={isDisabled}>
                {primaryJobSkilldata?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={6}>
            <Form.Item name="payrule" label="Pay Rule">
              <Select
                size='large'
                disabled={isDisabled}
                showSearch
                placeholder="Select a pay rule"
                optionFilterProp="label"
                onChange={onChangePayRule}
                onSearch={onSearch}
                options={payRules} // Use the mapped options here
              />
            </Form.Item>
          </Col>

          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='employmentStatus'>
              <Checkbox
                size='large'
                onChange={onChange}
                onClick={Empstatustoggle}
                style={{ paddingTop: '40px' }}
                disabled={isDisabled}>
                Termination Status
              </Checkbox>
            </Form.Item> */}
          {/* <Form.Item name='sectionCode' label='Section Code'>
              <Select
                size='large'
                placeholder='Section Code'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'sectionCode', value } })
                }>
                <Option value=''></Option>
                <Option value='HR001'>HR001</Option>
                <Option value='FIN001'>FIN001</Option>
                <Option value='ACC001'>ACC001</Option>
                <Option value='LEG001X'>LEG001X</Option>
                <Option value='MFG001'>MFG001</Option>
                <Option value='DEL001'>DEL001</Option>
                <Option value='OPS001'>OPS001</Option>
                <Option value='STR001'>STR001</Option>
                <Option value='ADM001'>ADM001</Option>
                <Option value='MED001'>MED001</Option>
                <Option value='SEC001'>SEC001</Option>
                <Option value='IT001'>IT001</Option>
                <Option value='PRC001'>PRC001</Option>{' '}
              </Select>
            </Form.Item> */}
          {/* </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='dateOfTermination' label='Date Of Termination'>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                placeholder='DD-MM-YYYY'
                disabled={Empstatus}
                format='DD-MM-YYYY'
              />
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='terminationReason' label='Reason For Termination'>
              <Input
                size='large'
                disabled={Empstatus}
                onChange={onChange}
                name='terminationReason'
              />
            </Form.Item>{' '}
          </Col> */}
        </Row>
        {/*<hr />}
        {/* <Title level={5}>Work Experience</Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item name='totalExperience' label='Total Experience'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='totalExperience'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='previousOrg'
              label='Previous Organization'
              rules={[
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 30,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='previousOrg'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='referredBy'
              label='Refered By'
              rules={[
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 30,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='referredBy'
              />
            </Form.Item>{' '}
          </Col>
        </Row> */}

        <hr />
        <Title level={5}>Bank Details</Title>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='bankname'
              label='Bank Name'
              rules={[
                { required: true, message: 'Enter Bank Name' },

                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 300,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='bankname'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='AccountNo'
              label='Bank Account Number'
              rules={[
                { required: true, message: 'Enter Bank Account Number' },
                // {
                //   min: 1,
                //   max: 20,
                //   message: "Account number should be less than 20 characters",
                // },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='AccountNo'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='ifsc'
              label='IFSC Code'
              rules={[
                // { required: true, message: 'Enter IFSC Code' },
                {
                  pattern: /^[A-Za-z]{4}\d{7}$/,
                  message: 'Enter Valid IFSC Code',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='ifsc'
              />
            </Form.Item>{' '}
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='userName'
              label='Name As Per Bank Account'
              rules={[
                // { required: true, message: "Enter Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='userName'
              />
            </Form.Item>{' '}
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='nomineeBankName'
              label='Nominee Bank Name'
              rules={[
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
                // { required: true, message: "Enter Address" },
                {
                  min: 3,
                  message: 'Minimum 3 Characters',
                },
                {
                  max: 300,
                  message: 'Maximum 300 characters allowed ',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='nomineeBankName'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='nomineeAccountNo'
              label='Nominee Bank Account Number'
              rules={
                [
                  // {
                  //   min: 1,
                  //   max: 20,
                  //   message: "Account number should be less than 20 characters",
                  // },
                ]
              }>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='nomineeAccountNo'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='nomineeIfsc'
              label='Nominee IFSC Code'
              rules={[
                {
                  pattern: /^[A-Za-z]{4}\d{7}$/,
                  message: 'Enter Valid IFSC Code',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='nomineeIfsc'
              />
            </Form.Item>{' '}
          </Col>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item
              name='userName'
              label='Name As Per Bank Account'
              rules={[
                // { required: true, message: "Enter Name" },
                {
                  pattern: /^[A-Za-z\s]*$/,
                  message: 'Only Alphabetic Allowed',
                },
              ]}>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='userName'
              />
            </Form.Item>{' '}
          </Col> */}
        </Row>
        <hr />
        <Title level={5}>Other Details</Title>
        <Row gutter={16}>
          {/* <Col className='gutter-row' span={6}>
            <Form.Item name='shoeSize' label='Shoe Size'>
              <Select
                size='large'
                placeholder='Shoe Size'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'shoeSize', value } })
                }>
                <Option value=''></Option>
                <Option value='5'>5 in</Option>
                <Option value='6'>6 in</Option>
                <Option value='7'>7 in</Option>
                <Option value='8'>8 in</Option>
                <Option value='9'>9 in</Option>
                <Option value='10'>10 in</Option>
                <Option value='11'>11 in</Option>
                <Option value='12'>12 in</Option>
              </Select>
            </Form.Item>{' '}
          </Col> */}
          <Col className='gutter-row' span={6}>
            <Form.Item name='bloodGroup' label='Blood Group' hasFeedback>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'bloodGroup', value } })
                }>
                {bloodGroup?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='academicQualification'
              label='Academic Qualification'>
              <Input
                size='large'
                disabled={isDisabled}
                onChange={onChange}
                name='academicQualification'
              />
            </Form.Item>{' '}
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item name='techQualification' label='Technical Qualification'>
              <Select
                size='large'
                disabled={isDisabled}
                onChange={(value) =>
                  onChange({ target: { name: 'techQualification', value } })
                }>
                {technicalDetails?.map((area, index) => (
                  <Option key={index} value={area}>
                    {area}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={6}>
            <Form.Item
              name='dl'
              label='Driving Licence Number'
              rules={[
                ({ getFieldValue }) => ({
                  required:
                    getFieldValue('designation') == 'MHE Crane Operator', // Set required based on the value of 'dl'
                }),
              ]}>
              <Input
                size='large'
                onChange={Esictoggle}
                disabled={isDisabled}
                name='dl'
              />
            </Form.Item>
          </Col>

          <Col className='gutter-row' span={6}>
            <Form.Item
              name='dlExpiry'
              label='DL Validity Date'
              dependencies={['dl']}
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('dl'), // Set required based on the value of 'dl'
                  validator(_, value) {
                    if (!getFieldValue('dl') || value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error('Please select DL Validity Date')
                    );
                  },
                }),
              ]}>
              <DatePicker
                style={{ width: '100%', height: '40px' }}
                format='DD-MM-YYYY'
                disabled={isDisabled}
                inputReadOnly={true} // Disable manual input
                onChange={(e) => handleFieldChange('dlExpiry', e)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button
            type='primary'
            htmlType='submit'
            disabled={isNextButtonDisabled}>
            Next
          </Button>
        </Form.Item>
        <Form.Item style={{ float: 'right', marginLeft: '10px' }}>
          <Button onClick={() => onPrev(data)} type='primary'>
            Prev
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
export default Jobdetails;
