import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, DatePicker, Table, Space, Typography, Select, Row, Col, message, Modal } from 'antd';
import { PlusOutlined, DeleteOutlined, LeftOutlined, AlignCenterOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import { fetchWorkOrderDetailsData } from '../../services/actions/api/workOrderDetails';
import { fetchLrLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionsDetails'
import { createLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionDetails';
import { jobOrderDetailsColumn } from "../constant/jobOrderList";
import { useParams, useHistory, useNavigate, useLocation } from 'react-router-dom';
import { updateLrDetailsDataByLrNumber, fetchLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionsDetails'
import moment from 'moment';
import dayjs from 'dayjs';
import approve from '../../assets/images/Approved.png';
import reject from '../../assets/images/Reject.png';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const LaborRequisitionDetails = () => {
    const [form] = Form.useForm();
    const [jobOrderDataSource, setJobOrderDataSource] = useState([]);
    const [dataDetail, setDataDetail] = useState({});
    const [allJobData, setAllJobData] = useState([]);
    const [lrDetailsDataSource, setLRDetailsDataSource] = useState([]);
    const { workOrderNumber, lrNumber } = useParams();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [rejectionRemark, setRejectionRemark] = useState('');
    const [lrPerticularWODetails, setLrPerticularWODetails] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    // const isLRPage = location.pathname.includes('laborRequisitionDetail'); 
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0)
    const isLRPage = location.pathname.includes('laborRequisitionList'); 
    const columns = jobOrderDetailsColumn(isLRPage);
    const [lrData, setLrData] = useState({});

    console.log("workOrderNumber & lrNumber", workOrderNumber, lrNumber);

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                if (workOrderNumber) {
                    await fetchWorkOrderData(workOrderNumber);
                }
                if (lrNumber) {
                    console.log("lrNumber", lrNumber);
                    await fetchLRData(lrNumber);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [workOrderNumber, lrNumber]);

    const fetchWorkOrderData = async (workOrderNumber) => {
        console.log("workOrderNumber", workOrderNumber);
        const workOrderData = await fetchWorkOrderDetailsData(workOrderNumber);
        console.log("Fetched work order data:", workOrderData);

        if (workOrderData?.serviceDetails) {
            setDataDetail(workOrderData);
            setAllJobData(workOrderData.serviceDetails);
            const dataWithKeys = workOrderData.serviceDetails.map((item, index) => ({
                ...item,
                key: index
            }));
            setJobOrderDataSource(dataWithKeys);
            setLRDetailsDataSource([]);
        } else {
            console.log('No serviceDetails found in the response:', workOrderData);
        }
    };
    
    const fetchLRData = async (lrNumber) => {
        console.log("lrNumber", lrNumber);
        const lrDatas = await fetchLrLaborRequisitionDetailsData(lrNumber);
        console.log("Fetched LR data:", lrDatas);
        setLrData(lrDatas)

        if (lrDatas) {
            console.log("lrData", lrDatas);
            const validFromDate = moment(lrDatas.validFrom, 'DD-MM-YYYY');
            const validToDate = moment(lrDatas.validTo, 'DD-MM-YYYY');
            form.setFieldsValue({
                validFrom: validFromDate,
                validTo: validToDate,
                offDaysPerWeek: lrDatas.offDaysPerWeek,
                remark: lrDatas.remark,
                rejectionRemark: lrDatas.rejectionRemark
            });
            console.log("lrData", lrDatas);
            const formattedLRDetails = lrDatas.jobDetails.map(detail => ({
                ...detail,
                shiftInformation: detail.shiftInformation.map(shiftData => ({
                    shiftA: shiftData.shiftA || '',
                    shiftB: shiftData.shiftB || '',
                    shiftC: shiftData.shiftC || '',
                    shiftGen: shiftData.shiftGen || '',
                }))
            }));
            console.log("formattedLRDetails", formattedLRDetails);
            setLRDetailsDataSource(formattedLRDetails);
            const workOrderNum = lrNumber.split('-').pop();
            console.log("workOrderNum from lrNumber", workOrderNum);
            const workOrderDataFromLr = await fetchWorkOrderDetailsData(workOrderNum);
            console.log("Fetched work order data from LR number:", workOrderDataFromLr);
            if (workOrderDataFromLr?.serviceDetails) {
                setDataDetail(workOrderDataFromLr);
                setAllJobData(workOrderDataFromLr.serviceDetails);
                const dataWithKeys = workOrderDataFromLr.serviceDetails.map((item, index) => ({
                    ...item,
                    key: index, // Add a unique key to each item
                }));
                setJobOrderDataSource(dataWithKeys);
            } else {
                console.log('No serviceDetails found in the response:', workOrderDataFromLr);
            }
        } else {
            console.log('No LR details found for lrNumber:', lrNumber);
        }
    };
    console.log(lrData);
    
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const handleAdd = () => {
        const newData = {
            key: Date.now().toString(),
            sVCLnItemName: '',
            qty: '',
            // remainingQty: '',
            remainingQty: calculateRemainingQty(allJobData, lrPerticularWODetails),
            sVCNum: '',
            trade: '',
            skill: '',
            shiftInformation: [
                {
                    shiftA: '',
                    shiftB: '',
                    shiftC: '',
                    shiftGen: '',
                }
            ],
        };
        setLRDetailsDataSource([...lrDetailsDataSource, newData]);

    };

    const handleDelete = (key) => {
        const newData = lrDetailsDataSource.filter((item) => item.key !== key);
        setLRDetailsDataSource(newData);
    };

  
    const calculateRemainingQty = (jobOrderDataSource, lrPerticularWODetails, newLRDetails) => {
        // Clone jobOrderDataSource to avoid mutation
        const clonedJobOrderDataSource = JSON.parse(JSON.stringify(jobOrderDataSource));
    
        // Iterate over existing LR details and update the cloned job order data
        lrPerticularWODetails.forEach(lrDetail => {
            const existingJob = clonedJobOrderDataSource.find(job => job.sVCNum === lrDetail.sVCNum);
            if (existingJob) {
                // Calculate total quantity used across shifts
                const totalShiftQty = (parseInt(lrDetail.shiftA, 10) || 0) + (parseInt(lrDetail.shiftB, 10) || 0) +
                                      (parseInt(lrDetail.shiftC, 10) || 0) + (parseInt(lrDetail.shiftGen, 10) || 0);
    
                // Adjust remainingQty by subtracting used quantity
                existingJob.remainingQty -= totalShiftQty;
    
                // Adjust remainingQty by adding back rejected quantities
                if (lrDetail.status === "Rejected") {
                    existingJob.remainingQty += totalShiftQty;
                }
            }
        });
    
        // Now calculate remainingQty for new LR details (if provided)
        if (newLRDetails) {
            newLRDetails.forEach(newLR => {
                const existingJob = clonedJobOrderDataSource.find(job => job.sVCNum === newLR.sVCNum);
                if (existingJob) {
                    // Calculate total quantity used across shifts for the new LR
                    const totalShiftQty = (parseInt(newLR.shiftA, 10) || 0) + (parseInt(newLR.shiftB, 10) || 0) +
                                          (parseInt(newLR.shiftC, 10) || 0) + (parseInt(newLR.shiftGen, 10) || 0);
    
                    // Adjust remainingQty by subtracting used quantity for the new LR
                    existingJob.remainingQty -= totalShiftQty;
                }
            });
        }
    
        // Return the cloned job order data with adjusted remainingQty
        return clonedJobOrderDataSource;
    };
    

    const totalQtyData = (jobOrderDataSource, lrPerticularWODetails) => {
        if (!Array.isArray(jobOrderDataSource) || !Array.isArray(lrPerticularWODetails)) {
            console.error("Data source is not an array");
            return [];
        }

        if (!Array.isArray(lrPerticularWODetails)) {
            console.error("lrPerticularWODetails is not an array:", lrPerticularWODetails);
            return jobOrderDataSource.map(item => ({ ...item, remainingQty: item.qty }));
        }

        return jobOrderDataSource.map((jobOrderItem) => {
            console.log("jobOrderItem", jobOrderItem);
            console.log("setLrPerticularWODetails", lrPerticularWODetails);
           
        let totalShiftQty = 0;
        // Calculate totalShiftQty based on lrPerticularWODetails
            lrPerticularWODetails.forEach(lrDetail => {
                lrDetail.jobDetails.forEach(detail => {
                    if (detail.sVCNum === jobOrderItem.sVCNum) {
                        // Accumulate total shift quantities
                        totalShiftQty += (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftA, 10) || 0) +
                                        (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftB, 10) || 0) +
                                        (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftC, 10) || 0) +
                                        (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftGen, 10) || 0);
                    }
                });
            });
            console.log(totalShiftQty, jobOrderItem.qty);
            let remainingQty = parseInt(jobOrderItem.qty, 10) - totalShiftQty;
            
            lrPerticularWODetails.forEach(lrDetail => {
                lrDetail.jobDetails.forEach(detail => {
                    if (detail.sVCNum === jobOrderItem.sVCNum && detail.status === "Rejected") {
                        // const rejectedQty = (parseInt(detail.shiftA, 10) || 0) + (parseInt(detail.shiftB, 10) || 0) + (parseInt(detail.shiftC, 10) || 0) + (parseInt(detail.shiftGen, 10) || 0);
                        const rejectedQty = (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftA, 10) || 0) +
                                            (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftB, 10) || 0) +
                                            (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftC, 10) || 0) +
                                            (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1].shiftGen, 10) || 0);

                        remainingQty += rejectedQty;
                    }
                })  
            });

            return { ...jobOrderItem, remainingQty };
        });
    };

    const fetchData = async () => {
        try {
          const response = await fetchLaborRequisitionDetailsData(workOrderNumber);
          console.log("response", response);
          setLrPerticularWODetails(response);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle the error appropriately
        }
      };

      useEffect(() => {
        fetchData();
      }, [workOrderNumber]);
    

    useEffect(() => {
        const updatedData = totalQtyData(jobOrderDataSource, lrPerticularWODetails);
        setData(updatedData);
    }, [jobOrderDataSource, lrPerticularWODetails]);

    const handleJobChange = (index, value) => {
        const selectedJobData = allJobData.find(job => job.sVCLnItemName === value);
        console.log(selectedJobData);
        
        if (selectedJobData) {
            console.log("lrPerticularWODetails", lrPerticularWODetails);
    
            // Find the most recent remainingQty from the last entry in lrPerticularWODetails for the specified sVCNum
            let mostRecentRemainingQty = selectedJobData.qty;
    
            const latestDetail = lrPerticularWODetails[lrPerticularWODetails.length - 1]?.jobDetails.find(detail => detail.sVCNum === selectedJobData.sVCNum);
    
            if (latestDetail) {
                mostRecentRemainingQty = latestDetail.remainingQty;
            }
    
            console.log("mostRecentRemainingQty", mostRecentRemainingQty);
    
            // Update LR details data source
            setLRDetailsDataSource(prevData => {
                const newData = [...prevData];
                newData[index] = { 
                    ...newData[index], 
                    ...selectedJobData, 
                    sVCLnItemName: value,
                    remainingQty: mostRecentRemainingQty 
                };
                return newData;
            });
        } else {
            console.error(`Job data not found for ${value}`);
        }
    };

    const handleInputChange = (index, field, value) => {
        setLRDetailsDataSource(prevData => {
            const newData = [...prevData];
            const updatedRecord = { ...newData[index] };
    
            // Get the current shift information and the previous value of the field
            const currentShiftInfo = updatedRecord.shiftInformation[updatedRecord.shiftInformation.length - 1] || {};
            const previousValue = parseInt(currentShiftInfo[field] || 0, 10);
            const newValue = parseInt(value, 10) || 0;
    
            // Update the specific field in shiftInformation with the new value
            const updatedShiftInfo = {
                ...currentShiftInfo,
                [field]: newValue
            };
            updatedRecord.shiftInformation = [updatedShiftInfo];
    
            // Calculate the total shift quantity
            const totalShiftQty = (
                parseInt(updatedShiftInfo.shiftA || 0, 10) +
                parseInt(updatedShiftInfo.shiftB || 0, 10) +
                parseInt(updatedShiftInfo.shiftC || 0, 10) +
                parseInt(updatedShiftInfo.shiftGen || 0, 10)
            );
    
            // Calculate the initial remaining quantity based on remainingQty
            const initialRemainingQty = parseInt(updatedRecord.remainingQty, 10);
            console.log("initialRemainingQty", initialRemainingQty);
    
            // Calculate the difference between the previous and new value of the field
            const difference = newValue - previousValue;

            // Validate if the total shifts exceed the initial quantity
            if (totalShiftQty > initialRemainingQty) {
                message.error("Total shifts (A + B + C + Gen) cannot exceed the balance quantity.");
                // Reset the changed field to its previous value to maintain consistency
                updatedShiftInfo[field] = previousValue;
                updatedRecord.shiftInformation = [updatedShiftInfo];
                return prevData; // Return previous state if validation fails
            }
    
            // Adjust remainingQty based on the difference
            updatedRecord.remainingQty = Math.max(0, initialRemainingQty - difference);
    
            // Update the newData array with the modified record
            newData[index] = updatedRecord;
    
            return newData;
        });
    };
    

    const lrDetailsColumns = [
        {
            title: 'Job',
            dataIndex: 'sVCLnItemName',
            key: 'sVCLnItemName',
            render: (text, record, index) => {
                const selectedJobs = lrData.jobDetails.map(item => item.sVCLnItemName);
                const availableJobs = allJobData.filter(job => !selectedJobs.includes(job.sVCLnItemName));
                return(
                <Select
                    value={record.sVCLnItemName}
                    onChange={(value) => handleJobChange(index, value)}
                    style={{ width: 200 }}
                >
                    {availableJobs.map((job) => (
                        <Option key={job.sVCLnItemNum} value={job.sVCLnItemName} >
                            {job.sVCLnItemName}
                        </Option>
                    ))}
                </Select>
                )
            },
        },

        {
            title: 'Balance Qty',
            dataIndex: 'remainingQty',
            key: 'remainingQty',
            render: (_, record, index) => (
            // render: (_, record, index) => (
                <Input
                    value={record.remainingQty}
                    // onChange={(e) => {
                    //     console.log('Input value changed:', e.target.value);
                    //     handleInputChange(index, 'remainingQty')
                    // }}
                    disabled
                    style={{color:"gray"}}
                />
            ),
        },
        {
            title: 'Service Code',
            dataIndex: 'sVCNum',
            key: 'sVCNum',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.sVCNum}
                    onChange={(e) => handleInputChange(index, 'sVCNum', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Trade',
            dataIndex: 'trade',
            key: 'trade',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.trade}
                    onChange={(e) => handleInputChange(index, 'trade', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skill',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.skill}
                    onChange={(e) => handleInputChange(index, 'skill', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Shift A',
            dataIndex: 'shiftA',
            key: 'shiftA',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftA}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftA || ''}
                    onChange={(e) => {
                        console.log('Input value changed:', e.target.value);
                        handleInputChange(index, 'shiftA', e.target.value)
                    }}
                />
            ),
        },
        {
            title: 'Shift B',
            dataIndex: 'shiftB',
            key: 'shiftB',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftB}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftB || ''}
                    onChange={(e) => handleInputChange(index, 'shiftB', e.target.value)}
                />
            ),
        },
        {
            title: 'Shift C',
            dataIndex: 'shiftC',
            key: 'shiftC',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftC}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftC || ''}
                    onChange={(e) => handleInputChange(index, 'shiftC', e.target.value)}
                />
            ),
        },
        {
            title: 'Shift Gen',
            dataIndex: 'shiftGen',
            key: 'shiftGen',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftGen}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftGen || ''}
                    onChange={(e) => handleInputChange(index, 'shiftGen', e.target.value)}
                    // disabled={!!lrNumber}
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
                render: (_, record, index) => (
                    <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)} />
                ),
        },
    ];

    const approveLaborRequisitionData = async () => {
        try {
            console.log("tested");
            
            const formValues = form.getFieldsValue();
            console.log("tested", formValues);
            
            const updatedData = {
                workOrderNumber:workOrderNumber,
                lrNumber: lrNumber,
                jobName: lrDetailsDataSource[0].jobName,
                unitCode: lrDetailsDataSource[0].unitCode,
                workOrderType: lrDetailsDataSource[0].workOrderType,
                contractorName: lrDetailsDataSource[0].contractorName,
                status: "Approved",
                validFrom: formValues.validFrom ? formValues.validFrom.format('DD-MM-YYYY') : '',
                validTo: formValues.validTo ? formValues.validTo.format('DD-MM-YYYY') : '',
                offDaysPerWeek: formValues.offDaysPerWeek || '',
                remark: formValues.remark || '',
                jobDetails: lrDetailsDataSource.map(detail => ({
                    sVCNum: detail.sVCNum,
                    sVCLnItemName:detail.sVCLnItemName,
                    trade: detail.trade,
                    skill: detail.skill,
                    uom: detail.uom,
                    wbsElement:detail.wbsElement,
                    rate: detail.rate,
                    qty:detail.qty,
                    remainingQty:detail.remainingQty,
                    shiftInformation: detail.shiftInformation.map(data => ({
                        shiftA: data.shiftA,
                        shiftB: data.shiftB,
                        shiftC: data.shiftC,
                        shiftGen: data.shiftGen,
                        lrAQty: data.lrAQty || 0,
                        lrBQty: data.lrBQty || 0,
                        lrCQty: data.lrCQty || 0,
                        lrGenQty: data.lrGenQty || 0,
                        scheduleAQty: data.scheduleAQty || 0,
                        scheduleBQty: data.scheduleBQty || 0,
                        scheduleCQty: data.scheduleCQty || 0,
                        scheduleGenQty: data.scheduleGenQty || 0,
                        balScheduleAQty: data.balScheduleAQty || 0,
                        balScheduleBQty: data.balScheduleBQty || 0,
                        balScheduleCQty: data.balScheduleCQty || 0,
                        balScheduleGenQty: data.balScheduleGenQty || 0,
                    }))
                })),
            };
    
            const response = await updateLrDetailsDataByLrNumber(lrNumber, updatedData);
            console.log('Response:', response);
    
            if (response) {
                message.success('Labor requisition data updated successfully!');
                navigate(-1);
            } else {
                message.error('Failed to update labor requisition data.');
            }
        } catch (error) {
            console.error('Error updating labor requisition data:', error);
            message.error('An error occurred while updating labor requisition data.');
        }
    };
   
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form
            .validateFields()
            .then(async() => {
                // If the form is valid, perform the action
                // For example, you can close the modal
                setIsModalVisible(false);
                await rejectLaborRequisitionData();
            })
            .catch(errorInfo => {
                // Validation failed, do nothing or show error message
                console.log('Validation failed:', errorInfo);
            });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const rejectLaborRequisitionData = async () => {
        try {
            console.log("tested");
            
            const formValues = form.getFieldsValue();
            console.log("tested", formValues);
            const updatedData = {
                workOrderNumber:workOrderNumber,
                lrNumber: lrNumber,
                jobName: lrDetailsDataSource[0].jobName,
                unitCode: lrDetailsDataSource[0].unitCode,
                workOrderType: lrDetailsDataSource[0].workOrderType,
                contractorName: lrDetailsDataSource[0].contractorName,
                status: "Rejected",
                validFrom: formValues.validFrom ? formValues.validFrom.format('DD-MM-YYYY') : '',
                validTo: formValues.validTo ? formValues.validTo.format('DD-MM-YYYY') : '',
                offDaysPerWeek: formValues.offDaysPerWeek || '',
                remark: formValues.remark || '',
                rejectionRemark:rejectionRemark,
                jobDetails: lrDetailsDataSource.map(detail => ({
                    sVCNum: detail.sVCNum,
                    sVCLnItemName:detail.sVCLnItemName,
                    trade: detail.trade,
                    skill: detail.skill,
                    uom: detail.uom,
                    wbsElement:detail.wbsElement,
                    rate: detail.rate,
                    qty:detail.qty,
                    remainingQty:detail.remainingQty,
                    shiftInformation: detail.shiftInformation.map(data => ({
                        shiftA: data.shiftA,
                        shiftB: data.shiftB,
                        shiftC: data.shiftC,
                        shiftGen: data.shiftGen,
                        lrAQty: data.lrAQty || 0,
                        lrBQty: data.lrBQty || 0,
                        lrCQty: data.lrCQty || 0,
                        lrGenQty: data.lrGenQty || 0,
                        scheduleAQty: data.scheduleAQty || 0,
                        scheduleBQty: data.scheduleBQty || 0,
                        scheduleCQty: data.scheduleCQty || 0,
                        scheduleGenQty: data.scheduleGenQty || 0,
                        balScheduleAQty: data.balScheduleAQty || 0,
                        balScheduleBQty: data.balScheduleBQty || 0,
                        balScheduleCQty: data.balScheduleCQty || 0,
                        balScheduleGenQty: data.balScheduleGenQty || 0,
                    }))
                })),
            };
    
            const response = await updateLrDetailsDataByLrNumber(lrNumber, updatedData);
            console.log('Response:', response);
    
            if (response) {
                message.success('Labor requisition data updated successfully!');
                navigate(-1);
            } else {
                message.error('Failed to update labor requisition data.');
            }
        } catch (error) {
            console.error('Error updating labor requisition data:', error);
            message.error('An error occurred while updating labor requisition data.');
        }
    };
    

    console.log("dataDetail", dataDetail, "lrDetailsDataSource", lrDetailsDataSource[0]?.status, lrDetailsDataSource[0]?.rejectionRemark);

    function formatMongoTimestamp(timestamp) {   
        // Handle potential invalid timestamps gras
        console.log("5555", timestamp);
        
        if (isNaN(timestamp)) 
        {     
            return 'Invalid timestamp';   
        }   
        const date = moment(timestamp).utcOffset('+05:30'); 
        // Adjust for IST time zonereturn
        console.log("dateeeeeeeee", date); 
        return date.format('DD-MM-YYYY') + ', ' + date.format('HH:mm:ss'); 
    }

    return (
        <Layout style={{ minHeight: '100vh', padding: '16px', backgroundColor: "#ffff" }}>
            <Row gutter={16} style={{ marginBottom: 16, marginRight: '50px', padding: '10px' }} align="middle" justify="space-between">
                <Col span={6}>
                    <Button type="link" style={{ color: 'black' }} onClick={() => navigate(-1)}>
                        <LeftOutlined />
                    </Button>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>LR Summary</span>
                </Col>
                {(lrData?.status === "Pending") && (
                        <Col span={12} style={{ textAlign: 'right' }}>
                            <Button
                                style={{ color: 'black', background: '#E0E0E0', marginRight: 8 }}
                                // onClick={rejectLaborRequisitionData}
                                onClick={showModal}
                            >
                                <span style={{ fontWeight: 'bold' }}>Reject</span>
                            </Button>
                            <Button
                                style={{ color: 'white', background: '#1677FF' }}
                                onClick={approveLaborRequisitionData}
                            >
                                <span style={{ fontWeight: 'bold' }}>Approve</span>
                            </Button>
                        </Col>
                    )}
                    {(lrData.status !== "Pending") && (
                        <Col span={12} style={{ textAlign: 'right' }}>
                            {lrData.status === "Approved" && (
                                <img src={approve} alt="Status" style={{ height: '30px', marginRight: '6px' }} />
                            )}
                            
                            {lrData.status === "Rejected" && (
                                <img src={reject} alt="Status" style={{ height: '30px', marginRight: '6px'}} />
                            )}
                            <span style={{ fontWeight: 'bold', fontSize:'15px' }}>{lrData.status} On {new Date(lrData.updatedAt).toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })}
                                {/* {formatMongoTimestamp(lrData.updatedAt)} */}
                            </span>
                        </Col>
                    )}

            </Row>
            
            <Modal
                title="Reject Labor Requisition"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form form={form} >
                    <Form.Item
                        name="rejectionRemark"
                        label="Remark"
                        rules={[{ required: true, message: 'Please enter a remark' }]}
                    >
                        <Input.TextArea
                            value={rejectionRemark}
                            onChange={(e) => setRejectionRemark(e.target.value)}
                        />
                        {/* <Input.TextArea /> */}
                    </Form.Item>
                </Form>
            </Modal>

            <Content style={{ margin: '5px', padding: '10px' }}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Row gutter={16}>
                    <Col span={8}>
                    <div style={{ background: '#F4F6FF', borderRadius: '8px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '140px' }}>
                        <Form.Item label={<span style={{ display: 'block', marginTop: '50px'  }}>Work Order Number : {dataDetail.workOrderNumber}</span>} />
                        <Form.Item label={<span style={{ display: 'block', marginBottom: '10px'  }}>Vendor Code : {dataDetail.vendorCode}</span>} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{ background: '#F4F6FF', borderRadius: '8px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '140px' }}>
                        <Form.Item label={<span style={{ display: 'block', marginTop: '50px' }}>Contractor : {dataDetail.contractorName}</span>} />
                        <Form.Item label={<span style={{ display: 'block', marginBottom: '10px' }}>Department : {dataDetail.departmentName}</span>} />
                        </div>
                    </Col>
                    <Col span={8}>
                        <div style={{ background: '#F4F6FF', borderRadius: '8px', padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '140px' }}>
                        <Form.Item label={<span style={{ display: 'block', marginTop: '50px' }}>LR Validity From : {dayjs(dataDetail.validFrom).format('DD-MM-YYYY')}</span>} />
                        {/* {dayjs(dataDetail.validFrom).format('DD-MM-YYYY')} */}
                        {/* dayjs(dataDetail.validTo).format('DD-MM-YYYY') */}
                        <Form.Item label={<span style={{ display: 'block', marginBottom: '10px' }}>LR Validity To : {dayjs(dataDetail.validTo).format('DD-MM-YYYY')}</span>} />
                        </div>
                    </Col>
                    </Row>

                    <Title level={5} style={{ marginTop: '20px' }}>
                        Job Order Details
                    </Title>
                    <Table
                        // dataSource={jobOrderDataSource}
                        // dataSource={data}
                        dataSource={lrDetailsDataSource}
                        columns={columns}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                        bordered
                    />
                    <div style={{ background: '#F4F6FF', padding: '15px', borderRadius: '8px' }}>
                        <Title level={5} style={{ marginTop: '10px' }}>
                            Basic Details
                        </Title>
                    </div><br/>
                    <div style={{ marginBottom: '30px' }}>
                        <Space size="large">
                            <Row gutter={16} >
                                <Col span={12}>
                                    <Form.Item name="validFrom" label="LR Validity From"  style={{alignItems:"center", paddingLeft:"2px"}}>
                                        <DatePicker 
                                        format="DD-MM-YYYY" size='large' style={{ width: '100%', color:"gray" }}
                                        disabled/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="validTo" label="LR Validity To">
                                        <DatePicker 
                                        format="DD-MM-YYYY" size='large' style={{ width: '100%', color:"gray"}} 
                                        disabled/>
                                    </Form.Item>
                                </Col>
                                {/* <Col span={6}>
                                    <Form.Item name="offDaysPerWeek" label="Off days per week">
                                        <Input size='large'  disabled style={{color:"gray"}}/>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item name="remark" label="Remarks">
                                        <Input size='large'  disabled style={{color:"gray"}}/>
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Space>
                    </div>
                    <Title level={5} style={{ marginTop: '20px' }}>
                        LR Details
                    </Title>
                    <Table
                        dataSource={lrDetailsDataSource}
                        columns={lrDetailsColumns}
                        pagination={false}
                        bordered
                    />
                   
                    {lrData.status === "Rejected" && (
                     <>  
                     <Title level={5} style={{ marginTop: '20px' }}>
                         Remarks
                    </Title>
                    
                    <Row gutter={16}>
                        <Col span={24}>
                        <Form.Item name="rejectionRemark" >
                            <Input.TextArea 
                            rows={2} 
                            // value={lrDetailsDataSource[0]?.rejectionRemark || ''} 
                            style={{color:"gray"}}
                            disabled/>
                        </Form.Item>
                        </Col>
                    </Row>
                    </> 
                    )}
                </Form>
            </Content>
        </Layout>
    );
};

export default LaborRequisitionDetails;
