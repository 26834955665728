import axios from "./axiosInstance";

export const getSupplier = () => {
  return axios.get("/supplier");
};

// export const getSupplierId = (data) => {
//   console.log("data", data);
//   return axios.get("/supplier/supplierId", data);
// };

export const replaceSupplier = (data) => {
  return axios.put("/replaceSupplier", data);
};
