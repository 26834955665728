import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button, DatePicker, Table, Space, Typography, Select, Row, Col, message, Modal, Tooltip } from 'antd';
import { PlusOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import { fetchWorkOrderDetailsData } from '../../services/actions/api/workOrderDetails';
import { fetchLrLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionsDetails'
// import { createLaborRequisitionDetailsData } from '../actions/api/laborRequisitionDetails';
import { createLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionsDetails';
import { jobOrderDetailsColumn } from "../constant/jobOrderList";
import { useParams, useHistory, useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import { updateLrDetailsDataByLrNumber, fetchLaborRequisitionDetailsData } from "../../services/actions/api/laborRequisitionsDetails";
// import { updateLrDetailsDataByLrNumber, fetchLaborRequisitionDetailsData } from "../actions/api/laborRequisitionDetails";
// import { updateLrDetailDataByLrNumber } from "../api/laborRequisitionDetails";
import moment from 'moment';
import { locale } from 'antd/lib/locale/en_US';
import WorkOrderDetail from './WorkOrderDetail';

const { Header, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const LaborRequisitionDetail = () => {
    const [form] = Form.useForm();
    const [jobOrderDataSource, setJobOrderDataSource] = useState([]);
    const [dataDetail, setDataDetail] = useState({});
    const [allJobData, setAllJobData] = useState([]);
    const [lrDetailsDataSource, setLRDetailsDataSource] = useState([]);
    const { workOrderNumber, lrNumber } = useParams();
    const navigate = useNavigate();
    // const [validFrom, setValidFrom] = useState(null);
    // const [validTo, setValidTo] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [navigateToTab, setNavigateToTab] = useState(null);
    const [count, setCount] = useState(0)
    const [totalQty, setTotalQty] = useState(0);
    const [leftQty, setLeftQty] = useState(0)
    const [data, setData] = useState([]);
    const [lrPerticularWODetails, setLrPerticularWODetails] = useState([]);
    const [sectionHeadData, setSectionHeadData] = useState({});
    // const [pickerFocused, setPickerFocused] = useState(false);
    const [lrData, setLrData] = useState({});
    const location = useLocation();
    const isLRPage = location.pathname.includes('laborRequisitionDetail') 
    console.log("isLRPage", isLRPage);
    const columns = jobOrderDetailsColumn(isLRPage, lrNumber);

    console.log("workOrderNumber & lrNumber", workOrderNumber, lrNumber, lrDetailsDataSource);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (workOrderNumber) {
                    await fetchWorkOrderData(workOrderNumber);
                }
                if (lrNumber) {
                    console.log("lrNumber", lrNumber);
                    await fetchLRData(lrNumber);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [workOrderNumber, lrNumber]);


    const fetchWorkOrderData = async (workOrderNumber) => {
        console.log("workOrderNumber", workOrderNumber);
        const workOrderData = await fetchWorkOrderDetailsData(workOrderNumber);
        console.log("Fetched work order data:", workOrderData);
        setSectionHeadData(workOrderData)
        if (workOrderData?.serviceDetails) {
            setDataDetail(workOrderData);
            setAllJobData(workOrderData.serviceDetails);
            const dataWithKeys = workOrderData.serviceDetails.map((item, index) => ({
                ...item,
                key: index
            }));
            setJobOrderDataSource(dataWithKeys);
            setLRDetailsDataSource([]);
        } else {
            console.log('No serviceDetails found in the response:', workOrderData);
        }
    };

    const fetchLRData = async (lrNumber) => {
        console.log("lrNumber", lrNumber);
        const lrDatas = await fetchLrLaborRequisitionDetailsData(lrNumber);
        console.log("Fetched LR data:", lrData);
        setLrData(lrDatas)

        if (lrDatas) {
            console.log("lrData", lrDatas);
            const validFromDate = moment(lrDatas.validFrom, 'DD-MM-YYYY');
            const validToDate = moment(lrDatas.validTo, 'DD-MM-YYYY');
            form.setFieldsValue({
                validFrom: validFromDate,
                validTo: validToDate,
                offDaysPerWeek: lrDatas.offDaysPerWeek,
                remark: lrDatas.remark,
                rejectionRemark: lrDatas.rejectionRemark
            });
            console.log("lrData", lrDatas);
            const formattedLRDetails = lrDatas.jobDetails.map(detail => ({
                ...detail,
                shiftInformation: detail.shiftInformation.map(shiftData => ({
                    shiftA: shiftData.shiftA || '',
                    shiftB: shiftData.shiftB || '',
                    shiftC: shiftData.shiftC || '',
                    shiftGen: shiftData.shiftGen || '',
                }))
            }));
            console.log("formattedLRDetails", formattedLRDetails);

            setLRDetailsDataSource(formattedLRDetails);
        } else {
            console.log('No LR details found for lrNumber:', lrNumber);
        }
    };

    const handleAdd = () => {
        const newData = {
            key: Date.now().toString(),
            sVCLnItemName: '',
            qty: '',
            remainingQty: '',
            // remainingQty: calculateRemainingQty(allJobData, lrPerticularWODetails),
            sVCNum: '',
            trade: '',
            skill: '',
            shiftInformation: [
                {
                    shiftA: '',
                    shiftB: '',
                    shiftC: '',
                    shiftGen: '',
                }
            ],
        };


        // // Calculate and set remainingQty based on existing lrDetailsDataSource
        // const updatedRemainingQty = totalQtyData(jobOrderDataSource, lrPerticularWODetails);
        // newData.remainingQty = updatedRemainingQty.find(item => item.sVCNum === newData.sVCNum)?.remainingQty || '';

        setLRDetailsDataSource([...lrDetailsDataSource, newData]);

    };

    useEffect(() => {
        const updatedData = totalQtyData(jobOrderDataSource, lrPerticularWODetails);
        setData(updatedData);
        // setJobOrderDataSource(updatedData);
    }, [jobOrderDataSource, lrPerticularWODetails]);

    const totalQtyData = (jobOrderDataSource, lrPerticularWODetails) => {
        if (!Array.isArray(jobOrderDataSource) || !Array.isArray(lrPerticularWODetails)) {
            console.error("Data source is not an array");
            return [];
        }
    
        return jobOrderDataSource.map(jobOrderItem => {
            let totalShiftQty = 0;
            let remainingQty = parseInt(jobOrderItem.qty, 10);
    
            // Calculate totalShiftQty based on lrPerticularWODetails
            lrPerticularWODetails.forEach(lrDetail => {
                lrDetail.jobDetails.forEach(detail => {
                    if (detail.sVCNum === jobOrderItem.sVCNum) {
                        totalShiftQty += (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftA, 10) || 0) +
                                         (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftB, 10) || 0) +
                                         (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftC, 10) || 0) +
                                         (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftGen, 10) || 0);
                    }
                });
            });

            remainingQty -= totalShiftQty;
    
            // Calculate rejected quantities
            lrPerticularWODetails.forEach(lrDetail => {
                if (lrDetail.status === "Rejected") {
                    lrDetail.jobDetails.forEach(detail => {
                        if (detail.sVCNum === jobOrderItem.sVCNum) {
                            const rejectedQty = (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftA, 10) || 0) +
                                                (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftB, 10) || 0) +
                                                (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftC, 10) || 0) +
                                                (parseInt(detail.shiftInformation[detail.shiftInformation.length - 1]?.shiftGen, 10) || 0);
                            totalShiftQty -= rejectedQty; // Subtract rejected quantities from totalShiftQty
                        }
                    });
                }
            });
    
            remainingQty = parseInt(jobOrderItem.qty, 10) - totalShiftQty;
    
            console.log(`Job Order Item ${jobOrderItem.sVCNum}:`);
            console.log(`- Total Shift Qty: ${totalShiftQty}`);
            console.log(`- Remaining Qty: ${remainingQty}`);
    
            return { ...jobOrderItem, remainingQty };
        });
    };
    
    

    const fetchData = async () => {
        try {
          const response = await fetchLaborRequisitionDetailsData(workOrderNumber);
          console.log("response", response);
          setLrPerticularWODetails(response);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Handle the error appropriately
        }
      };

      useEffect(() => {
        fetchData();
      }, [workOrderNumber]);

    useEffect(() => {
        // Add an initial empty row when the component mounts
        handleAdd();
    }, []);
    
    
    console.log("setDataDetail", dataDetail, count, totalQty, leftQty, data, lrDetailsDataSource);

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const handleDelete = (key) => {
        const newData = lrDetailsDataSource.filter((item) => item.key !== key);
        setLRDetailsDataSource(newData);
    };

    const handleJobChange = (index, value) => {
        console.log("data", data, value);
        // const selectedJobData = allJobData.find(job => job.sVCLnItemName === value);
        const selectedJobData = data.find(job => job.sVCLnItemName === value);
        console.log(selectedJobData);
        
        if (selectedJobData) {
            console.log("lrPerticularWODetails", lrPerticularWODetails);
    
            // Find the most recent remainingQty from the last entry in lrPerticularWODetails for the specified sVCNum
            let mostRecentRemainingQty = selectedJobData.qty;
    
            const latestDetail = lrPerticularWODetails[lrPerticularWODetails.length - 1]?.jobDetails.find(detail => detail.sVCNum === selectedJobData.sVCNum);
    
            if (latestDetail) {
                console.log("latestDetail", latestDetail);
                // mostRecentRemainingQty = latestDetail.remainingQty;
                mostRecentRemainingQty = selectedJobData.remainingQty;
            }
    
            console.log("mostRecentRemainingQty", mostRecentRemainingQty);
    
            // Update LR details data source
            setLRDetailsDataSource(prevData => {
                const newData = [...prevData];
                newData[index] = { 
                    ...newData[index], 
                    ...selectedJobData, 
                    sVCLnItemName: value,
                    remainingQty: mostRecentRemainingQty 
                };
                console.log("newData",newData);
                return newData;
            });
        } else {
            console.error(`Job data not found for ${value}`);
        }
    };

    const handleInputChange = (index, field, value) => {
        setLRDetailsDataSource(prevData => {
            const newData = [...prevData];
            const updatedRecord = { ...newData[index] };
    
            // Get the current shift information and the previous value of the field
            const currentShiftInfo = updatedRecord.shiftInformation[updatedRecord.shiftInformation.length - 1] || {};
            const previousValue = parseInt(currentShiftInfo[field] || 0, 10);
            const newValue = parseInt(value, 10) || 0;
    
            // Update the specific field in shiftInformation with the new value
            const updatedShiftInfo = {
                ...currentShiftInfo,
                [field]: newValue
            };
            updatedRecord.shiftInformation = [updatedShiftInfo];
    
            // Calculate the total shift quantity
            const totalShiftQty = (
                parseInt(updatedShiftInfo.shiftA || 0, 10) +
                parseInt(updatedShiftInfo.shiftB || 0, 10) +
                parseInt(updatedShiftInfo.shiftC || 0, 10) +
                parseInt(updatedShiftInfo.shiftGen || 0, 10)
            );

            console.log("updatedRecord", updatedRecord);
    
            // Calculate the initial remaining quantity based on remainingQty
            const initialRemainingQty = parseInt(updatedRecord.remainingQty, 10);
            const initialQty = parseInt(updatedRecord.qty, 10);
    
            // Calculate the difference between the previous and new value of the field
            const difference = newValue - previousValue;

            // Store the remaining quantity in a variable
            let remainingQtyVariable = initialRemainingQty - difference;
    
            // // Validate if the total shifts exceed the initial quantity
            // console.log(totalShiftQty, initialQty, initialRemainingQty, data[0]?.remainingQty);
            // // if (totalShiftQty > initialQty && totalShiftQty != 0) {
            // if (totalShiftQty >= data.remainingQty && initialRemainingQty != 0) {
            //     console.log("totalShiftQty", initialRemainingQty);
            //     message.error("Total shifts (A + B + C + Gen) cannot exceed the initial quantity.");
            //     return prevData; // Return previous state if validation fails
            // }

             // Validate if the total shifts exceed the initial quantity
            console.log(totalShiftQty, initialQty, initialRemainingQty, remainingQtyVariable);
            if (totalShiftQty > initialQty || remainingQtyVariable < 0) {
                console.log("totalShiftQty", initialRemainingQty);
                message.error("Total shifts (A + B + C + Gen) cannot exceed the initial quantity.");
                return prevData; // Return previous state if validation fails
            }

            // // Adjust remainingQty based on the total shift quantity
            // updatedRecord.remainingQty = initialRemainingQty - difference;

            // Adjust remainingQty based on the total shift quantity
            updatedRecord.remainingQty = remainingQtyVariable;
    
            // Adjust remainingQty based on the total shift quantity
            // updatedRecord.remainingQty = initialQty - totalShiftQty;
    
            // Update the newData array with the modified record
            newData[index] = updatedRecord;
    
            return newData;
        });
    };

    const lrDetailsColumns = [
        {
            title: 'Job',
            dataIndex: 'sVCLnItemName',
            key: 'sVCLnItemName',
            render: (text, record, index) => {
                const selectedJobs = lrDetailsDataSource.map(item => item.sVCLnItemName);
                const availableJobs = allJobData.filter(job => !selectedJobs.includes(job.sVCLnItemName));
                return(
                <Select
                    value={record.sVCLnItemName}
                    onChange={(value) => handleJobChange(index, value)}
                    style={{ width: 200 }}
                >
                    {availableJobs.map((job) => (
                        <Option key={job.sVCLnItemNum} value={job.sVCLnItemName} >
                            {job.sVCLnItemName}
                        </Option>
                    ))}
                </Select>
                )
            },
        },

        {
            title: 'Balance Qty',
            dataIndex: 'remainingQty',
            key: 'remainingQty',
            render: (_, record) => {
                console.log("Record data:", record);
                return (
                    <Input
                        value={record.remainingQty}
                        disabled
                        style={{ color: "gray" }}
                    />
                );
            },
        },
        {
            title: 'Service Code',
            dataIndex: 'sVCNum',
            key: 'sVCNum',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.sVCNum}
                    onChange={(e) => handleInputChange(index, 'sVCNum', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Trade',
            dataIndex: 'trade',
            key: 'trade',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.trade}
                    onChange={(e) => handleInputChange(index, 'trade', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skill',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    value={record.skill}
                    onChange={(e) => handleInputChange(index, 'skill', e.target.value)}
                    style={{color:"gray"}}
                    disabled
                />
            ),
        },
        {
            title: 'Shift A',
            dataIndex: 'shiftA',
            key: 'shiftA',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftA}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftA || ''}
                    onChange={(e) => {
                        console.log('Input value changed:', e.target.value);
                        handleInputChange(index, 'shiftA', e.target.value)
                    }}
                />
            ),
        },
        {
            title: 'Shift B',
            dataIndex: 'shiftB',
            key: 'shiftB',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftB}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftB || ''}
                    onChange={(e) => handleInputChange(index, 'shiftB', e.target.value)}
                />
            ),
        },
        {
            title: 'Shift C',
            dataIndex: 'shiftC',
            key: 'shiftC',
            // render: (_, record) => (
            render: (_, record, index) => (
                <Input
                    // value={record.shiftC}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftC || ''}
                    onChange={(e) => handleInputChange(index, 'shiftC', e.target.value)}
                />
            ),
        },
        {
            title: 'Shift Gen',
            dataIndex: 'shiftGen',
            key: 'shiftGen',
            // render: (_, record) => (
            render: (_, record, index) => (
                // <Select
                //     value={record.shiftGen}
                //     onChange={(value) => handleInputChange(index, 'shiftGen', value)}
                // >
                //     <Option value="1">1</Option>
                //     <Option value="0">0</Option>
                // </Select>
                <Input
                    // value={record.shiftGen}
                    value={record.shiftInformation[record.shiftInformation.length-1]?.shiftGen || ''}
                    onChange={(e) => handleInputChange(index, 'shiftGen', e.target.value)}
                    // disabled={!!lrNumber}
                />
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            // render: (_, record) =>
                // record.key ? (
                //     <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)} />
                // ) : null,
                render: (_, record, index) => (
                    <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.key)} />
                ),
        },
    ];
    console.log("lrDetailsDataSource", lrDetailsDataSource, sectionHeadData.sectionHead);
    const saveLaborRequisitionData = async () => {
        try {
            await form.validateFields(['validFrom', 'validTo']);
            const formValues = form.getFieldsValue();
            console.log("lrDetailsDataSource", lrDetailsDataSource, sectionHeadData);

            const dataToSave = {
                workOrderNumber:workOrderNumber,
                lrNumber: lrNumber,
                companyCode:lrDetailsDataSource[0].companyCode,
                unitCode: lrDetailsDataSource[0].unitCode,
                deptCode: lrDetailsDataSource[0].deptCode,
                sectionCode: lrDetailsDataSource[0].sectionCode,
                vendorCode: lrDetailsDataSource[0].vendorCode,
                jobName: lrDetailsDataSource[0].jobName,
                departmentName: lrDetailsDataSource[0].departmentName,
                workOrderType: lrDetailsDataSource[0].workOrderType,
                contractorName: lrDetailsDataSource[0].contractorName,
                sectionHead: sectionHeadData.sectionHead,
                status: 'Pending',
                validFrom: formValues.validFrom ? formValues.validFrom.format('DD-MM-YYYY') : '',
                validTo: formValues.validTo ? formValues.validTo.format('DD-MM-YYYY') : '',
                offDaysPerWeek: formValues.offDaysPerWeek || '',
                remark: formValues.remark || '',
                jobDetails: lrDetailsDataSource.map(detail => ({
                    itemNum:detail.itemNum,
                    sVCLnItemNum:detail.sVCLnItemNum,
                    sVCNum: detail.sVCNum,
                    sVCLnItemName:detail.sVCLnItemName,
                    trade: detail.trade,
                    skill: detail.skill,
                    uom: detail.uom,
                    wbsElement:detail.wbsElement,
                    rate: detail.rate,
                    qty:detail.qty,
                    contractorSupervisor:detail.contractorSupervisor,
                    remainingQty:detail.remainingQty,
                    shiftInformation: detail.shiftInformation.map(data => ({
                        shiftA: data.shiftA,
                        shiftB: data.shiftB,
                        shiftC: data.shiftC,
                        shiftGen: data.shiftGen,
                        lrAQty: data.lrAQty || 0,
                        lrBQty: data.lrBQty || 0,
                        lrCQty: data.lrCQty || 0,
                        lrGenQty: data.lrGenQty || 0,
                        scheduleAQty: data.scheduleAQty || 0,
                        scheduleBQty: data.scheduleBQty || 0,
                        scheduleCQty: data.scheduleCQty || 0,
                        scheduleGenQty: data.scheduleGenQty || 0,
                        balScheduleAQty: data.balScheduleAQty || 0,
                        balScheduleBQty: data.balScheduleBQty || 0,
                        balScheduleCQty: data.balScheduleCQty || 0,
                        balScheduleGenQty: data.balScheduleGenQty || 0,
                    }))
                })),
            };
            console.log(dataToSave);
            await createLaborRequisitionDetailsData(dataToSave);
            // if (saveResult.success) {
                message.success('Labor Requisition details saved successfully');
                // setNavigateToTab('approved');
                setNavigateToTab('3');
                navigate(-1);
                // navigate(`/labour-requisition/approved`);
        } catch (error) {
            console.error('Failed to save Labor Requisition details:', error);
            message.error('Failed to save Labor Requisition details');
        }
    };
    console.log("lrDetailsDataSource", lrDetailsDataSource);
    const updateLaborRequisitionData = async () => {
        try {
            console.log("tested");
            
            const formValues = form.getFieldsValue();
            console.log("tested", formValues);
            const updatedData = {
                workOrderNumber:workOrderNumber,
                lrNumber: lrNumber,
                companyCode:lrDetailsDataSource[0].companyCode,
                unitCode: lrDetailsDataSource[0].unitCode,
                deptCode: lrDetailsDataSource[0].deptCode,
                sectionCode: lrDetailsDataSource[0].sectionCode,
                vendorCode: lrDetailsDataSource[0].vendorCode,
                jobName: lrDetailsDataSource[0].jobName,
                departmentName: lrDetailsDataSource[0].departmentName,
                workOrderType: lrDetailsDataSource[0].workOrderType,
                contractorName: lrDetailsDataSource[0].contractorName,
                sectionHead: sectionHeadData.sectionHead,
                status: 'Pending',
                // validFrom: formValues.validFrom ? formValues.validFrom.format('DD-MM-YYYY') : '',
                // validTo: formValues.validTo ? formValues.validTo.format('DD-MM-YYYY') : '',
                validFrom: formValues.validFrom ? formValues.validFrom.format('DD-MM-YYYY') : '',
                validTo: formValues.validTo ? formValues.validTo.format('DD-MM-YYYY') : '',
                offDaysPerWeek: formValues.offDaysPerWeek || '',
                remark: formValues.remark || '',
                jobDetails: lrDetailsDataSource.map(detail => ({
                    itemNum:detail.itemNum,
                    sVCLnItemNum:detail.sVCLnItemNum,
                    sVCNum: detail.sVCNum,
                    sVCLnItemName:detail.sVCLnItemName,
                    trade: detail.trade,
                    skill: detail.skill,
                    uom: detail.uom,
                    wbsElement:detail.wbsElement,
                    rate: detail.rate,
                    qty:detail.qty,
                    contractorSupervisor:detail.contractorSupervisor,
                    remainingQty:detail.remainingQty,
                    shiftInformation: detail.shiftInformation.map(data => ({
                        shiftA: data.shiftA,
                        shiftB: data.shiftB,
                        shiftC: data.shiftC,
                        shiftGen: data.shiftGen,
                        lrAQty: data.lrAQty || 0,
                        lrBQty: data.lrBQty || 0,
                        lrCQty: data.lrCQty || 0,
                        lrGenQty: data.lrGenQty || 0,
                        scheduleAQty: data.scheduleAQty || 0,
                        scheduleBQty: data.scheduleBQty || 0,
                        scheduleCQty: data.scheduleCQty || 0,
                        scheduleGenQty: data.scheduleGenQty || 0,
                        balScheduleAQty: data.balScheduleAQty || 0,
                        balScheduleBQty: data.balScheduleBQty || 0,
                        balScheduleCQty: data.balScheduleCQty || 0,
                        balScheduleGenQty: data.balScheduleGenQty || 0,
                    }))
                })),
            };
    
            const response = await updateLrDetailsDataByLrNumber(lrNumber, updatedData);
            console.log('Response:', response);
    
            if (response) {
                message.success('Labor requisition data updated successfully!');
                navigate(-1);
            } else {
                message.error('Failed to update labor requisition data.');
            }
        } catch (error) {
            console.error('Error updating labor requisition data:', error);
            message.error('An error occurred while updating labor requisition data.');
        }
    };
    
    const showPopup = () => {
        console.log("tesedcvv");
        // setIsModalVisible(true);
        form.validateFields()
        .then((values) => {
            console.log("tesedcvv", values);
            if (Object.values(values).some(value => value)) {
            setIsModalVisible(true);
            } else {
            navigate(-1);
            }
        })
        .catch(() => {
            navigate(-1);
        });
    };
    
    const handleOk = () => {
    // message.info('Please click the save button.');
    saveLaborRequisitionData();
    setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        navigate(-1);
    };

    console.log("setNavigateToTab", navigateToTab, data);

    const disabledDate = (current) => {
        // Disable dates before validFrom and after validTo
        return current && (current < moment(dataDetail.validFrom, 'YYYY-MM-DD') || current > moment(dataDetail.validTo, 'YYYY-MM-DD'));
    };

    console.log("data.remainingQty", data[0]?.remainingQty);

    console.log("dataDetail", dataDetail);
    console.log("lrData",lrData);
    console.log("lrDetailsDataSource",lrDetailsDataSource);

    // Compute whether the button should be disabled based on your logic
    const isAddButtonDisabled = () => {
        return (lrDetailsDataSource.length >= allJobData.length) || (lrDetailsDataSource.some(item => item.remainingQty <= 0));
    };

    return (
        <Layout style={{ minHeight: '100vh', padding: '16px', backgroundColor: "#ffff" }}>
            {/* <Row gutter={16} style={{ marginBottom: 16, marginRight: '50px', padding: '10px' }} align="middle" justify="space-between"> */}
            <Row gutter={16} style={{ marginBottom: 16 }} justify="space-between" align="middle">
                <Col span={12}>
                    <Button type="link" style={{ color: 'black' }} onClick={() => navigate(-1)}>
                        <LeftOutlined />
                    </Button>
                    {/* <Button type="link" style={{ color: 'black' }} onClick={showPopup}>
                        <LeftOutlined />
                    </Button> */}
                    {/* <Modal
                        title="Confirmation"
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <p>Do you want to save?</p>
                    </Modal> */}
                    <Routes>
                        {navigateToTab}
                        <Route path="/workorderdetails/:workOrderNumber" element={<WorkOrderDetail navigateToTab={navigateToTab} />} />
                    </Routes>
                    {!lrNumber&&(<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Create LR</span>)}
                    {lrNumber&&(<span style={{ fontSize: '18px', fontWeight: 'bold' }}>Update LR</span>)}
                </Col>
                {!lrNumber && ( 
                // <Col span={12} style={{ marginLeft: "auto" }}>
                //     {/* Remove "marginLeft: 'auto'" from the style object */}
                //     <Button style={{ color: 'white', background: '#1677FF', marginLeft: "525px" }} onClick={saveLaborRequisitionData}>Save</Button>
                // </Col>
                <Col span={12} style={{ textAlign: 'right' }} >
                    <Button style={{marginBottom: 10, color: 'white', backgroundColor: '#1677FF'}} onClick={saveLaborRequisitionData}>Save</Button>
                </Col>
                )}
                {lrNumber && (
                // <Col span={12} style={{ marginLeft: "auto" }}>
                //     {/* Remove "marginLeft: 'auto'" from the style object */}
                //     <Button style={{ color: 'white', background: '#1677FF', marginLeft: "525px" }} onClick={updateLaborRequisitionData}>Update</Button>
                // </Col>
                <Col span={12} style={{ textAlign: 'right' }} >
                    <Button style={{marginBottom: 10, color: 'white', backgroundColor: '#1677FF'}} onClick={updateLaborRequisitionData}>Update</Button>
                </Col>
                )}
            </Row>


            <Content style={{ margin: '5px', padding: '10px' }}>
                <Form 
                form={form} 
                layout="vertical" 
                onFinish={onFinish}
                initialValues={{
                    validFrom: dataDetail.validFrom ? moment(dataDetail.validFrom, 'DD-MM-YYYY') : null,
                    validTo: dataDetail.validTo ? moment(dataDetail.validTo, 'DD-MM-YYYY') : null,
                  }}
                >
                    {/* <Row gutter={16}> */}
                    <Row gutter={16} style={{ marginBottom: 16 }} justify="space-between" align="middle">
                        <Col span={5}>
                            <div style={{ background: '#F4F6FF', padding: '8px', borderRadius: '8px' }}>
                                <Form.Item label={<span style={{ fontWeight: 'bold' }}>LR Number</span>} className="custom-form-item">
                                    {/* <span>{(() => {
                                        let num = ""; // Declare num outside of the map function
                                        return lrDetailsDataSource.map((ele) => {
                                        if (num !== ele.lrNumber) {
                                            num = ele.lrNumber;
                                            return num;
                                        }
                                        return null; // Return null if num is the same as ele.lrNumber
                                        });
                                    })()}</span> */}
                                    {lrData.lrNumber}
                                    
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={9}>
                            <div style={{ background: '#F4F6FF', padding: '8px', borderRadius: '8px' }}>
                                <Form.Item label={<span style={{ fontWeight: 'bold' }}>Job Description</span>} className="custom-form-item">
                                    <span>
                                        {/* {(() => {
                                            let job = ""; // Declare num outside of the map function
                                            return lrDetailsDataSource.map((ele) => {
                                            if (job !== ele.jobName) {
                                                job = ele.jobName;
                                                return job;
                                            }
                                            return null; // Return null if num is the same as ele.lrNumber
                                            });
                                        })()} */}
                                        {lrNumber? lrData.jobName : dataDetail.jobDescription}
                                        {/* {dataDetail.jobDescription} */}
                                        </span>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div style={{ background: '#F4F6FF', padding: '8px', borderRadius: '8px' }}>
                                <Form.Item label={<span style={{ fontWeight: 'bold' }}>Work Order Type</span>} className="custom-form-item">
                                    <span>{lrNumber?lrData.workOrderType:dataDetail.workOrderType}</span>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={5}>
                            <div style={{ background: '#F4F6FF', padding: '8px', borderRadius: '8px' }}>
                                <Form.Item label={<span style={{ fontWeight: 'bold' }}>Status</span>} className="custom-form-item">
                                    {/* <span>{(() => {
                                            let status = ""; // Declare num outside of the map function
                                            return lrDetailsDataSource.map((ele) => {
                                            if (status !== ele.status) {
                                                status = ele.status;
                                                return status;
                                            }
                                            return null; // Return null if num is the same as ele.lrNumber
                                            });
                                        })()}</span> */}
                                        {lrData.status}
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                    <Title level={5} style={{ marginTop: '20px' }}>
                        Job Order Details
                    </Title>
                    <Table
                        // dataSource={jobOrderDataSource}setJobOrderDataSource
                        
                        dataSource={lrNumber?lrDetailsDataSource:data}
                        // dataSource={lrNumber?lrDetailsDataSource:jobOrderDataSource}
                        columns={columns}
                        pagination={false}
                        style={{ marginBottom: '20px' }}
                        bordered
                    />
                    <div style={{ background: '#F4F6FF', padding: '12px', borderRadius: '8px' }}>
                        <Title level={5} style={{ marginTop: '8px' }}>
                            Basic Details
                        </Title>
                    </div><br/>
                    <Space size="large">
                        {/* <Row gutter={16}> */}
                        <Row gutter={16} style={{ marginBottom: 16 }} justify="space-between" align="middle">
                            <Col span={12}>
                            {/* <Tooltip  title={`${dataDetail.validFrom} to ${dataDetail.validTo}`}> */}
                            <Tooltip title={`Validity should be within ${moment(dataDetail.validFrom).format('DD-MM-YYYY')} to ${moment(dataDetail.validTo).format('DD-MM-YYYY')}`}>
                                <Form.Item 
                                    label="LR Validity From" 
                                    name="validFrom"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please select the start date',
                                        },
                                    ]}
                                    className="custom-form-item"
                                >
                                
                                    <DatePicker 
                                    format="DD-MM-YYYY" size='large' style={{ width: '100%' }}
                                    // onChange={(date, dateString) => setValidFrom(dateString)}
                                    // value={validFrom ? moment(validFrom, 'DD-MM-YYYY') : null}
                                    disabledDate={disabledDate}
                                    disabled={data[0]?.remainingQty <= 0}
                                    // value={dataDetail.validFrom ? moment(dataDetail.validFrom, 'DD-MM-YYYY') : null}
                                    className="custom-form-item"
                                    />
                                </Form.Item>
                            </Tooltip>
                            </Col>
                            <Col span={12}>
                            <Tooltip title={`Validity should be within ${moment(dataDetail.validFrom).format('DD-MM-YYYY')} to ${moment(dataDetail.validTo).format('DD-MM-YYYY')}`}>
                                <Form.Item 
                                    label="LR Validity To" 
                                    name="validTo"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please select the end date',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                              if (!value || getFieldValue('validFrom') <= value) {
                                                return Promise.resolve();
                                              }
                                              return Promise.reject(new Error('End date must be after start date'));
                                            },
                                          }),
                                    ]}
                                    className="custom-form-item"
                                >
                                <DatePicker 
                                format="DD-MM-YYYY" size='large' style={{ width: '100%' }}
                                // onChange={(date, dateString) => setValidTo(dateString)}
                                // value={validTo ? moment(validTo, 'DD-MM-YYYY') : null} 
                                disabledDate={disabledDate}
                                disabled={data[0]?.remainingQty <= 0}
                                // value={dataDetail.validTo ? moment(dataDetail.validTo, 'YYYY-MM-DD') : null}
                                // value={moment(dataDetail.validTo, 'YYYY-MM-DD')} 
                                // value={dataDetail.validTo ? moment(dataDetail.validTo, 'DD-MM-YYYY') : null}
                                // defaultPickerValue={dataDetail.validTo ? moment(dataDetail.validTo, 'DD-MM-YYYY') : moment(dataDetail.validFrom, 'DD-MM-YYYY')}
                                />                    
                                </Form.Item>
                            </Tooltip>
                            </Col>
                            {/* <Col span={6}>
                                <Form.Item name="offDaysPerWeek" label="Off days per week">
                                    <Input size='large' disabled={(data[0]?.remainingQty === 0)}/>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={6}>
                                <Form.Item name="remark" label="Remarks">
                                    <Input size='large' disabled={(data[0]?.remainingQty === 0)}/>
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Space>
                    <Title level={5} style={{ marginTop: '20px' }}>
                        LR Details
                    </Title>
                    <Table
                        dataSource={lrDetailsDataSource}
                        // dataSource={jobOrderDataSource}
                        columns={lrDetailsColumns}
                        rowKey="key"
                        pagination={false}
                        bordered
                        style={{ marginTop: '16px' }}
                    />
                    <Button
                        type="dashed"
                        onClick={handleAdd}
                        style={{ width: '100%', marginTop: '20px' }}
                        icon={<PlusOutlined />}
                        // disabled={(lrDetailsDataSource.length >= allJobData.length) || (data[0]?.remainingQty === 0)}
                        disabled={isAddButtonDisabled()}
                    >
                        Add Job
                    </Button><br/>

                    {lrData.status === "Rejected" &&(
                    <>
                        <Title level={5} style={{ marginTop: '20px' }}>
                         Remarks
                        </Title>
                        {/* <Row gutter={16}> */}
                        <Row gutter={16} style={{ marginBottom: 16 }} justify="space-between" align="middle">
                            <Col span={24}>
                            <Form.Item name="rejectionRemark" >
                                <Input.TextArea rows={2} style={{color:"gray"}} disabled/>
                            </Form.Item>
                            </Col>
                        </Row>
                    </>
                    )}
                </Form>
            </Content>
        </Layout>
    );
};

export default LaborRequisitionDetail;
