// import Axios from './axios';
import axios from '../axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */
export const createWorkOrderDetailData = (payload) => {
    console.log("payload", payload)
  return axios.post('/workOrderDetails', {workOrderDetailsData:payload});
};

export const getWorkOrderDetailData = () => {
  return axios.get('/workOrderDetails');
};

export const updateWorkOrderDetailDataById = async(id) => {
//   return axios.patch('/workOrderDetails/:id');
  try {
        const response = await axios.patch(`/workOrderDetails/${id}`);
        return response.data; // Assuming your data is in response.data
    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};


export const updateWorkOrderDetailDataByWorkOrderNumber = async(workOrderNumber, data) => {
//   return axios.patch('/workOrderDetails/:id');
    // try {
    //     const response = await axios.patch(`/workOrderDetails/workOrderNumber/${workOrderNumber}`, [data]);
    //     return response.data; // Assuming your data is in response.data
    // } catch (error) {
    //     throw new Error(`Error fetching work order details data: ${error.message}`);
    // }
    try {
        // Ensure data is an array
        console.log("workOrderNumber:", workOrderNumber);
        console.log("data:", data);

        const dataArray = Array.isArray(data) ? data : {workOrderDetailsData:[data]};

        console.log("dataArray:", dataArray);

        const response = await axios.patch(`/workOrderDetails/workOrderNumber/${workOrderNumber}`, dataArray);
        return response.data; // Assuming your data is in response.data
    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};

export const deleteWorkOrderDetailData = () => {
    return axios.delete('/workOrderDetails/:id');
};

export const fetchWorkOrderDetailData = async(workOrderNumber) => {
    try {
        const response = await axios.get(`/workOrderDetails/${workOrderNumber}`);
        console.log("response", response);
        return response.data; // Assuming your data is in response.data

    } catch (error) {
        throw new Error(`Error fetching work order details data: ${error.message}`);
    }
};

// export const uploadWorkOrderDetailData = async (payload) => {
//     console.log("payload", payload);
//     try {
//         const formData = new FormData();
//         formData.append('file', payload); // Make sure 'payload' is the file object

//         // Log the FormData object to check its contents
//         console.log("FormData:", formData);

//         const response = await axios.post(`/workOrderDetails/upload`, formData);
        
//         // Check if response.data is defined before accessing it
//         if (response.data) {
//             return response.data; // Assuming your data is in response.data
//         } else {
//             throw new Error("Response data is undefined");
//         }
//     } catch (error) {
//         // Handle errors appropriately
//         throw new Error(`Error uploading work order details data: ${error.message}`);
//     }
// };

export const uploadWorkOrderDetailData = async (payload) => {
    try {
        const response = await axios.post(`/workOrderDetails/upload`, payload);
        
        // Check if response is defined and has data property before accessing it
        if (response && response.data) {
            return response.data; // Assuming your data is in response.data
        } else {
            throw new Error("Invalid response received from server");
        }
    } catch (error) {
        // Handle errors appropriately
        throw new Error(`Error uploading work order details data: ${error.message}`);
    }
};

export const getExcelTemplateData = () => {
    return axios.get('/workOrderDetails/excelTemplate');
    // return axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};

export const getDownloadExcel = () => {
    // return axios.get('/workOrderDetails/excelTemplate');
    return axios.get('/downloads/workorder_template.xlsx', {responseType: 'blob'});
};

export const getSearch = () => {
    return axios.get('/workOrderDetails/search');
}

