// import Axios from './axios';
import axios from '../axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */

export const getShiftPatternFromWfdData = () => {
  return axios.get('/getShiftPatternFromDB');
};


export const updateSchedule = (payload, selectedJob) => {
  console.log("selectedJob", selectedJob);
  return axios.post('/updateScheduleToWfd', payload, selectedJob);
};

export const getShiftPatternDetail = (name) => {
  console.log("name", name);
  return axios.get(`/getShiftPatternDetail?name=${name}`);
};

export const findByLrNumberData = async(lrNumber) => {
  console.log("name", lrNumber);
  return await axios.post(`/labour-requisition/schdeule-lrNumberData/${lrNumber}`);
};

// export const getStatusScheduleDatas = (validFrom, validTo) =>{
//   console.log("name", validFrom, validTo);
//   return axios.get('/getStatusScheduleData', validFrom, validTo);
// }

export const getStatusScheduleDatas = (validFrom, validTo) => {
  console.log("Sending validFrom and validTo:", validFrom, validTo);
  return axios.get('/getStatusScheduleData', {
    params: { validFrom, validTo }
  });
}

export const getShiftPatternFromWfd = () => {
  // console.log("Sending validFrom and validTo:", validFrom, validTo);
  return axios.post('/getShiftPatternFromWfd');
}




