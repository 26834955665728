import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

// export const jobOrderDetailsColumn = [
export const jobOrderDetailsColumn = (isLRPage, lrNumber) => {
    // const { workOrderNumber, lrNumber } = useParams();
    const columns = [
        {
            title: 'Job',
            dataIndex: 'sVCLnItemName',
            key: 'sVCLnItemName',
        //   render: (text, record) => (
        //     <Link 
        //     to={`/workorderDetails/${record.workOrderNumber}`}
        //     style={{ textDecoration: 'none', color: 'inherit' }}
        //     >
        //     {record.workOrderNumber}
        //     </Link>
        //   ),
        },
        // {
        //     title: 'Item Number',
        //     dataIndex: 'itemNum',
        //     key: 'itemNum',
        
        // },
        // {
        //     title: 'Service Line Item Number',
        //     dataIndex: 'sVCLnItemNum',
        //     key: 'sVCLnItemNum',
        // },

        {
            title: 'Service Code',
            dataIndex: 'sVCNum',
            key: 'sVCNum'
        },
        {
            title: 'Trade',
            dataIndex: 'trade',
            key: 'trade'
        },
        {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skill'
        },
        {
            title: 'Rate',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Item Qty',
            dataIndex: 'qty',
            key: 'qty',
        },
        // {
        //     title: 'Service entry Qty',
        //     dataIndex: 'serviceEntryQty',
        //     key: 'serviceEntryQty',
        // },
        ...(isLRPage
            ? [
                {
                    title: 'Remaining Qty',
                    dataIndex: 'remainingQty',
                    key: 'remainingQty',
                },
            ]
            : []),
        {
            title: 'WBS Element',
            dataIndex: 'wbsElement',
            key: 'wbsElement',
        },
        {
            title: 'UOM',
            dataIndex: 'uom',
            key: 'uom',
        },
    ];

    // Conditionally remove 'Remaining Qty' column if not on LR page
    // if (!isLRPage) {
    //     return columns.filter(column => column.key !== 'remainingQty');
    // }

    return columns;
}
