import { createLaborRequisitionDetailData, getLaborRequisitionDetailData, fetchLaborRequisitionDetailData, fetchLrLaborRequisitionDetailData, updateLrDetailDataByLrNumber, updateOrgPathAndEffectDate, lrSearch} from '../../api/laborRequisitionsdetails';

export const createLaborRequisitionDetailsData = async (data) => {

  console.log("tested", data);
  const response = await createLaborRequisitionDetailData(data);
  console.log('value of post data', response);
  //   getWageHistory();
};

export const getLaborRequisitionDetailsData = async () => {
  //Get Data
  try{
      const laborRequisitionData = await getLaborRequisitionDetailData();
      console.log("workOrderData?.data", laborRequisitionData);
      return laborRequisitionData?.data?.data;
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};

export const getLrSearchData = async () => {
  //Get Data
  try{
      const lrSearchData = await lrSearch();
      console.log("lrSearchData", lrSearchData);
      return lrSearchData;
    } catch (error) {
      console.error('Error fetching work order details data:', error);
      return []; // Return an empty array in case of an error
    }
};

export const fetchLaborRequisitionDetailsData = async(data)=>{
  console.log("data", data);
    try {
        console.log("data", data);
        // Call the function to fetch work order details data
        const response = await fetchLaborRequisitionDetailData(data);
        console.log("response", response)
        // Directly return the response data
        return response.data;
      } catch (error) {
        throw new Error('Error fetching work order details data: ' + error.message);
      }
}

export const fetchLrLaborRequisitionDetailsData = async(data)=>{
  console.log("data", data);
    try {
        console.log("data", data);
        // Call the function to fetch work order details data
        const response = await fetchLrLaborRequisitionDetailData(data);
        console.log("response", response)
        // Directly return the response data
        return response;
      } catch (error) {
        throw new Error('Error fetching work order details data: ' + error.message);
      }
}

export const updateLrDetailsDataByLrNumber = async (lrNumber, data) => {
  try {
      if (!data) {
          throw new Error('Data parameter is undefined');
      }
      console.log("data", data);
      
      const response = await updateLrDetailDataByLrNumber(lrNumber, data);
      console.log("response", response);
      return response;
  } catch (error) {
      console.error("Error fetching work order details data:", error);
      throw new Error('Error fetching work order details data: ' + error.message);
  }
};

export const updateOrgPathAndEffectDateData = async (data) => {
  try {
      console.log("data3333333", data);
      // Call the function to fetch work order details data
      const response = await updateOrgPathAndEffectDate(data);
      console.log("response333333333", response);
      return response;
  } catch (error) {
      console.error('Error in updateLrDetailDataByLrNumber:', error);
      throw new Error(`Error updating labor requisition data: ${error.message}`);
  }
};
