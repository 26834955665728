import React, { useEffect, useState } from 'react';
import {
  Modal,
  Switch,
  Table,
  Space,
  Typography,
  DatePicker,
  Row,
  Col,
} from 'antd';
import dayjs from 'dayjs';
import { verifyToken } from '../utils/jwt';

const LicenceManagementModal = ({ open, onCancel, onOk, token }) => {
  const formRef = React.useRef();
  const [features, setFeatures] = useState([]);
  const [expiry, setExpiry] = useState('');
  const [isDatePickerValid, setIsDatePickerValid] = useState(true);

  const _onCancel = () => {
    formRef.current?.resetFields();
    // setFeatures([]);
    onCancel();
  };

  useEffect(() => {
    updateData(token);
  }, [token]);

  const updateData = async (token) => {
    const decoded = await verifyToken(token?.productLicenseKey);

    if (decoded) {
      setFeatures(decoded.features);
      setExpiry(decoded.expiry);
    } else {
      setFeatures([]);
      setExpiry(false);
    }
  };

  const dataSource = [
    {
      key: '1',
      name: 'Contractors',
      value: 'contractors',
    },
    {
      key: '2',
      name: 'Org Setup',
      value: 'orgsetup',
    },
    {
      key: '3',
      name: 'Compliance',
      value: 'compliance',
    },
    {
      key: '4',
      name: 'Master Setup',
      value: 'mastersetup',
    },
    {
      key: '5',
      name: 'Audit',
      value: 'audit',
    },
    {
      key: '6',
      name: 'User Management',
      value: 'usermanagement',
    },
    {
      key: '7',
      name: 'Onboard Workmen',
      value: 'onboardworkmen',
    },
    {
      key: '8',
      name: 'Workmen Dashboard',
      value: 'workmendashboard',
    },
    {
      key: '9',
      name: 'Quick Employee Onboarding',
      value: 'quickemployeeonboarding',
    },
    {
      key: '10',
      name: 'Bulk Upload',
      value: 'bulkupload',
    },
    {
      key: '11',
      name: 'Incident Report',
      value: 'incidentreport',
    },
    {
      key: '12',
      name: 'Workmen Approval',
      value: 'workmenapproval',
    },
    {
      key: '13',
      name: 'Report Incident',
      value: 'reportincident',
    },
    {
      key: '14',
      name: 'Historical Records',
      value: 'historicalrecords',
    },
    {
      key: '15',
      name: 'Contractor Approval',
      value: 'contractorapproval',
    },
    {
      key: '16',
      name: 'Reports For Contractor Workmen',
      value: 'reportsforcontractorworkmen',
    },
    {
      key: '17',
      name: 'Onboard Regular Workmen',
      value: 'onboardregularworkmen',
    },
    {
      key: '14',
      name: 'Regular Workmen Dashboard',
      value: 'regularworkmendashboard',
    },
    {
      key: '15',
      name: 'Historical Data',
      value: 'historicaldata',
    },
    {
      key: '16',
      name: 'Reports For Regular Workmen',
      value: 'reportsforregularworkmen',
    },
    {
      key: '17',
      name: 'IR Dashboard',
      value: 'irdashboard',
    },
    {
      key: '18',
      name: 'Contractor Dashboard',
      value: 'contractordashboard',
    },
    {
      key: '19',
      name: 'Manage Supervisor',
      value: 'managesupervisor',
    },
    {
      key: '20',
      name: 'Digital Report',
      value: 'digitalreport',
    },
    {
      key: '21',
      name: 'Work Order List',
      value: 'workorderlist',
    },
    {
      key: '22',
      name: 'LR List',
      value: 'lrlist',
    },
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'action',
      render: (_, record, _id) => {
        return (
          <Space>
            <Typography.Link>
              <Switch
                checked={features.includes(record.value)}
                onChange={(value) => {
                  let data = [...features];
                  if (value) {
                    if (!data.includes(record.value)) {
                      data.push(record.value);

                      setFeatures(data);
                    }
                  } else {
                    setFeatures(data.filter((item) => item !== record.value));
                  }
                }}
              />
            </Typography.Link>
          </Space>
        );
      },
    },
  ];
  return (
    <Modal
      title='Manage Features'
      onCancel={_onCancel}
      onOk={() => {
        if (!expiry) {
          setIsDatePickerValid(false);
          return;
        }
        setIsDatePickerValid(true);
        onOk(features, expiry);
      }}
      open={open}
      width={'50%'}>
      <Table
        columns={columns}
        dataSource={dataSource}
        // onChange={onChange}
        pagination={false}
      />

      <Row
        gutter={[16, 0]}
        style={{
          padding: '10px',
        }}>
        <Col span={8}>
          <label htmlFor='datepicker'>Valid Upto:</label>
        </Col>
        <Col span={16}>
          <DatePicker
            id='datepicker'
            style={{
              width: '80%',
              height: '40px',
              marginLeft: '20px',
              borderColor: isDatePickerValid ? '' : 'red',
            }}
            onChange={(e) => {
              setExpiry(new Date(e).getTime());
              setIsDatePickerValid(true); // Reset validation on date change
            }}
            format='DD-MM-YYYY'
            value={expiry ? dayjs(new Date(expiry)) : ''}
          />
          {!isDatePickerValid && (
            <p style={{ color: 'red' }}>Please select a date</p>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default LicenceManagementModal;
