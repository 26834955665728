import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { orgEmailValidator } from '../../validators';
import useAuth from '../../hooks/useAuth';
import axios from '../../services/axiosInstance';
import { getDetails } from '../../services/auth';

const { Option } = Select;

export default function SupervisorFormModal({
  open,
  onOk,
  onCancel,
  initialValues = {},
  checkIsValidAddition,
  isEdit = false,
  locationsData,
  roleTypeData,
}) {
  const { userConfig } = useAuth();
  const formRef = React.useRef();
  const [users, setUsers] = React.useState({});
  const [dataFetched, setDataFetched] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [filteredLocation, setFilteredLocation] = React.useState(locationsData);

  const [contractorNames , setContractorNames] = useState([]);
  const [contractorCodes , setContractorCodes] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState('')
  const [workOrder, setWorkOrder] = useState([]); // Store work order details
  const [selectedLocationId, setSelectedLocationId] = useState(null); // Store selected location
  const [supervisorNames, setSupervisorNames] = useState([]);
  const [lastNames, setLastNames] = useState([])
  const [firstNames, setFirstNames] = useState([])
  const [filteredLastNames, setFilteredLastNames] = useState([]);
  const [selectedFirstName, setSelectedFirstName] = useState(null);

  const orgId = userConfig.orgId;

  const getWorkOrderDetails = async () => {
    try {
      const response = await axios.get('/workOrderDetails');
      //console.log('GETWORK', response);
      return response.data.data;
    } catch (error) {
      console.error('Error fetching work order details:', error);
    }
  };
 
  useEffect(() => {
    getWorkOrderDetails()
  }, [])
 
  useEffect(() => {
    const contractorAgencyList = async () => {
      try {
        const workOrder = await getWorkOrderDetails();
        //console.log('workOrder', workOrder);
 
        const activeWorkOrders = workOrder.filter(detail => detail.status === "Active");
 
      // Extract contractor names and vendor codes from the filtered list
      const contractorNames = [...new Set(activeWorkOrders.map((detail) => detail.contractorName))];
     
        const contractorCodes = workOrder.map((detail) => detail.vendorCode);
        console.log("contractorNames",contractorNames)
        setContractorNames(contractorNames);
        setContractorCodes(contractorCodes);
      } catch (error) {
        console.error('Error fetching work order details:', error);
      }
    };
 
    contractorAgencyList();
   
  }, [open, initialValues]);
 
  useEffect(() => {
    const fetchWorkOrderDetails = async () => {
      try {
        const workOrderData = await getWorkOrderDetails();
       
        setWorkOrder(workOrderData); // Save work order data to state
       
        //console.log('workOrder', workOrderData);
      } catch (error) {
        console.error('Error fetching work order details:', error);
      }
     
    };
 
    fetchWorkOrderDetails();
  }, [open, initialValues]);
 
  const handleLocationChange = (value) => {
    console.log("Selected Location Name: ", value);
   
    // Set the selected location name
    setSelectedLocationId(value);
 
    // Find the matching location ID from the filteredLocation based on the selected location name
    const selectedLocation = filteredLocation?.locations?.find(
      (location) => location.id === value
    );
 
    if (!selectedLocation) {
      return;
    }
 
    const selectedLocationId = selectedLocation.id;
    console.log("Matched Location ID: ", selectedLocationId);
 
    // Log all work orders to verify data
    console.log("Work Orders: ", workOrder);
 
    // Now find all work orders that match the selected location's unitCode
    const matchedWorkOrders = workOrder.filter((workorder) => {
      console.log(
        `Comparing unitCode: ${workorder.unitCode} with locationId: ${selectedLocationId}`
      );
      return String(workorder.unitCode) === String(selectedLocationId);
    });
 
    console.log("Matched Work Orders: ", matchedWorkOrders);
 
    // Collect supervisor names from all matched work orders and avoid duplicates
    const supervisors = matchedWorkOrders
      .map((workorder) => workorder.contractorSupervisor) // Extract contractorSupervisor
      .filter(Boolean); // Remove any undefined or null values
 
    console.log("Supervisor Names Before Removing Duplicates: ", supervisors);
 
    const uniqueSupervisors = Array.from(new Set(supervisors)); // Ensure unique supervisor names
 
    console.log("Unique Supervisors: ", uniqueSupervisors);
 
    // Set supervisor names in state
    setSupervisorNames(uniqueSupervisors);
  };
 
  const handleContractorChange = (value) => {
    setSelectedContractor(value)
  }

  useEffect(() => {
    if (
      userConfig?.role == 'admin' &&
      locationsData?.locations &&
      userConfig?.location
    ) {
      const newObj = {
        locations: locationsData?.locations?.filter(
          (l) => l.id == userConfig?.location
        ),
      };
      setFilteredLocation(newObj);
    } else {
      setFilteredLocation({ locations: locationsData?.locations?.slice(1) });
    }
  }, [locationsData]);

  const validateContractorStatus = (rule, value, callback) => {
    const selectedOption = users.Response1.find(
      (option) => option.id === value
    );

    if (selectedOption) {
      const contractorStatus = selectedOption.status;

      if (contractorStatus) {
        const statusLowerCase = contractorStatus.toLowerCase();

        if (statusLowerCase === 'active' || statusLowerCase === 'rehired') {
          callback(); // Validation passed
        } else {
          callback(`Cannot proceed with status: ${contractorStatus}`);
        }
      } else {
        callback('Invalid contractor selected'); // Handle the case where the selected option is not found
      }
    }
  };

  React.useEffect(() => {
    EmployeeList();
    formRef.current?.setFieldsValue(initialValues);
  }, [open, initialValues]);

  const currentValues = formRef.current?.getFieldsValue();

  // Get current form values
  const updatedValues = {
    ...currentValues,
    roleName: currentValues?.roleName?.selectedRoleType, // Set roleName to roleName.selectedRoleType value
  };

  formRef.current?.setFieldsValue(updatedValues);

  async function EmployeeList() {
    const Response = await axios.get('/supplier');
    const Response1 = Response.data.suppliers;
    setUsers({ Response1 });
  }

  //

  const onSubmit = (value) => {
    formRef.current?.submit();
  };

  // const onFinish = (value) => {
  //   const selectedPermission = permissions.find((item) => {
  //     return item._id == value.roleName;
  //   });
  //   value.roleName = selectedPermission?.permissions;
  //   value.name = users.Response1.name;
  //
  //   if (checkIsValidAddition(value)) {
  //     onOk(value, isEdit);
  //   }
  // };

  const onFinish = (value) => {
    const roleName = value.roleName?.selectedRoleType || initialValues.roleName;

    const selectedPermission = permissions.find((item) => {
      return item._id == value.roleName;
    });

    value.roleName = {
      permissions: selectedPermission?.permissions,
      selectedRoleType: selectedPermission?.selectedRoleType,
    };

    value.name = users.Response1.name;

    if (checkIsValidAddition(value)) {
      onOk(value, isEdit, roleName); // Pass the role name here

      // selectedPermission.selectedRoleType
    }
  };

  const _onCancel = () => {
    formRef.current?.resetFields();
    onCancel();
  };

  React.useEffect(() => {
    if (!dataFetched) {
      fetchData();
    }
  }, [dataFetched]); // The empty dependency array ensures that the effect runs only once on mount

  const fetchData = () => {
    getDetails(orgId)
      .then((response) => {
        const filteredData = response?.data
          .map((item) => {
            // Check if the selectedRole is "Approver"
            if (item.selectedRole === 'Supervisor') {
              // If it is "Approver," return the modified item or just the property you need
              return {
                createdAt: item.createdAt,
                orgId: item.orgId,
                permissions: item.permissions,
                selectedRole: item.selectedRole,
                selectedRoleType: item.selectedRoleType,
                _id: item._id,
                // Add other properties as needed
              };
            }

            // If selectedRole is not "Approver," return null or an empty object
            return null;
          })
          .filter((filteredItem) => filteredItem !== null); // Remove null entries from the result

        //

        setPermissions(filteredData);
        setDataFetched(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    if (userConfig?.location || userConfig?.locationId) {
      const locations = locationsData?.locations?.filter(
        (l) =>
          l.name === userConfig?.location || l.name === userConfig?.locationId
      );
      const agencies = users?.Response1?.filter(
        (a) =>
          a.location === userConfig?.location ||
          a.location === userConfig?.locationId
      );

      setFilteredLocations(locations);
      setFilteredAgencies(agencies);
    } else {
      setFilteredLocations(locationsData?.locations?.slice(1));
      setFilteredAgencies(users?.Response1);
    }
  }, [locationsData, users]);

  useEffect(() => {
    const firsts = [];
    const lasts = [];
 
    supervisorNames.forEach((fullName) => {
      const nameParts = fullName.split(' '); // Split the full name by space
      if (nameParts.length >= 2) {
        firsts.push(nameParts[0]); // First part is the first name
        lasts.push(nameParts.slice(1).join(' ')); // Remaining parts as last name
      } else {
        firsts.push(nameParts[0]); // If only one part, treat it as first name
        lasts.push(''); // No last name
      }
    });
 
    setFirstNames(firsts);
    setLastNames(lasts);
  }, [supervisorNames]);
 
  // Handle first name selection and filter corresponding last names
  const handleFirstNameChange = (selectedFirst) => {
    setSelectedFirstName(selectedFirst);
 
    // Filter last names based on the selected first name
    const filteredLasts = supervisorNames
      .filter((fullName) => fullName.startsWith(selectedFirst)) // Check if full name starts with the selected first name
      .map((fullName) => fullName.split(' ').slice(1).join(' ')); // Get corresponding last name(s)
   
    setFilteredLastNames(filteredLasts);
  };

  return (
    <Modal
      title={isEdit ? 'Edit' : 'Add Supervisor'}
      open={open}
      onOk={onSubmit}
      onCancel={_onCancel}
      okText={isEdit ? 'Save' : 'Add '}
      autoComplete='off'>
      <Form
        name='supervisor'
        ref={formRef}
        layout='vertical'
        initialValues={initialValues}
        onFinish={onFinish}>
          
        <Form.Item label='Location' name='location'>
          {/* <Select disabled={isEdit ? true : false}>
            {filteredLocation?.locations?.map((option, index) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select> */}
          <Select
            onChange={handleLocationChange}
            value={selectedLocationId}
            placeholder="Select Location"
          >
            {/* Assuming you have filteredLocation or location data available */}
            {filteredLocation?.locations?.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: 'Enter First Name' }]}>
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Select First Name' }]}
        >
          <Select onChange={handleFirstNameChange}>
            {firstNames.map((firstName, index) => (
              <Option key={index} value={firstName}>
                {firstName}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Enter Last Name' }]}>
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Select Last Name' }]}
        >
          <Select>
            {filteredLastNames.map((lastName, index) => (
              <Option key={index} value={lastName}>
                {lastName}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item label='Role' name='role' hidden={true}>
          <Select disabled>
            <Option value='supervisor'>Supervisor</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label='Role Name'
          name='roleName'
          initialValue={initialValues?.roleName}
          rules={[{ required: true, message: 'Enter Role Name' }]}>
          <Select
            // disabled={isEdit ? true : false}
            style={{ fontWeight: 'bold', color: 'gray' }}>
            {permissions?.map((level, index) => (
              <Option key={index} value={level?._id}>
                {level?.selectedRoleType}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label='Contractor Agency'
          name='supplierId'
          rules={[
            {
              required: true,
              message: 'Please select a contractor agency',
            },
            {
              validator: validateContractorStatus,
            },
          ]}>
          <Select>
            {filteredAgencies?.map((option) => (
              <Option
                key={option.id}
                value={option.id}
                disabled={isEdit ? true : false}>
                {option.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
       
        {/* <Form.Item
          label='Unit Id'
          name='unitId'
          rules={[{ required: true, message: 'Enter Unit Id' }]}
        >
          <Input disabled={isEdit ? true : false} />
        </Form.Item> */}

        <Form.Item
          label='User Id'
          name='userid'
          rules={[{ required: true, message: 'Enter User Id' }]}
          getValueFromEvent={(e) => e.target.value.toUpperCase()}>
          <Input autoComplete='off' disabled={isEdit ? true : false} />
        </Form.Item>

        <Form.Item
          label='Password'
          name='password'
          rules={[
            { required: true, message: 'Enter Password' },
            {
              pattern: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$',
              message:
                'Must contain minimum eight characters, atleast one number and one special character',
            },
          ]}>
          <Input.Password maxLength={20} autoComplete='new-password' />
        </Form.Item>
        <Form.Item
          label='Email'
          name='emailId'
          rules={[
            { required: true, message: 'Enter Email' },
            { type: 'email', message: 'Enter valid Email' },
            {
              // validator: (_, value) => {
              //   const emailRegex =
              //     /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
              //   if (emailRegex.test(value)) {
              //     return orgEmailValidator(value, userConfig.orgId);
              //   }
              // },
            },
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
