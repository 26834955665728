import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, DatePicker, Row, Col, Upload, message, Tooltip } from 'antd';
import { Link, useParams, useNavigate} from 'react-router-dom';
// import { fetchLaborRequisitionDetailsData } from '../actions/api/laborRequisitionDetails'
import { fetchLaborRequisitionDetailsData } from '../../services/actions/api/laborRequisitionsDetails'
import { EyeOutlined } from '@ant-design/icons';
import useAuth from '../../hooks/useAuth';

const LaborRequisitionList = ({workOrderDetail}) => {
    const { userConfig } = useAuth();
    const [laborRequisitionData, setLaborRequisitionData] = useState([]);
    // const [isWorkOrder, setIsWorkOrder] = useState(sessionStorage.getItem('isWorkOrder') === 'true');
    const [isWorkOrder, setIsWorkOrder] = useState(userConfig.level==="sectionHead");
    const navigate = useNavigate();
    const { workOrderNumber } = useParams();
    console.log("workOrderDetail", workOrderDetail);

    const columns = [
        {
          title: 'LR Number',
          dataIndex: 'lrNumber',
          key: 'lrNumber',
          render: (text, record) => {
            return isWorkOrder ? (
              <Link
                to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {record.lrNumber}
              </Link>
            ) : (
              // <span>{record.lrNumber}</span>
              <Link
                to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {record.lrNumber}
              </Link>
            );
          },
        },
        {
          title: 'Job Description',
          dataIndex: 'jobName',
          key: 'jobName',
          // width: 50,
          className: 'job-description-column', // Add this line
          render: (text, record) => {
            return isWorkOrder ? (
            <Link 
            to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            {record.jobName}
            </Link>
            ) : (
              // <span>{record.jobName}</span>
              <Link
                to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {record.jobName}
              </Link>
            );
          }
          // render: (jobDetails) => (
          //   <span>
          //       {jobDetails && jobDetails.map((job, index) => {
          //         console.log("job", job);
          //         return(
          //           <div key={index}>{job.jobName}</div>
          //         )
          //   })}
          //   </span>
          // ),
        },
        {
          title: 'LR Valid From ',
          dataIndex: 'validFrom',
        //   render: (text, record) => `${record.validFrom} - ${record.validTo}`,
          key: 'validFrom',
          render: (text, record) => {
            return isWorkOrder ? (
            <Link 
            to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            {record.validFrom}
            </Link>
          ) : (
            // <span>{record.validFrom}</span>
            <Link
              to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {record.validFrom}
            </Link>
          );
        }
        },
        {
            title: 'LR Valid To ',
            dataIndex: 'validTo',
            // render: (text, record) => `${record.validFrom} - ${record.validTo}`,
            key: 'validTo',
            render: (text, record) => {
              return isWorkOrder ? (
              <Link 
              to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
              >
              {record.validTo}
              </Link>
             ) : (
              // <span>{record.validTo}</span>
              <Link
                to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {record.validTo}
              </Link>
            );
          }
          },
        {
          title: 'Contractor Name',
          dataIndex: 'contractorName',
          key: 'contractorName',
          render: (text, record) => {
            return isWorkOrder ? (
            <Link 
            to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            {record.contractorName}
            </Link>
             ) : (
              // <span>{record.contractorName}</span>
              <Link
                to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {record.contractorName}
              </Link>
            );
          }
          // render: (jobDetails) => (
          //   <span>
          //       {jobDetails && jobDetails.map((job, index) => (
          //           <div key={index}>{job.contractorName}</div>
          //       ))}
          //   </span>
          // ),
        },
        {
          title: 'Unit Name',
          dataIndex: 'unitCode',
          key: 'unitCode',
          render: (text, record) => {
            return isWorkOrder ? (
            <Link 
            to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            {record.unitCode}
            </Link>
           ) : (
            // <span>{record.unitCode}</span>
            <Link
              to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {record.unitCode}
            </Link>
          );
        }
          // render: (jobDetails) => (
          //   <span>
          //       {jobDetails && jobDetails.map((job, index) => (
          //           <div key={index}>{job.unitCode}</div>
          //       ))}
          //   </span>
          // ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (text, record) => {
            return isWorkOrder ? (
            <Link 
            to={`/home/department/sectionhead/workorderlist/laborRequisitionDetail/lr/${record.lrNumber}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
            >
            {record.status}
            </Link>
          ) : (
            // <span>{record.status}</span>
            <Link
              to={`/home/department/departmenthead/lrlist/laborRequisitionList/lr/${record.lrNumber}`}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              {record.status}
            </Link>
          );
        }
        },
      ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchLaborRequisitionDetailsData(workOrderNumber);
                console.log("data", data);
                if (data) {
                    // setLaborRequisitionData(data);
                    // Filter out duplicates based on lrNumber
                    const uniqueData = data.filter((item, index, self) =>
                      index === self.findIndex((t) => (
                          t.lrNumber === item.lrNumber
                      ))
                  );
                  setLaborRequisitionData(uniqueData);
                } else {
                    console.error('No data found for work order number:', workOrderNumber);
                }
            } catch (error) {
                console.error('Error fetching work order details data:', error);
                // message.error('Failed to fetch labor requisition data.');
            }
        };
        fetchData();
    }, [workOrderNumber]);

    const handleView = (record) => {
        console.log('Viewing record:', record);
        // Implement the desired functionality for viewing the record here
        // For example, navigate to a detailed view page:
        navigate(`/laborRequisitionDetail/lr/${record.lrNumber}`);
    };
        
    const crateLaborRequisition = () => {
        console.log("tetsed", workOrderDetail.workOrderDetailsData[0]?.status);
        navigate(`/home/department/sectionhead/workorderlist/laborRequisitionDetail/workOrder/${workOrderNumber}`)
    }
      
    return(
        <div>
            <Row gutter={16} style={{ marginBottom: 16 }} justify="space-between" align="middle">
                <Col span={6}>
                    <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Labor Requisitions</span>
                </Col>
                {
                (workOrderDetail?.workOrderDetailsData[0]?.status === "Active" && userConfig.level === "sectionHead") &&
                // ? 
                (
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Tooltip title="Active">
                            <Button style={{ marginBottom: 10, color: '#1677FF', borderColor: '#1677FF' }} onClick={crateLaborRequisition}>Create LR</Button>
                        </Tooltip>
                    </Col>
                ) 
                // : 
                // (
                //     <Col span={12} style={{ textAlign: 'right' }}>
                //         <Tooltip title={workOrderDetail?.workOrderDetailsData[0]?.status}>
                //             <Button style={{ marginBottom: 10, color: 'gray', borderColor: 'gray' }} onClick={crateLaborRequisition} disabled>Create LR</Button>
                //         </Tooltip>
                //     </Col>
                // )
                }
            </Row>
            <Table columns={columns} dataSource={laborRequisitionData} />
        </div>
    )
}

export default LaborRequisitionList;
