import React, { useEffect, useState, useRef } from 'react';
import { Form, Select, DatePicker, Table, Button, Row, Col, Card, Typography, Divider, Tooltip, Menu, Checkbox, Dropdown, Modal, Input, message, Spin} from 'antd';
import { LeftOutlined, FilterOutlined, FilterFilled } from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { lrOrderDetailsColumn } from "../constant/lrOrderList";
import { InfoCircleOutlined } from '@ant-design/icons';
import { fetchLrLaborRequisitionDetailsData, fetchLaborRequisitionDetailsData, updateOrgPathAndEffectDateData } from "../../services/actions/api/laborRequisitionsDetails";
import { getShiftPatternFromWfdDatas, updateScheduleOfPersonData, getShiftPatternDetails, getFindByLrNumberData, getStatusScheduleData } from "../../services/actions/api/workmenSchedulerService";
import { getPersonDetailsData, updatePersonDataDetails } from "../../services/actions/api/personDetails";
import ShiftPatternCell from './ShiftPatternCell'
import moment from 'moment';
import approve from '../../assets/images/Approved.png';
import reject from '../../assets/images/Reject.png';
// import './WorkOrderDetails.css';
import '../../App.css';
import useAuth from '../../hooks/useAuth';


const { Option } = Select;
const { Text, Title } = Typography;

const WorkmenScheduler = () => {
  const { userConfig } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { lrNumber, workOrderNumber } = useParams();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedExceptionKeys, setSelectedExceptionKeys] = useState([]);
  const column = lrOrderDetailsColumn();
  const [jobOptions, setJobOptions] = useState([]);
  const [shiftPattern, setShiftPattern] = useState('');
  const [statusFilters, setStatusFilters] = useState({
    all: false,
    notScheduled: false,
    scheduled: false,
    partiallyScheduled: false,
    fullyScheduled: false,
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');

  const [selectedLR, setSelectedLR] = useState(null);
  const [validFrom, setValidFrom] = useState(null);
  const [validTo, setValidTo] = useState(null);
  const [departmentName, setDepartmentName] = useState('');
  const [workOrderType, setWorkOrderType] = useState('');
  const [jobName, setJobName] = useState('');
  const [modalWidth, setModalWidth] = useState(500);
  const [filteredShiftPatternData, setFilteredShiftPatternData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedJob, setSelectedJob] = useState("");
  const [exceptionWorkmens, setExceptionWorkmens] = useState([]);
  const [isJobEnabled, setIsJobEnabled] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [personDetailData, setPersonDetailData] = useState([])
  const [shiftPatternDetail, setShiftPatternDetail] = useState([])
  const [selectedLrData, setSelectedLrData] = useState([])
  const [selectedJobDetail, setSelectedJobDetail] = useState([])
  const [schedulValidFrom, setSchedulValidFrom] = useState("")
  const [schedulValidTo, setSchedulValidTo] = useState("")
  const [orgPath, setOrgPath] = useState("")
  const [shiftPatternItemsDay, setShiftPatternItemsDay] = useState(0)
  const [statusSchedule, setStatusSchedule] = useState("")
  

  const handleStatusFilterChange = ( {key} ) => {
    if (key === 'all') {
      setStatusFilters({
        all: true,
        notScheduled: false,
        scheduled: false,
        partiallyScheduled: false,
        fullyScheduled: false,
      });
    } else {
      setStatusFilters(prevFilters => ({
        ...prevFilters,
        all: false,
        [key]: !prevFilters[key],
      }));
    }
  };

  useEffect(() => {
    console.log("usecofig", userConfig);
    const fetchData = async () => {
        try {
            const data = await fetchLaborRequisitionDetailsData(workOrderNumber);
            console.log("data", data);
            if (data) {
              // First filter the data where status is "Approved"
              const approvedData = data.filter(item => item.status === "Approved");

              // Then filter out duplicates based on lrNumber
              const uniqueApprovedData = approvedData.filter((item, index, self) =>
                index === self.findIndex(t => t.lrNumber === item.lrNumber)
              );
              setFilteredData(uniqueApprovedData);
               // Set default selected LR number if not already set
              if (!selectedLR && uniqueApprovedData.length > 0) {
                setSelectedLR(uniqueApprovedData[0].lrNumber);
              }
            } else {
                console.error('No data found for work order number:', workOrderNumber);
            }
        } catch (error) {
            console.error('Error fetching work order details data:', error);
            // message.error('Failed to fetch labor requisition data.');
        }
    };
    fetchData();
  }, [workOrderNumber]);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const data = await getShiftPatternFromWfdDatas();
            if (data) {
                // setLaborRequisitionData(data);
                // Filter out duplicates based on lrNumber
                console.log("data", data);
                const uniqueData = data.filter((item, index, self) =>
                  index === self.findIndex((t) => (
                      t.name === "$M-Manufacturing"
                  ))
              );
              console.log("uniqueData", uniqueData);
              setFilteredShiftPatternData(uniqueData);
              //  // Set default selected LR number if not already set
              // if (!selectedLR && uniqueData.length > 0) {
              //   setSelectedLR(uniqueData[0].lrNumber);
              // }
            } else {
                console.error('No data found for Shift Pattern:');
            }
        } catch (error) {
            console.error('Error fetching work order details data:', error);
            // message.error('Failed to fetch labor requisition data.');
        }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedLR) {
      handleLRChange(selectedLR);
    }
  }, [selectedLR]);

  const handleLRChange = async (value) => {
    try {
      const data = await fetchLrLaborRequisitionDetailsData(value);
      console.log("Selected LR Data:", data);
      setSelectedLrData(data)     
      if (data) {
        // Map jobDetails to include validFrom and validTo
        
        const jobDetailsWithValidity = (data.jobDetails || []).map(item => ({
          ...item,
          validFrom: data.validFrom,
          validTo: data.validTo,
          shiftInformation: item.shiftInformation.slice(-1),
        }));
        console.log("jobDetailsWithValidity", jobDetailsWithValidity);
        setTableData(jobDetailsWithValidity);

        setSelectedLR(value);
        setValidFrom(moment(data.validFrom, 'DD-MM-YYYY'));
        setValidTo(moment(data.validTo, 'DD-MM-YYYY'));
        setDepartmentName(data.departmentName);
        setWorkOrderType(data.workOrderType);
        setJobName(data.jobDetails[0]?.sVCLnItemName || '');

        const options = data.jobDetails.map(item => ({
          value: item.sVCLnItemName,
          label: item.sVCLnItemName,
        }));
        setJobOptions(options);

        form.setFieldsValue({
          // validFrom: data.validFrom ? moment(data.validFrom, 'DD-MM-YYYY') : null,
          // validTo: data.validTo ? moment(data.validTo, 'DD-MM-YYYY') : null,
          job: data.jobDetails[0]?.sVCLnItemName || '',
        });
      }
      fetchFindByLrNumberData(value);
    } catch (error) {
      console.error('Error fetching LR details:', error);
    }
  };

  const fetchFindByLrNumberData = async(value) => {
    console.log("selectedLR", value);
    await getFindByLrNumberData(value);
  }


  const allSchedulingDataPayload = {
    where: {
      excludeBreaks: false,
      locations : {
        locationRefs: {
          
          qualifiers: [
            "UCo/Eastwood - Canada East/Administration Division/Canada/Canada HQ/Human Resources/CABA"
          ]
        },
        includeEmployeeTransfer: false,
        startDate:validFrom,
        endDate:validTo
      }
    }
  }

  const filteredDataSource = data.filter(item => {
    console.log("item", item, data);
    // if (statusFilters.all) return true;
    if (statusFilters.all) return true; // Show all data if 'All' is selected
    if (!statusFilters.notScheduled && !statusFilters.scheduled && !statusFilters.partiallyScheduled && !statusFilters.fullyScheduled) {
      return true; // Show all data if no filters are selected
    }
    if (statusFilters.notScheduled && item.status === 'Not Scheduled') return true;
    if (statusFilters.fullyScheduled && item.status === 'Fully Scheduled') return true;
    if (statusFilters.scheduled && item.status === 'Scheduled') return true;
    if (statusFilters.partiallyScheduled && item.status === 'Partially Scheduled') return true;
    return false;
  });

  const isFilterActive = Object.values(statusFilters).some(value => value) && !statusFilters.all;

  const fetchData = async (selectedJob) => {
    setLoading(true); // Start showing the loading spinner
    try{
    console.log("yyyyyyy");
    console.log("Fetching data with111:", schedulValidFrom, schedulValidTo);

    await fetchFindByLrNumberData(selectedLR)

    // Fetch person data from your API
    const response = await getPersonDetailsData(); 
    console.log("Person data response:", response);
    console.log("Selected job:", selectedJob);

    const jobDetails = tableData.find(job => job.sVCLnItemName === selectedJob);
    console.log("Job details found:", jobDetails);
    setSelectedJobDetail(jobDetails);

    if (jobDetails) {
      console.log("jobDetails", jobDetails);
      
        const { trade, skill } = jobDetails;
        
        // const filteredData = response.filter(person =>
        //     // person.jobDetails.designation === trade && person.jobDetails.skillCode === skill
        //     // Check if the status is "approved", "rehired", or "Active"
        //     (person.status === "approved" || person.status === "rehired" || person.status === "Active") &&
        //     // Check if empCode is present
        //     person.personalDetails.empCode &&
        //     // Check if trade and skill match
        //     person.jobDetails.designation === trade &&
        //     person.jobDetails.skillCode === skill
        // );
        const filteredData = response.filter(person => {
          const isContractor = person.employementType === "Contractor";
          console.log("isContractor", isContractor);
          const hasValidStatus = ["approved", "rehired", "Active"].includes(person.status);
          console.log("hasValidStatus", hasValidStatus);
          // const hasEmpCode = person.personalDetails.empCode && person.personalDetails.empCode.trim() !== '';
          const hasEmpCode = person.personalDetails.empCode;
          console.log("hasEmpCode", hasEmpCode);
          
          const hasValidTrade = person.jobDetails.designation === trade;
          console.log("hasValidTrade", hasValidTrade);
          const hasValidSkill = person.jobDetails.skillCode === skill;
          console.log("hasValidSkill", hasValidSkill);
      
          return isContractor && hasValidStatus && hasEmpCode && hasValidTrade && hasValidSkill;
        });

        setPersonDetailData(filteredData);
        console.log("Filtered data:", filteredData);

        console.log("Fetching data with221:", schedulValidFrom, schedulValidTo);
        const statusScheduleData = await getStatusScheduleData(schedulValidFrom, schedulValidTo);
        console.log("Status schedule data:", statusScheduleData);

        const transformedData = filteredData.map(person => {
            let status = "Not Scheduled";
            console.log("shiftPattern", shiftPattern);

            statusScheduleData.data.forEach(element => {
                if (person.personalDetails.empCode === element.empDetails.empCode) {
                    const formatStartDate = moment(element.minStartDateTime).utc();
                    const formatEndDate = moment(element.maxEndDateTime).utc();
                    const schedulStartDate = moment(schedulValidFrom).utc();
                    const schedulEndDate = moment(schedulValidTo).utc();

                    console.log("formatStartDate", formatStartDate.format('YYYY-MM-DD'));
                    console.log("formatEndDate", formatEndDate.format('YYYY-MM-DD'));
                    console.log("schedulStartDate", schedulStartDate.format('YYYY-MM-DD'));
                    console.log("schedulEndDate", schedulEndDate.format('YYYY-MM-DD'));
                    console.log("-----111",schedulStartDate.isSame(formatStartDate, 'day'));
                    console.log("----555", schedulEndDate.isSame(formatEndDate, 'day'));

                    if (
                        schedulStartDate.isSame(formatStartDate, 'day') && 
                        schedulEndDate.isSame(formatEndDate, 'day')
                    ) {
                        status = "Fully Scheduled";
                        console.log("Fully Scheduled for:", person.personalDetails.empCode);
                        return true
                    }else if (
                      (schedulStartDate.isBefore(formatEndDate, 'day') &&
                        schedulEndDate.isAfter(formatStartDate, 'day')) ||
                      (formatStartDate.isSame(formatEndDate, 'day') &&
                        (formatStartDate.isSame(schedulStartDate, 'day') ||
                          formatEndDate.isSame(schedulEndDate, 'day')))
                    ) {
                      status = "Partially Scheduled";
                      console.log("Partially scheduled for:", person.personalDetails.empCode);
                  }
                }
                return false
            });
            console.log("statusrrr", status);

            return {
                firstName: person.personalDetails.firstName,
                lastName: person.personalDetails.lastName,
                employeeCode: person.personalDetails.empCode,
                trade: person.jobDetails.designation,
                skill: person.jobDetails.skillCode,
                shiftPattern: status === "Not Scheduled" ? "" : person.multipleScheduleHistory[person.multipleScheduleHistory.length - 1]?.shiftPattern,
                // shiftPattern: status === "Not Scheduled" ? "" : shiftPattern,
                // status: person.multipleScheduleHistory[person.multipleScheduleHistory.length - 1]?.statusSchedule
                status: status
            };
        });

        console.log("transformedData", transformedData);
        setData(transformedData);
    }
    console.log("Data fetched");
    } catch (error) {
        console.error("Error fetching data:", error);
    } finally {
        setLoading(false); // Stop the loading spinner
    }
};


// const [isJobEnabled, setIsJobEnabled] = useState(false);


  const schedulValidtyTo = async () => {
    const validTo = form.getFieldValue('validTo');
    const validFrom = form.getFieldValue('validFrom');

    if (validTo) {
    // Extract year, month, and date components
    const year = validTo.year();
    const month = validTo.month(); // Note: month is 0-indexed in moment.js
    const date = validTo.date();

    // Construct the correct date as 23:59:59 in UTC
    const formattedEndDate = moment.utc([year, month, date]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');

    console.log("Raw validTo:", validTo.toString());
    console.log("setSchedulValidTo", formattedEndDate);

    setSchedulValidTo(formattedEndDate);
  } else {
    console.warn("validTo is null or undefined");
  }
  checkDateValidity(validFrom, validTo);
};

const schedulValidtyFrom = async () => {
    const validFrom = form.getFieldValue('validFrom');
    const validTo = form.getFieldValue('validTo');

    if (validFrom) {
    // Extract year, month, and date components
    const year = validFrom.year();
    const month = validFrom.month(); // Note: month is 0-indexed in moment.js
    const date = validFrom.date();

    // Construct the correct date as 00:00:00 in UTC
    const formattedStartDate = moment.utc([year, month, date]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');

    console.log("Raw validFrom:", validFrom.toString());
    console.log("setSchedulValidFrom", formattedStartDate);

    setSchedulValidFrom(formattedStartDate);
  } else {
    console.warn("validFrom is null or undefined");
  }
  checkDateValidity(validFrom, validTo);
};

const checkDateValidity = (validFrom, validTo) => {
  if (validFrom && validTo) {
      setIsJobEnabled(true);
  } else {
      setIsJobEnabled(false);
  }
};
  

  const handleJobChange = async (value) => {
    const validFrom = form.getFieldValue('validFrom');
    const validTo = form.getFieldValue('validTo');
    console.log("value", value);
    

    if (!validFrom || !validTo) {
        form.validateFields(['validFrom', 'validTo']); // Trigger validation for both fields
        return;
    }

    setSelectedJob(value);
    // await fetchData(); // Fetch data based on the selected job
    try {
      await fetchData(selectedJob); // Fetch data based on the selected job
    } finally {
      setLoading(false); // Stop showing the progress bar
    }
  };

  useEffect(() => {
    if (selectedJob) {
      fetchData(selectedJob); // Fetch data when selectedJob changes
    }
  }, [selectedJob]);

// Component to handle rendering shift pattern with hooks
const ShiftPatternCell = ({ shiftPattern, status }) => {
  const [shiftPatternData, setShiftPatternData] = React.useState(null);

  React.useEffect(() => {
    const fetchShiftPatternDetails = async () => {
      if (!shiftPattern) return;
      try {
        const data = await getShiftPatternDetails(shiftPattern);
        setShiftPatternData(data);
      } catch (error) {
        console.error('Error fetching shift pattern details:', error);
      }
    };

    fetchShiftPatternDetails();
  }, [shiftPattern]);

  const extractTime = (dateTime) => {
    return dateTime ? dateTime.split('T')[1].slice(0, 5) : '-';
  };

  const renderTooltipContent = () => {
    if (!shiftPatternData || shiftPatternData.length === 0) {
      return <span>No shift pattern available</span>;
    }

    const dayPatterns = shiftPatternData[0]?.items.map((item) => ({
      day: item.day,
      startTime: extractTime(item.shiftTemplate?.startDateTime),
      endTime: extractTime(item.shiftTemplate?.endDateTime),
    }));

    const dayCount = shiftPatternData[0]?.dayCount || 7;
    const weeks = Array.from({ length: Math.ceil(dayCount / 7) }, (_, i) => i + 1);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    return (
      <div style={{ padding: '8px' }}>
        <div style={{ marginBottom: '8px' }}>
          <strong>Shift name:</strong> {shiftPatternData[0]?.name || 'N/A'}
        </div>
        <div style={{ marginBottom: '8px' }}>
          <strong>Shift Pattern:</strong>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(9, 1fr)', gap: '5px' }}>
          <div style={{ fontWeight: 'bold' }}>Week</div>
          <div style={{ fontWeight: 'bold' }}>-</div>
          {daysOfWeek.map((day, i) => (
            <div key={i} style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {day}
            </div>
          ))}
          {weeks.map((week) => (
            <React.Fragment key={week}>
              <div style={{ fontWeight: 'bold' }}>{week}</div>
              <div style={{ fontWeight: 'bold' }}>Start</div>
              {daysOfWeek.map((day, i) => {
                const dayIndex = (week - 1) * 7 + i + 1;
                const dayPattern = dayPatterns.find((d) => d.day === dayIndex);
                return <div key={i} style={{ textAlign: 'center' }}>{dayPattern?.startTime || '-'}</div>;
              })}
              <div style={{ fontWeight: 'bold' }}></div>
              <div style={{ fontWeight: 'bold' }}>End</div>
              {daysOfWeek.map((day, i) => {
                const dayIndex = (week - 1) * 7 + i + 1;
                const dayPattern = dayPatterns.find((d) => d.day === dayIndex);
                return <div key={i} style={{ textAlign: 'center' }}>{dayPattern?.endTime || '-'}</div>;
              })}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };

  const displayText =
    status === 'Fully Scheduled'
      ? shiftPattern || 'No Shift Assigned'
      : shiftPattern || 'Pending Assignment';

  return (
    <div style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
      {displayText}
      {shiftPattern && (
        <Tooltip title={renderTooltipContent()} overlayClassName="custom-tooltip">
          <InfoCircleOutlined style={{ marginLeft: 8, color: 'gray' }} />
        </Tooltip>
      )}
    </div>
  );
};

  const columns = [
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Last name', 
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Employee Code',
      dataIndex: 'employeeCode',
      key: 'employeeCode',
    },
    {
      title: 'Trade',
      dataIndex: 'trade',
      key: 'trade',
    },
    {
      title: 'Skill',
      dataIndex: 'skill',
      key: 'skill',
    },
    {
      title: 'Shift pattern',
      dataIndex: 'shiftPattern',
      key: 'shiftPattern',
      render: (text, record) => {
        return <ShiftPatternCell shiftPattern={record.shiftPattern} status={record.status} />;
      },
    },
  
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center', float: "center" }}>
          <span style={{ marginRight: '100px' }}>Status</span>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="all" onClick={handleStatusFilterChange}>
                  <Checkbox checked={statusFilters.all}>All</Checkbox>
                </Menu.Item>
                <Menu.Item key="notScheduled" onClick={handleStatusFilterChange}>
                  <Checkbox checked={statusFilters.notScheduled}>Not Scheduled</Checkbox>
                </Menu.Item>
                  {/* <Menu.Item key="scheduled" onClick={handleStatusFilterChange}>
                    <Checkbox checked={statusFilters.scheduled}>Scheduled</Checkbox>
                  </Menu.Item> */}
                <Menu.Item key="partiallyScheduled" onClick={handleStatusFilterChange}>
                  <Checkbox checked={statusFilters.partiallyScheduled}>Partially Scheduled</Checkbox>
                </Menu.Item>
                <Menu.Item key="fullyScheduled" onClick={handleStatusFilterChange}>
                  <Checkbox checked={statusFilters.fullyScheduled}>Fully Scheduled</Checkbox>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            {/* <Button type="text" icon={<FilterOutlined />} style={{ marginLeft: '10px' }}> */}
             {/* Filter notification */}
             {/* {Object.values(statusFilters).includes(true) ? (<span style={{ color: 'red' }}>•</span>) : null}
            </Button> */}
            <Button
              type="text"
              icon={
                <FilterFilled style={{ color: isFilterActive ? 'blue' : 'gray' }} />
              }
            style={{ marginLeft: '10px' }}
          />
          </Dropdown>
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
    },
  ];

  const handleShiftPatternChange = (value) => {
    console.log("valueeee", value);
    setShiftPattern(value);
    singleShiftPatternDetail(value);
  };

  const singleShiftPatternDetail = async(shiftPattern) => {
    console.log("shiftPattern", shiftPattern);
    const shiftPatternData = await getShiftPatternDetails(shiftPattern);
    console.log("shiftPatternData", shiftPatternData[0]?.items[0]?.day)
    const shiftPatternItemsDay = shiftPatternData[0]?.items[0]?.day
    setShiftPatternItemsDay(shiftPatternItemsDay)
  }

  const onExSelectChange = (exSelectedRowKeys) => {
    console.log('newExSelectedRowKeys:', exSelectedRowKeys);
    setSelectedRowKeys(exSelectedRowKeys);
  };

  const handleTableChange = (keys) => {
    console.log('Selected Row Keys:', keys);
    setSelectedExceptionKeys(keys);
  };
  
  // const rowSelection1 = {
  //   // selectedRowKeys: [...selectedExceptionKeys],
  //   // selectedRowKeys: selectedExceptionKeys,
  //   // selectedRowKeys: selectedExceptionKeys,
  //   selectedExceptionKeys,
  
  //   // onChange: handleTableChange,
  //   onChange: (keys) => {
  //     console.log("selectedKeys", keys);
  //     setSelectedExceptionKeys(keys)
  //   },
  // }

  let selectedExceptionKeysVar = [];
  let selectedExceptionWorkmensKeysVar = [];
  const rowSelection1 = {
    onChange: (keys) => {
      console.log("selectedKeys", keys);
      setSelectedExceptionKeys(keys)
      selectedExceptionKeysVar = [...keys];
      // // Also update the selected exception workmen keys here
      // selectedExceptionWorkmensKeysVar = exceptionWorkmens.filter(workman =>
      //   keys.includes(workman.employeeCode)
      // ).map(workman => workman.employeeCode);
    },
    type: 'checkbox', 
  };

  const handleExceptionScheduleClick = async () => {
    console.log("selectedExceptionKeys11111",selectedExceptionKeys, exceptionWorkmens);
    console.log("selectedExceptionKeys - inside handleExceptionScheduleClick",selectedExceptionKeysVar, selectedExceptionWorkmensKeysVar);
    try {
        await form.validateFields(['validFrom', 'validTo', 'selectJob']);
    } catch (error) {
        console.error('Form validation failed:', error);
        return;
    }

    // await form.validateFields(['validFrom', 'validTo', 'selectJob']);
    const validFrom = form.getFieldValue('validFrom');
    const validTo = form.getFieldValue('validTo');

    console.log('Initial validFrom:', validFrom, 'Initial validTo:', validTo);

    // Ensure validFrom and validTo are properly formatted
    if (!validFrom || !validTo) {
      console.error('Invalid date values:', validFrom, validTo);
      return;
  }

    // console.log(form.getFieldValue('validTo'),form.getFieldValue('validFrom'));

    const formattedStartDate = validFrom.format('YYYY-MM-DD');
    const formattedEndDate = validTo.format('YYYY-MM-DD');

    const dayOfWeek =  moment(formattedStartDate).day();
    console.log("dayOfWeek",dayOfWeek, shiftPatternItemsDay);
    const dayOfFirstElm = shiftPatternItemsDay - 1
    console.log("dayOfFirstElm",dayOfFirstElm);
    // const startDateOffset = dayOfWeek - dayOfFirstElm;
    // const startDateOffset = dayOfWeek < dayOfFirstElm ?  dayOfFirstElm : dayOfWeek - dayOfFirstElm;
    const startDateOffset = dayOfWeek;

    // const startDateOffset = (dayOfWeek === 0) ? 1 : dayOfWeek + 1;

    console.log("selectedExceptionKeys", selectedExceptionKeys);
    
    // Filter selected exception workmen
    if (selectedExceptionKeysVar && selectedExceptionKeysVar.length > 0) {
      console.log("exceptionWorkmens---------------", exceptionWorkmens, selectedExceptionKeysVar);

        const selectedExceptionWorkmen = selectedExceptionWorkmensKeysVar.filter(workman =>
          selectedExceptionKeysVar.includes(workman.employeeCode)
        );

        console.log("Selected Exception Workmen:", selectedExceptionWorkmen);

        // Prepare payload for exception workmen
        const exceptionPayload = {
          employeeData:{
            employeeSchedulePatterns: {
                do: {
                    employeeSchedulePatternCreates: selectedExceptionWorkmen.map(workman => ({
                        employeeSchedulePattern: {
                            employeeRef: {
                                qualifier: `${workman.employeeCode}`
                            },
                            endDate: formattedEndDate,
                            populatedThroughDate: formattedStartDate,
                            schedulePattern: {
                                name: shiftPattern,
                                startDate: formattedStartDate
                            },
                            startDate: formattedStartDate,
                            startDateOffset:startDateOffset
                        },
                        override: true
                    }))
                }
            },
            options: {
                detailedPartialSuccess: false
            }
          },
          selectedJob,
          statusSchedule
        };

        console.log("exceptionPayload", exceptionPayload);

        try {
            // Call the update schedule API
            const response = await updateScheduleOfPersonData(exceptionPayload); // Replace with actual API URL
            console.log('Exception Schedule Update Response:', response);
            console.log('Schedule Update Response:', response);
            await fetchFindByLrNumberData(selectedLR)

            // const statusUpdateData = await fetchData();
            // console.log("statusUpdateData", statusUpdateData);

            //  Fetch person data from your API
            const responses = await getPersonDetailsData(); 
            console.log("Person data response:", responses);
            console.log("Selected job:", selectedJob);

            const jobDetails = tableData.find(job => job.sVCLnItemName === selectedJob);
            console.log("Job details found:", jobDetails);
            setSelectedJobDetail(jobDetails);
            let schedulStatus = ""

            if (jobDetails) {
                const { trade, skill } = jobDetails;
                const filteredData = responses.filter(person =>
                    person.jobDetails.designation === trade && person.jobDetails.skillCode === skill
                );
                setPersonDetailData(filteredData);
                console.log("Filtered data:", filteredData);

                const statusScheduleData = await getStatusScheduleData(schedulValidFrom, schedulValidTo);
                console.log("Status schedule data:", statusScheduleData);

                const transformedData = filteredData.map(person => {
                    let status = "Not Scheduled";
                    console.log("shiftPattern", shiftPattern);

                statusScheduleData.data.forEach(element => {
                    if (person.personalDetails.empCode === element.empDetails.empCode) {
                        const formatStartDate = moment(element.minStartDateTime).utc();
                        const formatEndDate = moment(element.maxEndDateTime).utc();
                        const schedulStartDate = moment(schedulValidFrom).utc();
                        const schedulEndDate = moment(schedulValidTo).utc();

                        console.log("formatStartDate", formatStartDate.format('YYYY-MM-DD'));
                        console.log("formatEndDate", formatEndDate.format('YYYY-MM-DD'));
                        console.log("schedulStartDate", schedulStartDate.format('YYYY-MM-DD'));
                        console.log("schedulEndDate", schedulEndDate.format('YYYY-MM-DD'));
                        console.log("-----111",schedulStartDate.isSame(formatStartDate, 'day'));
                        console.log("----555", schedulEndDate.isSame(formatEndDate, 'day'));

                        if (
                            schedulStartDate.isSame(formatStartDate, 'day') && 
                            schedulEndDate.isSame(formatEndDate, 'day')
                        ) {
                            status = "Fully Scheduled";
                            console.log("Fully Scheduled for:", person.personalDetails.empCode);
                            return true;
                        } else if (
                          (schedulStartDate.isBefore(formatEndDate, 'day') &&
                            schedulEndDate.isAfter(formatStartDate, 'day')) ||
                          (formatStartDate.isSame(formatEndDate, 'day') &&
                            (formatStartDate.isSame(schedulStartDate, 'day') ||
                              formatEndDate.isSame(schedulEndDate, 'day')))
                        ) {
                      //   else if (
                      //     (schedulStartDate.isBefore(formatEndDate, 'day') && schedulEndDate.isAfter(formatStartDate, 'day')) ||
                      //     (
                      //         formatStartDate.isSame(formatEndDate, 'day') &&
                      //         (formatStartDate.isSame(schedulStartDate, 'day') || formatEndDate.isSame(schedulEndDate, 'day'))
                      //     )
                      // ) {
                          status = "Partially Scheduled";
                          console.log("Partially scheduled for:", person.personalDetails.empCode);
                          return true;
                      }
                    }
                    return false;
                });
                schedulStatus = status
                console.log("statussss", status);
                setStatusSchedule(status)
                return { ...person, status };
              })
            }

            console.log("statusScheduleuuuuuu",statusSchedule, schedulStatus, selectedJob);
            
            const personData = await updatePersonDataDetails(response,statusSchedule, schedulStatus, selectedJob)
            console.log('Schedule Update Response:', personData);

            const successMessage = (
              <div>
                  <img src={approve} alt="Status" style={{ height: '30px', marginRight: '6px' }} />
                  {`${selectedExceptionWorkmen.length} workmen are scheduled successfully in "${shiftPattern}" under "${selectedJob}" between "${formattedStartDate}" to "${formattedEndDate}".`}
              </div>
            );
            if(personData.message === "Schedule updated and person data stored successfully") {
            setModalTitle('Schedule Status');
            setModalContent(successMessage);
            setModalWidth(500);
            fetchFindByLrNumberData(selectedLR);
            // fetchData(selectedJob);
            // await fetchData();
            }else {
              message.error("Error Notification!.");
            } 
        } catch (error) {
            console.error('Error during exception schedule update:', error);
            // Handle the error accordingly
            message.error('Error during exception schedule update:', error);
        }
    } else {
        console.error('No exception workmen selected.');
    }

    setModalVisible(true);
  };

  console.log('Initial validFrom:', validFrom, 'Initial validTo:', validTo);

  const handleModalClose = () => {
    console.log("tttettttt");
    setModalVisible(false);
    fetchData(selectedJob);  // Call fetchData after closing the modal
  };
  
  const onFinish = async (actionType) => {
    console.log("actionType", actionType);
    await form.validateFields(['validFrom', 'validTo', 'selectJob']);
    const validFrom = form.getFieldValue('validFrom');
    const validTo = form.getFieldValue('validTo');
    const selectJob = form.getFieldValue('selectJob')

    if (!validFrom || !validTo || !selectJob) {
      message.error('Please fill out all fields.');
      return;
    }

    let selectJobs = selectJob.replace(/\s*\(.*?\)\s*/g, '');

    console.log('Initial validFrom:', validFrom, 'Initial validTo:', validTo);
    const formattedStartDate = validFrom.format('YYYY-MM-DD');
    const formattedEndDate = validTo.format('YYYY-MM-DD');

    console.log("formattedStartDate", formattedStartDate);

    console.log("shiftPatternItemsDay", shiftPatternItemsDay);
    console.log("selectedJobDetail", selectedJobDetail);
    

    // Step 3: Check each required data point and log missing values
  const requiredData = [
    { key: 'companyCode', value: selectedLrData?.companyCode },
    { key: 'unitCode', value: selectedLrData?.unitCode },
    { key: 'deptCode', value: selectedLrData?.deptCode },
    { key: 'sectionCode', value: selectedLrData?.sectionCode },
    { key: 'vendorCode', value: selectedLrData?.vendorCode },
    // { key: 'contractorSupervisor', value: selectedJobDetail?.contractorSupervisor },
    { key: 'workOrderNumber', value: selectedLrData?.workOrderNumber },
    // { key: 'itemNum', value: selectedJobDetail?.itemNum },
    // { key: 'sVCLnItemNum', value: selectedJobDetail?.sVCLnItemNum },
    { key: 'selectJobs', value: selectJobs }
  ];


    const dayOfWeek =  moment(formattedStartDate).day();
    console.log("dayOfWeek", dayOfWeek);
    // const jDayOfWeek =  new Date(formattedStartDate).getDay();
    // console.log("dayOfWeek", dayOfWeek);

    const dayOfFirstElm = shiftPatternItemsDay - 1
    // const dayOfFirstElm = list[0].day-1
    console.log("dayOfFirstElm", dayOfFirstElm);
    const startDateOffset = dayOfWeek;
    // const startDateOffset = dayOfWeek < dayOfFirstElm ?  dayOfWeek : dayOfWeek - dayOfFirstElm;/*(dayOfWeek === 0) ? 0 : dayOfWeek + 1;*/
    console.log("startDateOffset", startDateOffset);

    // let orgPath = "";
    console.log("selectedRowKeys", selectedRowKeys)
    console.log(formattedStartDate, formattedEndDate, selectJobs, selectedRowKeys, selectedLrData, selectedJobDetail);
    console.log('selectedLrData:', selectedLrData);
    console.log('selectedJobDetail:', selectedJobDetail);
    console.log('selectJobs:', selectJobs);

    const missingData = requiredData.filter(item => !item.value);
    if (missingData.length > 0) {
      console.log("Missing data:", missingData);
      message.warning('Some data is missing, please check and try again.');
      return;
    }
    // if(!selectedLrData?.companyCode  || !selectedLrData?.unitCode  || !selectedLrData?.deptCode  || !selectedLrData?.sectionCode  || !selectedLrData?.vendorCode  || !selectedJobDetail?.contractorSupervisor  || !selectedLrData?.workOrderNumber  || !selectedJobDetail?.itemNum  || !selectedJobDetail?.sVCLnItemNum  || !selectJobs ) {
    //   //undefined
    //   console.log("!selectedLrData?.companyCode", selectedLrData?.companyCode, selectedLrData?.unitCode, selectedLrData?.deptCode, selectedLrData?.sectionCode, selectedLrData?.vendorCode, selectedJobDetail?.contractorSupervisor, selectedLrData?.workOrderNumber, selectedJobDetail?.itemNum, selectedJobDetail?.sVCLnItemNum, selectJobs);
      
    //   message.warning('Some data is missing, please reload the page.');
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 3000);
      
    // }else {
    //   // orgPath = `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedJobDetail.contractorSupervisor}/${selectedLrData.workOrderNumber}-${selectedJobDetail.itemNum}-${selectedJobDetail.sVCLnItemNum}/${selectJobs}`
    //   orgPath = `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedLrData.workOrderNumber}-${selectedJobDetail.itemNum}-${selectedJobDetail.sVCLnItemNum}/${selectJobs}` 
    //   setOrgPath(orgPath)
    // }

     // Step 4: Construct orgPath and proceed
    // const orgPath = `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedLrData.workOrderNumber}-${selectedJobDetail.itemNum}-${selectedJobDetail.sVCLnItemNum}/${selectJobs}`;
    const orgPath = `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedLrData.workOrderNumber}/${selectJobs}`;
    setOrgPath(orgPath);
      
    
    // Construct the payload
    const payloads = selectedRowKeys.map(personNumber => ({
      jobAssignment: {
        primaryLaborAccounts: [
          {
            effectiveDate: formattedStartDate,
            // organizationPath: `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedJobDetail.contractorSupervisor}/${selectedLrData.workOrderNumber}-${selectedJobDetail.itemNum}-${selectedJobDetail.sVCLnItemNum}/${selectJobs}`,*/
            organizationPath: `${selectedLrData.companyCode}/${selectedLrData.unitCode}/${selectedLrData.deptCode}/${selectedLrData.sectionCode}/${selectedLrData.vendorCode}/${selectedLrData.workOrderNumber}/${selectJobs}`,
            // organizationPath: orgPath,
            expirationDate: "3000-01-01"
          }
        ]
      },
      personIdentity: {
        personNumber
        // personNumber: parseInt(personNumber, 10)
      }
    }));

    console.log("Payload:", JSON.stringify(payloads, null, 2));

    // Make the API request
    const response = await updateOrgPathAndEffectDateData(payloads)

    console.log("API Response:", response);

    // Handle response or errors
    if (response.status === 200) {
      console.log("Update successful");
      if (selectedRowKeys.length > 0) {
        console.log("selectedRowKeys", selectedRowKeys, data);
          const workmen = selectedRowKeys.map(key => data.find(item => item.employeeCode === key));
          console.log("workmen", workmen);
          if (actionType === 'schedule' && shiftPattern) {
            console.log("actionType", actionType, shiftPattern);
              const successWorkmen = workmen.filter(w => w.status === 'Not Scheduled' || w.status === undefined || w.status === "");
              console.log("successWorkmen", successWorkmen);
              const exceptionWorkmen = workmen.filter(w => w.status !== 'Not Scheduled' && w.status !== '' && w.status !== undefined);
              console.log("exceptionWorkmen", exceptionWorkmen);
              // setExceptionWorkmens(exceptionWorkmen)
              // console.log(exceptionWorkmens);
                // Use the previous state value to ensure correct updates
                setExceptionWorkmens(prevState => {
                  console.log("Previous exceptionWorkmens state:", prevState);
                  console.log("Setting new exceptionWorkmens:", exceptionWorkmen);
                  selectedExceptionWorkmensKeysVar = [...exceptionWorkmen]
                  return exceptionWorkmen;
                });
              
              // Prepare the payload
              const payload = {
                employeeData:{
                  employeeSchedulePatterns: {
                      do: {
                          employeeSchedulePatternCreates: successWorkmen.map(w => (
                            // employeeSchedulePatternCreates:
                            {
                              employeeSchedulePattern: {
                                  employeeRef: {
                                      // qualifier: w.employeeCode // Assuming employeeCode is the qualifier
                                      qualifier: `${w.employeeCode}`
                                  },
                                  endDate: formattedEndDate,
                                  populatedThroughDate: formattedStartDate,
                                  schedulePattern: {
                                      name: shiftPattern,
                                      startDate: formattedStartDate
                                  },
                                  startDate: formattedStartDate,
                                  startDateOffset:startDateOffset
                              },
                              override: true
                          }
                        ))
                      }
                  },
                  options: {
                      detailedPartialSuccess: false
                  },
                },
                selectedJob,
                statusSchedule
              };
  
              console.log("payload", payload);
              // return
              try {
                // Call the update schedule API
                const response = await updateScheduleOfPersonData(payload); // Replace with actual API URL
                console.log('Exception Schedule Update Response:', response);
                console.log('Schedule Update Response:', response);
                console.log("selectedLR", selectedLR);
                
                await fetchFindByLrNumberData(selectedLR)
    
                // const statusUpdateData = await fetchData();
                // console.log("statusUpdateData", statusUpdateData);
    
                //  Fetch person data from your API
                const responses = await getPersonDetailsData(); 
                console.log("Person data response:", responses);
                console.log("Selected job:", selectedJob);
    
                const jobDetails = tableData.find(job => job.sVCLnItemName === selectedJob);
                console.log("Job details found:", jobDetails);
                setSelectedJobDetail(jobDetails);
                let schedulStatus = ""
    
                if (jobDetails) {
                    const { trade, skill } = jobDetails;
                    const filteredData = responses.filter(person =>
                        person.jobDetails.designation === trade && person.jobDetails.skillCode === skill
                    );
                    setPersonDetailData(filteredData);
                    console.log("Filtered data:", filteredData);
    
                    const statusScheduleData = await getStatusScheduleData(schedulValidFrom, schedulValidTo);
                    console.log("Status schedule data:", statusScheduleData);
    
                  const transformedData = filteredData.map(person => {
                    let status = "Not Scheduled"; // Default to "Not Scheduled"
                    console.log("shiftPattern", shiftPattern);
                    console.log("Processing schedule for:", person.personalDetails.empCode);
                
                    // Iterate over the schedule data
                    statusScheduleData.data.forEach(element => {
                        if (person.personalDetails.empCode === element.empDetails.empCode) {
                          
                            const formatStartDate = moment(element.minStartDateTime).utc();
                            const formatEndDate = moment(element.maxEndDateTime).utc();
                            const schedulStartDate = moment(schedulValidFrom).utc();
                            const schedulEndDate = moment(schedulValidTo).utc();
                
                            console.log("Processing schedule for:", person.personalDetails.empCode, element);
                            console.log("formatStartDate", formatStartDate.format('YYYY-MM-DD'));
                            console.log("formatEndDate", formatEndDate.format('YYYY-MM-DD'));
                            console.log("schedulStartDate", schedulStartDate.format('YYYY-MM-DD'));
                            console.log("schedulEndDate", schedulEndDate.format('YYYY-MM-DD'));
                            console.log("-----111", schedulStartDate.isSame(formatStartDate, 'day'));
                            console.log("----555", schedulEndDate.isSame(formatEndDate, 'day'));
                
                            if (schedulStartDate.isSame(formatStartDate, 'day') && schedulEndDate.isSame(formatEndDate, 'day')) {
                                // Set the status to Fully Scheduled and break out of the loop
                                status = "Fully Scheduled";
                                console.log("Fully Scheduled for:", person.personalDetails.empCode);
                                return; // No need to check further if fully scheduled
                            } 
                            // Check if Partially Scheduled
                            else if (
                                (schedulStartDate.isBefore(formatEndDate, 'day') && schedulEndDate.isAfter(formatStartDate, 'day')) ||
                                (formatStartDate.isSame(formatEndDate, 'day') &&
                                    (formatStartDate.isSame(schedulStartDate, 'day') || formatEndDate.isSame(schedulEndDate, 'day')))
                            ) {
                                // Set the status to Partially Scheduled
                                status = "Partially Scheduled";
                                console.log("Partially scheduled for:", person.personalDetails.empCode);
                                // Note: Do not return here, let the loop continue for other checks, but preserve this status
                            }
                        }
                    });
                
                    // Log the final status after all checks
                    console.log("Final status for person:", person.personalDetails.empCode, status);
                    
                    return { ...person, status }; // Return the updated person object with the status
                });
                }
    
                  console.log("statusSchedule",statusSchedule, "statusSchedule1", schedulStatus, "statusSchedule2",selectedJob);
                  
                  const personData = await updatePersonDataDetails(response, schedulStatus, selectedJob)
                  console.log("personData", personData);
  
                  // Handle response and update the person data of statusSchedule, and shift pattern accordingly
  
                  const successMessage = (
                      <>
                          <div>
                              <img src={approve} alt="Status" style={{ height: '30px', marginRight: '6px' }} />
                              {`${successWorkmen.length} workmen are scheduled successfully in "${shiftPattern}" under "${selectedJob}" between "${formattedStartDate}" to "${formattedEndDate}".`}
                          </div>
                          {exceptionWorkmen.length > 0 && (
                              <>
                                  <Divider />
                                  <div>
                                      <img src={reject} alt="Status" style={{ height: '30px', marginRight: '6px' }} />
                                      {`${exceptionWorkmen.length} workmen are exceptions, click "Schedule" to unschedule these workmen from current shift pattern and assign them to the below schedule`}
                                  </div>
                                  <div style={{ marginTop: 16 }}>
                                      <Card style={{ backgroundColor: "#F4F6FF", padding: 16 }}>
                                      <Form layout="vertical">
                                        <Row gutter={16}>
                                          <Col >
                                            <Form.Item label="Shift Pattern" >
                                              <Input placeholder="Shift Pattern" value={shiftPattern} disabled style={{ width: 120 }} />
                                            </Form.Item>
                                          </Col>
                                          <Col >
                                            <Form.Item label="From" >
                                              <Input placeholder="From" value={formattedStartDate} disabled style={{ width: 100 }} />
                                            </Form.Item>
                                          </Col>
                                          <Col >
                                            <Form.Item label="To" >
                                              <Input placeholder="To" value={formattedEndDate} disabled style={{ width: 100 }} />
                                            </Form.Item>
                                          </Col>
                                          <Col>
                                            <Form.Item label={ <span style={{ fontSize: 14, whiteSpace: 'nowrap'}}>{"Job"}</span> }>
                                              <Input placeholder="Job" value={selectedJob} disabled style={{ width: 120 }} />
                                            </Form.Item>
                                          </Col>
                                          <Col>
                                            <Form.Item 
                                            label={
                                              <span style={{ fontSize: 14, whiteSpace: 'nowrap'}}>
                                              {selectedRowKeys.length > 0
                                                ? `${selectedRowKeys.length} workmen selected${shiftPattern ? ` under ${shiftPattern}` : ''}`
                                                : 'Select workman and shift pattern to schedule'}
                                                </span>
                                            }
                                            >
                                              <Button 
                                                type="primary" 
                                                htmlType="submit" 
                                                className="custom-button" 
                                                disabled={!shiftPattern} 
                                                onClick={handleExceptionScheduleClick} 
                                                // onClick={() => handleExceptionScheduleClick('schedule')}
                                                style={{ width: 150 }}
                                              >
                                                Schedule
                                              </Button>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Form>
                                    </Card>
                                  </div>
                                  <Table
                                      rowSelection={rowSelection1}
                                      columns={columns}
                                      dataSource={exceptionWorkmen}
                                      pagination={true}
                                      rowKey="employeeCode"
                                  />
                              </>
                          )}
                      </>
                  );

                  setModalTitle('Schedule Status');
                  setModalContent(
                  // <div>
                  //   <img src={approve} alt="Status" style={{ height: '30px', marginRight: '6px' }} />
                  //   {`${successWorkmen.length} workmen are scheduled successfully in "${shiftPattern}" under "Pharmacist Charges" between "${form.getFieldValue('validFrom')}" to "${form.getFieldValue('validTo')}".`}
                  // </div>
                  successMessage
                );
                  setModalWidth(exceptionWorkmen.length > 0 ? 1000 : 500);
                  // await fetchData();
              } catch (error) {
                  console.error('Error during schedule update:', error);
                  // Handle the error accordingly
              }
          } else if (actionType === 'unschedule') {
              const unscheduleMessage = <div><img src={reject} alt="Status" style={{ height: '30px', marginRight: '6px' }} />{`${workmen.length} workmen unscheduled successfully.`}</div>;
              setModalTitle('Unschedule Status');
              setModalContent(unscheduleMessage);
              setModalWidth(500);
          }
          setModalVisible(true);
        
      } else {
          console.error('Cannot perform action without selecting workmen.');
      }
      // Proceed with your logic here
    } else {
      console.error("Update failed", response.data);
      // Handle error response
    } 
};

console.log("filteredData", filteredData, filteredData.workOrderNumber);
  const lrNumberDisplay = filteredData ? filteredData.lrNumber : '';

  const stratShiftPatterns = {
    shiftA: { startTime: "04:00", endTime: "08:00" },
    shiftB: { startTime: "12:00", endTime: "17:00" },
    shiftC: { startTime: "18:00", endTime: "23:00" },
    shiftGen: { startTime: "07:00", endTime: "10:30" }
  };
  
  const endShiftPatterns = {
    shiftA: { startTime: "12:00", endTime: "16:00" },
    shiftB: { startTime: "20:00", endTime: "03:00" },
    shiftC: { startTime: "04:00", endTime: "08:00" },
    shiftGen: { startTime: "17:00", endTime: "20:00" }
  };

  const disabledDate = (current) => {
    return current && (
      (validFrom && current < moment(validFrom, 'DD-MM-YYYY')) ||
      (validTo && current > moment(validTo, 'DD-MM-YYYY'))
    );
  };

  const formatTime = (dateTimeString) => {
    if (!dateTimeString) return '';
    return dateTimeString.split('T')[1]?.split(':').slice(0, 2).join(':') || '';
  };

  // Function to calculate the duration between two date-time strings
const calculateHours = (startDateTime, endDateTime) => {
  if (!startDateTime || !endDateTime) return '';

  const start = new Date(startDateTime);
  const end = new Date(endDateTime);
  
  const diffInMs = end - start;
  const diffInHours = diffInMs / (1000 * 60 * 60); // Convert milliseconds to hours
  
  return diffInHours.toFixed(2); // Return the duration rounded to 2 decimal places
};

  // Ensure selectedJob and tableData are defined in your component

// Ensure selectedJob and tableData are defined in your component
console.log('Selected Job (outside function):', selectedJob);
console.log('Table Data (outside function):', tableData);

const checkOverlap = (startTime1, endTime1, shiftPattern) => {
  const { startTime: startTime2, endTime: endTime2 } = shiftPattern;

  // Convert times to Date objects for comparison
  const start1 = new Date(`1970-01-01T${startTime1}`);
  const end1 = new Date(`1970-01-01T${endTime1}`);
  const start2 = new Date(`1970-01-01T${startTime2}`);
  const end2 = new Date(`1970-01-01T${endTime2}`);

  // Check if the two time periods overlap
  return start1 < end2 && start2 < end1;
};

const extractTime = (dateTime) => {
  // Extract the time portion (HH:mm) from a DateTime string
  return dateTime ? dateTime.split('T')[1].slice(0, 5) : "-";
};

const renderOptions = async () => {
  if (!filteredShiftPatternData || !filteredShiftPatternData[0]) {
    console.error('Filtered shift pattern data is empty or invalid');
    return [];
  }

  try {
    const promises = filteredShiftPatternData[0].patternTemplates.map(async (item, index) => {
      console.log("tetstedhhhhhhh", item.qualifier);
      try {
        const shiftPatternData = await getShiftPatternDetails(item.qualifier);
        console.log("shiftPatternData", shiftPatternData);
        setShiftPatternDetail(shiftPatternData);

        
        const startTime = formatTime(shiftPatternData[0]?.items[0]?.shiftTemplate?.startDateTime);
        const endTime = formatTime(shiftPatternData[0]?.items[0]?.shiftTemplate?.endDateTime);
        const startTime1 = shiftPatternData[0]?.items[0]?.shiftTemplate?.startDateTime;
        const endTime1 = shiftPatternData[0]?.items[0]?.shiftTemplate?.endDateTime;

        const hours = calculateHours(startTime1, endTime1);

        console.log('Start Time:', startTime, 'End Time:', endTime);

        console.log('Selected Job:', selectedJob);
        if (!selectedJob) {
          console.error('Selected job is not defined');
          return null;
        }

        console.log('Table Data:', tableData);
        if (!tableData || tableData.length === 0) {
          console.error('Table data is not defined or empty');
          return null;
        }

        const jobDetails = tableData.find(job => job.sVCLnItemName === selectedJob);
        if (!jobDetails) {
          console.error('Job details not found for selected job:', selectedJob);
          return null;
        }

        console.log('Job Details:', jobDetails);

        const lastShiftInfo = jobDetails.shiftInformation[jobDetails.shiftInformation.length - 1];
        if (!lastShiftInfo) {
          console.error('Last shift information not found:', jobDetails.shiftInformation);
          return null;
        }

        console.log('Last Shift Info:', lastShiftInfo);

        const isZeroShiftA = lastShiftInfo.shiftA === 0 || lastShiftInfo.shiftA === "";//balScheduleAQty
        const isZeroShiftB = lastShiftInfo.shiftB === 0 || lastShiftInfo.shiftB === "";
        const isZeroShiftC = lastShiftInfo.shiftC === 0 || lastShiftInfo.shiftC === "";
        const isZeroShiftGen = lastShiftInfo.shiftGen === 0 || lastShiftInfo.shiftGen === "";
        const isZeroBalScheduleAQty = lastShiftInfo.balScheduleAQty <= 0 || lastShiftInfo.balScheduleAQty === "";//balScheduleAQty
        const isZeroBalScheduleBQty = lastShiftInfo.balScheduleBQty <= 0 || lastShiftInfo.balScheduleBQty === "";
        const isZeroBalScheduleCQty = lastShiftInfo.balScheduleCQty <= 0 || lastShiftInfo.balScheduleCQty === "";
        const isZeroBalScheduleGenQty = lastShiftInfo.balScheduleGenQty <= 0 || lastShiftInfo.balScheduleGenQty === "";

        console.log('Is Zero Shift A:', isZeroShiftA);
        console.log('Is Zero Shift B:', isZeroShiftB);
        console.log('Is Zero Shift C:', isZeroShiftC);
        console.log('Is Zero Shift Gen:', isZeroShiftGen);

        let isOverlap = false;

        if (isZeroShiftA || isZeroBalScheduleAQty) {
          const startPattern = stratShiftPatterns.shiftA;
          const endPattern = endShiftPatterns.shiftA;
          console.log(`Comparing with shiftA patterns:`, startPattern, endPattern);

          const overlapStart = (
            startTime >= startPattern.startTime && startTime <= startPattern.endTime
          );
          const overlapEnd = (
            endTime >= endPattern.startTime && endTime <= endPattern.endTime
          );
          
          console.log(`Overlap with shiftA start pattern:`, overlapStart);
          console.log(`Overlap with shiftA end pattern:`, overlapEnd);

          isOverlap = overlapStart && overlapEnd;
        } else if (isZeroShiftB || isZeroBalScheduleBQty) {
          const startPattern = stratShiftPatterns.shiftB;
          const endPattern = endShiftPatterns.shiftB;
          console.log(`Comparing with shiftB patterns:`, startPattern, endPattern);

          const overlapStart = (
            startTime >= startPattern.startTime && startTime <= startPattern.endTime
          );
          const overlapEnd = (
            endTime >= endPattern.startTime && endTime <= endPattern.endTime
          );
          
          console.log(`Overlap with shiftB start pattern:`, overlapStart);
          console.log(`Overlap with shiftB end pattern:`, overlapEnd);

          isOverlap = overlapStart && overlapEnd;
        } else if (isZeroShiftC || isZeroBalScheduleCQty) {
          const startPattern = stratShiftPatterns.shiftC;
          const endPattern = endShiftPatterns.shiftC;
          console.log(`Comparing with shiftC patterns:`, startPattern, endPattern);

          const overlapStart = (
            startTime >= startPattern.startTime && startTime <= startPattern.endTime
          );
          const overlapEnd = (
            endTime >= endPattern.startTime && endTime <= endPattern.endTime
          );
          
          console.log(`Overlap with shiftC start pattern:`, overlapStart);
          console.log(`Overlap with shiftC end pattern:`, overlapEnd);

          isOverlap = overlapStart && overlapEnd;
        } else if (isZeroShiftGen || isZeroBalScheduleGenQty) {
          const startPattern = stratShiftPatterns.shiftGen;
          const endPattern = endShiftPatterns.shiftGen;
          console.log(`Comparing with shiftGen patterns:`, startPattern, endPattern);

          const overlapStart = (
            startTime >= startPattern.startTime && startTime <= startPattern.endTime
          );
          const overlapEnd = (
            endTime >= endPattern.startTime && endTime <= endPattern.endTime
          );
          
          console.log(`Overlap with shiftGen start pattern:`, overlapStart);
          console.log(`Overlap with shiftGen end pattern:`, overlapEnd);

          isOverlap = overlapStart && overlapEnd;
        }

        console.log('Is Overlap:', isOverlap);

        const dayPatterns = shiftPatternData[0]?.items.map(item => ({
          day: item.day,
          startTime: extractTime(item.shiftTemplate?.startDateTime),
          endTime: extractTime(item.shiftTemplate?.endDateTime),
        }));

        const shiftKeys = ['shiftA', 'shiftB', 'shiftC', 'shiftGen'];
        const isOverlap1 = shiftKeys.some(shiftKey => {
          if (lastShiftInfo[shiftKey] === 0 || lastShiftInfo[shiftKey] === "") {
            return checkOverlapForWeek(dayPatterns, stratShiftPatterns[shiftKey]);
          }
          return false;
        });

        const dayCount = shiftPatternData[0]?.dayCount || 7;
        const weeks = Array.from({ length: Math.ceil(dayCount / 7) }, (_, i) => i + 1);
        const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        return (
          <Option key={index} value={item.qualifier} label={item.qualifier} disabled={isOverlap}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {item.qualifier}
            <Tooltip
              title={
                <div style={{ padding: '8px' }}>
                  <div style={{ marginBottom: '8px' }}>
                    <strong>Shift name:</strong> {shiftPatternData[0]?.name}
                  </div>
                  <div style={{ marginBottom: '8px' }}>
                    <strong>Shift Pattern:</strong>
                  </div>
                  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(9, 1fr)', gap: '5px' }}>
                    {/* Week and Days Header */}
                    <div style={{ fontWeight: 'bold' }}>Week</div>
                    <div style={{ fontWeight: 'bold' }}>-</div>
                    {daysOfWeek.map((day, i) => (
                      <div key={i} style={{ textAlign: 'center', fontWeight: 'bold' }}>{day}</div>
                    ))}
        
                    {/* Display Start and End times for each week */}
                    {weeks.map((week) => (
                      <React.Fragment key={week}>
                        {/* Week Number */}
                        <div style={{ fontWeight: 'bold' }}>{week}</div>
        
                        {/* Start Time Row */}
                        <div style={{ fontWeight: 'bold' }}>Start</div>
                        {daysOfWeek.map((day, i) => {
                          const dayIndex = (week - 1) * 7 + i + 1;
                          const dayPattern = dayPatterns.find(d => d.day === dayIndex);
                          const startTime = dayPattern ? dayPattern.startTime : '-';
                          return <div key={i} style={{ textAlign: 'center' }}>{startTime}</div>;
                        })}
        
                        {/* End Time Row */}
                        <div style={{ fontWeight: 'bold' }}></div> {/* Adjusted to align with Week */}
                        <div style={{ fontWeight: 'bold' }}>End</div>
                        {daysOfWeek.map((day, i) => {
                          const dayIndex = (week - 1) * 7 + i + 1;
                          const dayPattern = dayPatterns.find(d => d.day === dayIndex);
                          const endTime = dayPattern ? dayPattern.endTime : '-';
                          return <div key={i} style={{ textAlign: 'center' }}>{endTime}</div>;
                        })}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              }
              overlayClassName="custom-tooltip"
            >
              <InfoCircleOutlined style={{ marginLeft: 8, color: 'gray' }} />
            </Tooltip>
          </div>
          </Option>
        );
        
      
      } catch (error) {
        console.error('Error processing shift pattern data for item:', item, error);
        return null;
      }
    });

    const options = await Promise.all(promises);
    return options.filter(option => option !== null);
  } catch (error) {
    console.error('Error generating options:', error);
    return [];
  }
};

const checkOverlapForWeek = (dayPatterns, shiftPattern) => {
  return dayPatterns.some(dayPattern => {
    const startTime = dayPattern.startTime;
    const endTime = dayPattern.endTime;
    return checkOverlap(startTime, endTime, shiftPattern);
  });
};

useEffect(() => {
  console.log('Table Data in useEffect:', tableData);
  console.log('Selected Job in useEffect:', selectedJob);
  if (selectedJob && tableData.length > 0) {
    renderOptions().then(renderedOptions => {
      setOptions(renderedOptions);
    }).catch(error => {
      console.error('Error fetching options:', error);
    });
  }
}, [selectedJob, tableData]);

// Fetch options based on filteredShiftPatternData
useEffect(() => {
  const fetchOptions = async () => {
    try {
      const renderedOptions = await renderOptions();
      setOptions(renderedOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
    } finally {
      setLoading(false);
    }
  };

  if (filteredShiftPatternData && filteredShiftPatternData.length > 0) {
    fetchOptions();
  }
}, [filteredShiftPatternData]);

  return (
    <div>
      <Button type="link" style={{ color: 'black' }} onClick={() => navigate(`/home/supervisor/scheduleworkmen`)}>
        <LeftOutlined />
      </Button>
      <span style={{ fontSize: '18px', fontWeight: 'bold' }}>Schedule workmen for LR</span><br /><br />
      <Card style={{ backgroundColor: "#F4F6FF" }}>
        <Row gutter={16} justify="center" align="middle">
        <Col span={6}>
            <Text strong>LR number</Text>
            <Select
              value={selectedLR}
              style={{ width: '100%', marginTop: '10px' }}
              onChange={handleLRChange}
            >
              {filteredData.map((item) => (
                <Option key={item.lrNumber} value={item.lrNumber}>{item.lrNumber}</Option>
              ))}
            </Select>
          </Col>
        <Col span={6}>
            <Text strong>LR validity from</Text>
            <DatePicker value={validFrom} format="DD-MM-YYYY" placeholder="Select Start Date" style={{ width: '100%', marginTop: '10px' }} disabled/>
          </Col>
          <Col span={6}>
            <Text strong>LR validity to</Text>
            <DatePicker value={validTo} format="DD-MM-YYYY" placeholder="Select End Date" style={{ width: '100%', marginTop: '10px' }} disabled/>
          </Col>
        </Row>
        <Row gutter={16} justify="center" align="middle" style={{ marginTop: '20px', borderTop: '1px solid #E0E0E0', paddingTop: '10px' }}>
          <Col span={6}  style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text>Department</Text>
            <Text style={{ margin: '0 10px' }}>:</Text>
            <Text>{departmentName}</Text>
          </Col>
          <Col span={8}  style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text>Work order Type</Text>
            <Text style={{ margin: '0 10px' }}>:</Text>
            <Text>{workOrderType}</Text>
          </Col>
          <Col span={10}  style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Text>Job description</Text>
            <Text style={{ margin: '0 10px' }}>:</Text>
            <Text>{jobName}</Text>
          </Col>
        </Row>
      </Card>

      <Title level={5} style={{ marginTop: '20px' }}>
        Job Order Details
      </Title><br />
      <Table
        // dataSource={dataSource}
        dataSource={tableData}
        columns={column}
        pagination={false}
        style={{ marginBottom: '20px', overflowX: 'auto' }}
      />

      <Card style={{ backgroundColor: "#F4F6FF" }}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ validFrom: null, validTo: null }}
        // initialValues={{ job: '' }}
        >
          <Row gutter={16} justify="center">
            <Col span={6}>
              <Tooltip title={`Validity should be within ${moment(validFrom).format('DD-MM-YYYY')} to ${moment(validTo).format('DD-MM-YYYY')}`}>
                <Form.Item
                  label="From"
                  name="validFrom"
                  rules={[{ required: true, message: 'Please select the start date' }]}
                  className="custom-form-item"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%", color: "black", fontSize: '14px' }}
                    disabledDate={disabledDate}
                    // value={validFrom ? moment(validFrom, 'DD-MM-YYYY') : null}
                    // onChange={(date, dateString) => setValidFrom(date ? moment(dateString, 'DD-MM-YYYY') : null)}
                    onChange={schedulValidtyFrom}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={6}>
              <Tooltip title={`Validity should be within ${moment(validFrom).format('DD-MM-YYYY')} to ${moment(validTo).format('DD-MM-YYYY')}`}>
                <Form.Item
                  label="To"
                  name="validTo"
                  rules={[
                    { required: true, message: 'Please select the end date' },
                  //   ({ getFieldValue }) => ({
                  //     validator(_, value) {
                  //       if (!value || getFieldValue('validFrom') <= value) {
                  //         return Promise.resolve();
                  //       }
                  //       return Promise.reject(new Error('End date must be after start date'));
                  //     },
                  //   }),
                  // ]}
                  ({getFieldValue }) => ({
                    validator(_, value) {
                      if (value && value < getFieldValue('validFrom')) {
                        return Promise.reject(new Error('End date cannot be before start date'));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                className="custom-form-item"
                >
                  <DatePicker
                    format="DD-MM-YYYY"
                    style={{ width: "100%", color: "black", fontSize: '14px'}}
                    disabledDate={disabledDate}
                    // value={validTo ? moment(validTo, 'DD-MM-YYYY') : null}
                    // onChange={(date, dateString) => setValidTo(date ? moment(dateString, 'DD-MM-YYYY') : null)}
                    onChange={schedulValidtyTo}
                  />
                </Form.Item>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Form.Item
                // name=""
                name="selectJob"
                label="Select Job"
                // initialValue={jobOptions.length > 0 ? jobOptions[0].value : undefined}
                initialValue=""
                rules={[
                  {
                  required: true,
                  message: 'Please select the jobs',
                  },
              ]}
              className="custom-form-item"
              >
                {/* <Select>
                  <Option value="twoWheelerHiring" style={{ width: "100%", color: "black" }}>Two wheeler hiring charges</Option>
                </Select> */}
                <Select
                  onChange={handleJobChange}
                  disabled={!isJobEnabled} // Disable if validFrom or validTo is not set
                  style={{ width: "100%", color: "black", fontSize: '14px' }}
                >
                {/* <Option key="" value="">Select an option</Option> */}
                  {jobOptions.map(option => (
                    <Option key={option.value} value={option.value}>{option.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={4}>
              <Button type="" htmlType="submit" style={{ marginTop: '32px', backgroundColor: "#1677ff", width: "100%", color: "white" }}>
                Save
              </Button>
            </Col> */}
          </Row>
        </Form>
      </Card><br/>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
      // initialValues={{ validFrom: null, validTo: null }}
      // initialValues={{ job: '' }}
        // style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}
        // style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',}}
      >
        {/* <div style={{ width: '100%', maxWidth: '800px' }}> */}
        {/* <Row gutter={16} justify="center" align="middle"> */}
        <Row gutter={16} justify="center" align="middle" style={{marginLeft:"100px", marginRight:"8px"}}>
          <Col span={7}>
            <Form.Item
              label="Shift Pattern"
              name="shiftPattern"
              initialValue=""
              rules={[{ required: true, message: 'Please select a shift pattern' }]}
              className="custom-form-item"
            >
              <Select
                onChange={handleShiftPatternChange}
                dropdownRender={menu => (
                  <div>
                    {menu}
                  </div>
                )}
              >
                <Option value="">Select Shifts</Option>
                {options} 
                {/* Render shift patterns dynamically */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                selectedRowKeys.length > 0
                  ? `${selectedRowKeys.length} workmen selected${shiftPattern ? ` under ${shiftPattern}` : ''}`
                  : 'Select workman and shift pattern to schedule'
              }
              className="custom-form-item"
            >
              {/* <Row gutter={16}> */}
                <Col span={16}>
                  <Tooltip title={shiftPattern ? '' : 'Select shift pattern to schedule'}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="custom-button"
                      disabled={!shiftPattern || !selectedRowKeys.length}
                      onClick={() => onFinish('schedule')}
                    >
                      Schedule
                    </Button>
                  </Tooltip>
                </Col>
              {/* </Row> */}
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center" align="middle">
        {/* </div> */}
        {/* Show loading spinner while fetching data */}
        {loading && <Spin tip="Fetching data..." />}
        </Row>

      </Form>
      <Table
        rowSelection={{
          selectedRowKeys,
          // onChange: setSelectedRowKeys,
          onChange: (keys) => {
            console.log("selectedKeyswwwww", keys);
            setSelectedRowKeys(keys)
          },

        }}
        // rowSelection={{ exRowSelection }}
        rowKey="employeeCode" // Ensure this matches the key field in your data
        pagination={true}
        columns={columns}
        // dataSource={data, personDetailData}
        dataSource={filteredDataSource}
        style={{ marginTop: '20px', overflowX: 'auto' }}
      />

      {/* Modal for displaying success or exception messages */}
      <Modal
        // title={modalTitle}
        title={<div style={{ textAlign: 'center', width: '100%' }}>{modalTitle}</div>}
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        // onCancel={() => setModalVisible(false)}
        onCancel={handleModalClose}
        // width={800} // Adjust width as necessary
        width={modalWidth}
        footer={null}
      >
        <p>{modalContent}</p>
      </Modal>
    </div>
  );
};

export default WorkmenScheduler;


