// import Axios from './axios';
import axios from '../axiosInstance';
import axiosRetry from 'axios-retry';

/**
 * Application Preview
 * @param {Object} payload
 */
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
export const createLaborRequisitionDetailData = (payload) => {
    console.log("payload", payload)
  return axios.post(`/labour-requisition`, payload);
};

export const getLaborRequisitionDetailData = () => {
  return axios.get('/labour-requisition');
};


export const fetchLaborRequisitionDetailData = async(workOrderNumber) => {
  console.log("workOrderNumber",workOrderNumber);
  try {
      const response = await axios.get(`/labour-requisition/workOrderNumber/${workOrderNumber}`);
      console.log("response", response);
      // return response
      if (response && response.data) {
        return response.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};

export const fetchLrLaborRequisitionDetailData = async(lrNumber) => {
  console.log("workOrderNumber",lrNumber);
  try {
      const response = await axios.get(`/labour-requisition/lrNumber/${lrNumber}`);
      console.log("response", response);
      
      if (response && response.data) {
        return response.data.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};

// export const updateLrDetailDataByLrNumber = async(lrNumber, data) => {
//       try {
//           // Ensure data is an array
//           console.log("lrNumber:", lrNumber);
//           console.log("data:", data);
  
//           // const dataArray = Array.isArray(data) ? data : {workOrderDetailsData:[data]};
//           // const dataArray = Array.isArray(data) ? data : {labourRequisitionData: data};
  
//           // console.log("dataArray:", dataArray);
  
//           const response = await Axios.patch(`/laborRequisition/lrNumber/${lrNumber}`, {labourRequisitionData: data});
//           return response.data; // Assuming your data is in response.data
//       } catch (error) {
//           throw new Error(`Error fetching work order details data: ${error.message}`);
//       }
//   };

// // Function to update labor requisition data by LR number
export const updateLrDetailDataByLrNumber = async (lrNumber, data) => {
  try {
      console.log("lrNumber:", lrNumber);
      console.log("data:", data);
      
      const response = await axios.put(`/labour-requisition/${lrNumber}`, data );
      console.log("response", response);
      
      return response.data;
  } catch (error) {
      console.error('Error in updateLrDetailDataByLrNumber:', error);
      throw new Error(`Error updating labor requisition data: ${error.message}`);
  }
};

// export const updateLrDetailDataByLrNumber = async (lrNumber, item) => {
//   try {
//       const response = await Axios.patch(`/laborRequisition/lrNumber/${lrNumber}`, {
//           labourRequisitionData: item
//       });

//       // Log the raw response for debugging
//       console.log('Raw Axios response:', response);

//       if (response && response.data) {
//           return response.data;
//       } else {
//           throw new Error('Invalid response format');
//       }
//   } catch (error) {
//       console.error('Error in updateLrDetailDataByLrNumber:', error);
//       throw new Error(`Error updating labor requisition data: ${error.response ? error.response.data : error.message}`);
//   }
// };

// export const updateLrDetailDataByLrNumber = async (lrNumber, laborRequisitionData) => {
//   try {
//       const response = await Axios.patch(`/laborRequisition/lrNumber/${lrNumber}`, {
//         laborRequisitionData:laborRequisitionData
//       });
//       return response.data;
//   } catch (error) {
//       if (error.response) {
//           console.error('Error response from server:', error.response.data);
//       } else if (error.request) {
//           console.error('No response received:', error.request);
//       } else {
//           console.error('Error setting up the request:', error.message);
//       }
//       throw new Error('Error updating labor requisition data: ' + error.message);
//   }
// };


export const updateOrgPathAndEffectDate = async (data) => {
  try {
      console.log("data:", data);
      
      const response = await axios.post(
          `/labour-requisition/updateOrg-path-effectDate`, 
          data,
          {
            timeout: 30000  // Set timeout to 300 seconds
          },
          (response_imd)=>{
            console.log("response_imd", response_imd);
          }
      );
      console.log("response", response?.data);
      return response; // Return the actual response data
      // const response = await fetch('http://localhost:1337/labour-requisition/updateOrg-path-effectDate', {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify(data),
      //     timeout: 30000 // Set timeout to 300 seconds (5 minutes)
      // });
      
      // const result = await response.json();
      // console.log("response", result);
      // return result; // Return the actual response data
  } catch (error) {
      // console.error('Error in updateOrgPathAndEffectDate:', error);
      console.error('Error in updateOrgPathAndEffectDate:', {
        message: error.message,
        config: error.config,
        code: error.code,
        response: error.response ? error.response.data : 'No response data'
    });
    throw new Error(`Error updating labor requisition data: ${error.message}`);
  }

};

export const lrSearch = async() => {
  // console.log("lrNUmber ",lrNumber);
  try {
      const response = await axios.get('/labour-requisition/search');
      console.log("response", response);
     
      if (response && response.data) {
        return response.data.data; // Ensure you are returning the actual data
      } else {
        console.error('No data returned from API');
          // throw new Error('No data returned from API');
      }
  } catch (error) {
      console.error('Error fetching work order details data:', error);
      // throw new Error(`Error fetching work order details data: ${error.message}`);
  }
};
  


