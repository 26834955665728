// import Axios from './axios';
import axios from '../axiosInstance';

/**
 * Application Preview
 * @param {Object} payload
 */


export const getPersonDetailData = () => {
  return axios.get('/person');
};

export const updatePersonDataDetail = (response, schedulStatus, selectedJob) => {
  try {
    const payload = {
        data: response,
        selectedJob: selectedJob,
        schedulStatus: schedulStatus
    };
    console.log("payload", payload);
    
    return axios.post('/updatePersonDataDetail', payload); // Replace with your API endpoint
    // console.log(response, schedulStatus, selectedJob);
    // return axios.post('/updatePersonDataDetail', response, schedulStatus, selectedJob);
    
} catch (error) {
    console.error('Error in updatePersonDataDetails:', error);
    throw error;
}
 
};

