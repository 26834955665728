import { getShiftPatternFromWfdData, updateSchedule, getShiftPatternDetail, findByLrNumberData, getStatusScheduleDatas, getShiftPatternFromWfd } from '../../api/workmenSchedulerService'

export const getShiftPatternFromWfdDatas = async () => {
  //Get Data
  try{
    const shiftPatternFromWfdData = await getShiftPatternFromWfdData();
    console.log('Get work Order Data is', shiftPatternFromWfdData);
    console.log("workOrderData?.data", shiftPatternFromWfdData?.data);
    return shiftPatternFromWfdData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const updateScheduleOfPersonData = async (payload, selectedJob) => {
  //Get Data
  try{
    const updateScheduleOfPerson = await updateSchedule(payload, selectedJob);
    console.log('Get work Order Data is', updateScheduleOfPerson);
    console.log("workOrderData?.data", updateScheduleOfPerson?.data);
    return updateScheduleOfPerson?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const getShiftPatternDetails = async (name) => {
  console.log("yetttt", name);
  //Get Data
  try{
    const shiftPatternFromWfdData = await getShiftPatternDetail(name);
    console.log("shiftPatternFromWfdData", shiftPatternFromWfdData?.data);
    
    return shiftPatternFromWfdData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const getFindByLrNumberData = async (name) => {
  console.log("yetttt", name);
  //Get Data
  try{
    const shiftPatternFromWfdData = await findByLrNumberData(name);
    console.log("shiftPatternFromWfdData", shiftPatternFromWfdData);
    return shiftPatternFromWfdData?.data;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
  
};

export const getStatusScheduleData = async(validFrom, validTo) => {
  try{
    const shiftPatternFromWfdData = await getStatusScheduleDatas(validFrom, validTo);
    console.log("shiftPatternFromWfdData", shiftPatternFromWfdData);
    return shiftPatternFromWfdData;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
}

export const getShiftPatternFromWfds = async() => {
  try{
    const shiftPatternFromWfdData = await getShiftPatternFromWfd();
    console.log("shiftPatternFromWfdData", shiftPatternFromWfdData);
    return shiftPatternFromWfdData;
  } catch (error) {
    console.error('Error fetching work order details data:', error);
    return []; // Return an empty array in case of an error
  }
}

