import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// export const jobOrderDetailsColumn = [
export const lrOrderDetailsColumn = () => {
    const columns = [
        // {
        //     title: 'Validity From',
        //     dataIndex: 'validFrom',
        //     key: 'validFrom',
       
        // },
        // {
        //     title: 'Validity To',
        //     dataIndex: 'validTo',
        //     key: 'validTo'
        // },
        {
            title: 'Job',
            dataIndex: 'sVCLnItemName',
            key: 'sVCLnItemName',
       
        },
        {
            title: 'Service Code',
            dataIndex: 'sVCLnItemName',
            key: 'sVCLnItemName',
       
        },
        {
            title: 'Trade',
            dataIndex: 'trade',
            key: 'trade'
        },
        {
            title: 'Skill',
            dataIndex: 'skill',
            key: 'skill'
        },
        {
            title: 'Shift A',
            // dataIndex: 'shiftA',
            dataIndex: ['shiftInformation', 0, 'shiftA'],
            key: 'shiftA',
        },
        {
            title: 'LR A (qty)',
            dataIndex: ['shiftInformation', 0, 'lrAQty'],
            key: 'lrAQty',
        },
        {
            title: 'Schedule A (qty)',
            dataIndex: ['shiftInformation', 0, 'scheduleQtyA'],
            key: 'scheduleQtyA',
        },
        {
            title: 'Balance Schedule A (qty)',
            dataIndex: ['shiftInformation', 0, 'balScheduleAQty'],
            key: 'balScheduleAQty',
        },
        {
            title: 'Shift B',
            // dataIndex: 'shiftB',
            dataIndex: ['shiftInformation', 0, 'shiftB'],
            key: 'shiftB',
        },
        {
            title: 'LR B (qty)',
            dataIndex: ['shiftInformation', 0, 'lrBQty'],
            key: 'lrBQty',
        },
        {
            title: 'Schedule B (qty)',
            dataIndex: ['shiftInformation', 0, 'scheduleQtyB'],
            key: 'scheduleBQty',
        },
        {
            title: 'Balance Schedule B (qty)',
            dataIndex: ['shiftInformation', 0, 'balScheduleBQty'],
            key: 'balScheduleBQty',
        },
        {
            title: 'Shift C',
            // dataIndex: 'shiftC',
            dataIndex: ['shiftInformation', 0, 'shiftC'],
            key: 'shiftC',
        },
        {
            title: 'LR C (qty)',
            dataIndex: ['shiftInformation', 0, 'lrCQty'],
            key: 'lrCQty',
        },
        {
            title: 'Schedule C (qty)',
            dataIndex: ['shiftInformation', 0, 'scheduleQtyC'],
            key: 'scheduleCQty',
        },
        {
            title: 'Balance Schedule C (qty)',
            dataIndex: ['shiftInformation', 0, 'balScheduleCQty'],
            key: 'balScheduleCQty',
        },
        {
            title: 'Shift Gen',
            // dataIndex: 'shiftGen',
            dataIndex: ['shiftInformation', 0, 'shiftGen'],
            key: 'shiftGen',
        },
        {
            title: 'LR Gen (qty)',
            dataIndex: ['shiftInformation', 0, 'lrGenQty'],
            key: 'lrGenQty',
        },
        {
            title: 'Schedule Gen (qty)',
            dataIndex: ['shiftInformation', 0, 'scheduleQtyGen'],
            key: 'scheduleQtyGen',
        },
        {
            title: 'Balance Schedule Gen (qty)',
            dataIndex: ['shiftInformation', 0, 'balScheduleGenQty'],
            key: 'balScheduleGenQty',
        },
    ];

    // Conditionally remove 'Remaining Qty' column if not on LR page
    // if (!isLRPage) {
    //     return columns.filter(column => column.key !== 'remainingQty');
    // }

    return columns;
}
